import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionEightReduxForm from './SectionEightForm';
import messages from './messages';

export function SectionEight() {
  return (
    <div className="section-8">
      <SectionEightReduxForm />
      <div className="reasons-wrapper">
        <div>
          <h4>
            <FormattedMessage {...messages.whyChoose} />
          </h4>
          <div className="reasons">
            <span className="yellow-number">1</span>
            <span className="reason">
              <FormattedMessage {...messages.recognizeComplexities} />
            </span>
          </div>
          <div className="reasons">
            <span className="yellow-number">2</span>
            <span className="reason">
              <FormattedMessage {...messages.canAddress} />
            </span>
          </div>
          <div className="reasons">
            <span className="yellow-number">3</span>
            <span className="reason">
              <FormattedMessage {...messages.wereAdvocates} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
