import styled from 'styled-components';

const GlobalStyles = styled.div`
  .uik-checkbox__description {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 1.5;
    color: #3e3f42;
    margin-bottom: 0px;
  }
  .uik-divider__horizontal {
    width: 80%;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .uik-layout-main__wrapper {
    overflow-y: initial;
  }
  .header {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.46;
    color: #3e3f42;
    margin-top: 0px;
  }
  .title {
    margin-bottom: 20px;
  }
  h3.sub-header {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.5;
    color: #3e3f42;
    margin: 60px 0 40px 0;
  }
  h4.sub-header:first-of-type: {
    margin: 40px 0 20px 0;
  }
  h4.sub-header {
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: #3e3f42;
    margin: 60px 0 20px 0;
  }
  span.form-body {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #3e3f42;
    margin-bottom: 30px;
  }
  span.body-text,
  p.body-text {
    font-family: 'Roboto';
    font-size: 16px;
    line-height: 1.5;
    color: #3e3f42;
  }
  span.bold,
  p.bold {
    font-weight: 500;
  }
  a {
    text-decoration: none;
  }
`;

export default GlobalStyles;
