/**
 *
 * EmailReminders
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import _ from 'lodash';

import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { UikWidget, UikLayoutMain } from '@uik/lib';
import { fetchEmailReminders } from './actions';
import makeSelectEmailReminders from './selectors';
import reducer from './reducer';
import saga from './saga';

import AddReminder from './AddReminder';
import ModifyReminder from './ModifyReminder';
import EmailReminderList from './EmailReminderList';

export function EmailReminders({
  match,
  currentUser,
  currentCompanyId,
  ...props
}) {
  const history = useHistory();
  useInjectReducer({ key: 'emailReminders', reducer });
  useInjectSaga({ key: 'emailReminders', saga });

  useEffect(() => {
    if (!_.isEmpty(currentUser) && !currentUser.inkblot_admin_yn) {
      history.push('/my_account/company_information');
    }

    if (currentCompanyId && currentUser.inkblot_admin_yn) {
      props.fetchEmailReminders(currentCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, currentCompanyId]);

  return (
    <UikLayoutMain>
      <UikWidget padding>
        <h2 className="header">Email Reminders</h2>
        <Switch>
          <Route path={`${match.url}/add_reminder`} component={AddReminder} />
          <Route
            path={`${match.url}/:id/modify_reminder`}
            component={ModifyReminder}
          />
          <Route path={`${match.url}`} component={EmailReminderList} />
        </Switch>
      </UikWidget>
    </UikLayoutMain>
  );
}

EmailReminders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fetchEmailReminders: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  emailReminders: makeSelectEmailReminders(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchEmailReminders: (companyId) =>
      dispatch(fetchEmailReminders(companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EmailReminders);
