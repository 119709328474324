import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import TextDataContacts from './TextDataContacts';
import Container from './Container';
import AddressContacts from './Address';
import { safeObj } from './helpers';

const Contacts = ({ tag, contact }) => {
  const emailData = (
    <span>
      <span>{contact.email}</span>
      {contact.cc_emails && (
        <span>
          {contact.cc_emails.split(',').map((email) => (
            <span key={email}>
              <br />
              <Tag color="blue">CC</Tag>
              {email}
            </span>
          ))}
        </span>
      )}
    </span>
  );

  return (
    <div style={{ marginBottom: '40px' }}>
      <h4 style={{ marginBottom: '20px', fontSize: '16px' }}>{tag}</h4>
      <Container maxWidth="750px">
        <TextDataContacts tag="Contact Person" data={contact.name} />
        <TextDataContacts tag="Title" data={contact.title} />
        <TextDataContacts tag="Email" data={emailData} />
      </Container>
      <Container maxWidth="750px">
        <AddressContacts
          tag="Address"
          data={safeObj(contact, 'address') || {}}
        />
        <TextDataContacts tag="Business Phone" data={contact.business_phone} />
        <TextDataContacts tag="Mobile Phone" data={contact.sms_phone} />
      </Container>
    </div>
  );
};

Contacts.propTypes = {
  contact: PropTypes.object,
  tag: PropTypes.string,
};

Contacts.defaultProps = {
  contact: {},
  tag: '',
};

export default Contacts;
