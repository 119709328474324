/*
 *
 * Reports constants
 *
 */

export const DEFAULT_ACTION = 'app/Reports/DEFAULT_ACTION';

export const CREATE_REPORT = 'app/Reports/CREATE_REPORT';
export const CREATE_REPORT_SUCCESS = 'app/Reports/CREATE_REPORT_SUCCESS';
export const CREATE_REPORT_ERROR = 'app/Reports/CREATE_REPORT_ERROR';
