import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Modal } from 'antd';

import AntDatePicker from 'components/AntDatePicker';

function RemoveEmployeeModal({
  dateFormat,
  employeeName,
  handleCancel,
  handleChange,
  onSubmit,
  removeModalOpen,
}) {
  return (
    <Modal
      onCancel={handleCancel}
      onOk={onSubmit}
      title="Remove Employee"
      visible={removeModalOpen}
    >
      <form>
        <p>Are you sure you want to remove {employeeName}?</p>
        <Field
          component={AntDatePicker}
          label="end date*"
          name="end_date"
          onChange={(_date, dateString) => handleChange('end_date', dateString)}
          placeholder={dateFormat}
        />
        <br />
        <sub style={{ color: 'rgb(158, 160, 165)' }}>
          <i>* when no date is provided, default is one month from entry</i>
        </sub>
      </form>
    </Modal>
  );
}

RemoveEmployeeModal.propTypes = {
  dateFormat: PropTypes.string.isRequired,
  employeeName: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  removeModalOpen: PropTypes.bool.isRequired,
};

export default RemoveEmployeeModal;
