import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import messages from './messages';

import screenshot1Desktop from '../../images/LandingPage/screenshots/screenshot-1-desktop.png';
import screenshot2Desktop from '../../images/LandingPage/screenshots/screenshot-2-desktop.svg';
import screenshot3Desktop from '../../images/LandingPage/screenshots/screenshot-3-desktop.svg';

import screenshot1Mobile from '../../images/LandingPage/screenshots/screenshot-1-mobile.png';
import screenshot2Mobile from '../../images/LandingPage/screenshots/screenshot-2-mobile.svg';
import screenshot3Mobile from '../../images/LandingPage/screenshots/screenshot-3-mobile.svg';

import screenshot1French from '../../images/LandingPage/screenshots/screenshot-1-fr.png';
import screenshot2French from '../../images/LandingPage/screenshots/screenshot-2-fr.png';
import screenshot3French from '../../images/LandingPage/screenshots/screenshot-3-french.png';

import largeTrials from '../../images/LandingPage/animations/large-trials.svg';
import over85 from '../../images/LandingPage/animations/over-85.svg';
import better25 from '../../images/LandingPage/animations/better-25.svg';

import greenCheck1 from '../../images/LandingPage/animations/green-check-1.svg';
import greenCheck2 from '../../images/LandingPage/animations/green-check-2.svg';
import greenCheck3 from '../../images/LandingPage/animations/green-check-3.svg';
import greenCheck4 from '../../images/LandingPage/animations/green-check-4.svg';

export function SectionFour({ windowWidth }) {
  const isFr = localStorage.getItem('locale') === 'fr';
  return (
    <div className="section-4">
      <div>
        <FormattedHTMLMessage {...messages.inkblotSolution} />
        <div className="section-4-section">
          <img
            src={isFr ? screenshot1French : screenshot1Mobile}
            alt="Your mental health partner"
            className="section-4-mobile-image"
          />
          <div className="section-4-text-section">
            <div className="section-4-feature">
              <h2>
                <FormattedMessage {...messages.confidentialCounselling} />
              </h2>
              <p className="normal-weight">
                <FormattedMessage {...messages.consistency} />
              </p>
            </div>
            <div className="section-4-facts-section">
              <div>
                <img src={greenCheck1} alt="First checkmark" />
                <FormattedMessage {...messages.completeSurvey} />
              </div>
              <div>
                <img src={greenCheck2} alt="Second checkmark" />
                <FormattedMessage {...messages.bookConsult} />
              </div>
              <div>
                <img src={greenCheck3} alt="Third checkmark" />
                <FormattedMessage {...messages.secureVideo} />
              </div>
              <div>
                <img src={greenCheck4} alt="Fourth checkmark" />
                <FormattedMessage {...messages.betterCare} />
              </div>
              <hr />
              <div className="section-4-facts">
                <FormattedHTMLMessage {...messages.multipleLarge} />
                <img src={largeTrials} alt="Multiple large trials" id="large" />
              </div>
            </div>
          </div>
          <img
            src={isFr ? screenshot1French : screenshot1Desktop}
            alt="Your mental health partner"
            className="section-4-desktop-image"
          />
        </div>
        <div className="section-4-reverse-section">
          <img
            src={isFr ? screenshot2French : screenshot2Desktop}
            alt="Your mental health partner"
            className="section-4-desktop-image"
          />
          <img
            src={isFr ? screenshot2French : screenshot2Mobile}
            alt="Your mental health partner"
            className="section-4-mobile-image"
          />
          <div className="section-4-text-section">
            <div className="section-4-feature">
              <h2>
                <FormattedMessage {...messages.inkblotAssess} />
              </h2>
              <p className="normal-weight">
                <FormattedMessage {...messages.ourExpert} />
              </p>
              <p className="normal-weight">
                <FormattedMessage {...messages.employeeObtain} />
              </p>
            </div>
            <div className="section-4-facts-section">
              <hr />
              <div className="section-4-facts">
                <FormattedHTMLMessage {...messages.reportGen} />
                <img src={over85} alt="Over 85%" id="over" />
              </div>
            </div>
          </div>
        </div>
        <div className="section-4-section">
          <img
            src={isFr ? screenshot3French : screenshot3Mobile}
            alt="Your mental health partner"
            className="section-4-mobile-image"
          />
          <div className="section-4-text-section">
            <div className="section-4-feature">
              <h2>
                <FormattedMessage {...messages.comprehensiveReporting} />
              </h2>
              <p className="normal-weight">
                <FormattedMessage {...messages.learnAbout} />
              </p>
            </div>
            <div className="section-4-facts-section">
              <hr />
              <div className="section-4-facts">
                <FormattedHTMLMessage {...messages.employeeFeel} />
                <img src={better25} alt="25% better" id="better" />
              </div>
            </div>
          </div>
          <img
            src={
              isFr
                ? screenshot3French
                : windowWidth > 1440
                ? screenshot3Mobile
                : screenshot3Desktop
            }
            alt="Your mental health partner"
            className="section-4-desktop-image"
          />
        </div>
      </div>
    </div>
  );
}

SectionFour.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};
