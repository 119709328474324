/*
 *
 * EmailReminders constants
 *
 */

export const statusOptions = () => {
  return [
    {
      value: 'scheduled',
      label: 'Scheduled',
    },
    {
      value: 'cancelled',
      label: 'Cancelled',
    },
    {
      value: 'completed',
      label: 'Completed',
    },
    {
      value: 'unknown',
      label: 'Unknown',
    },
  ];
};

export const FETCH_EMAIL_REMINDER_TEMPLATES =
  'app/EmailReminders/FETCH_EMAIL_REMINDER_TEMPLATES';
export const FETCH_EMAIL_REMINDER_TEMPLATES_ERROR =
  'app/EmailReminders/FETCH_EMAIL_REMINDER_TEMPLATES_ERROR';
export const FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS =
  'app/EmailReminders/FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS';

export const CREATE_EMAIL_REMINDER = 'app/EmailReminders/CREATE_EMAIL_REMINDER';
export const CREATE_EMAIL_REMINDER_ERROR =
  'app/EmailReminders/CREATE_EMAIL_REMINDER_ERROR';
export const CREATE_EMAIL_REMINDER_SUCCESS =
  'app/EmailReminders/CREATE_EMAIL_REMINDER_SUCCESS';

export const FETCH_EMAIL_REMINDERS = 'app/EmailReminders/FETCH_EMAIL_REMINDERS';
export const FETCH_EMAIL_REMINDERS_ERROR =
  'app/EmailReminders/FETCH_EMAIL_REMINDERS_ERROR';
export const FETCH_EMAIL_REMINDERS_SUCCESS =
  'app/EmailReminders/FETCH_EMAIL_REMINDERS_SUCCESS';

export const FETCH_EMAIL_REMINDER = 'app/EmailReminders/FETCH_EMAIL_REMINDER';
export const FETCH_EMAIL_REMINDER_ERROR =
  'app/EmailReminders/FETCH_EMAIL_REMINDER_ERROR';
export const FETCH_EMAIL_REMINDER_SUCCESS =
  'app/EmailReminders/FETCH_EMAIL_REMINDER_SUCCESS';

export const UPDATE_EMAIL_REMINDER = 'app/EmailReminders/UPDATE_EMAIL_REMINDER';
export const UPDATE_EMAIL_REMINDER_ERROR =
  'app/EmailReminders/UPDATE_EMAIL_REMINDER_ERROR';
export const UPDATE_EMAIL_REMINDER_SUCCESS =
  'app/EmailReminders/UPDATE_EMAIL_REMINDER_SUCCESS';

export const DESTROY_EMAIL_REMINDER =
  'app/EmailReminders/DESTROY_EMAIL_REMINDER';
export const DESTROY_EMAIL_REMINDER_ERROR =
  'app/EmailReminders/DESTROY_EMAIL_REMINDER_ERROR';
export const DESTROY_EMAIL_REMINDER_SUCCESS =
  'app/EmailReminders/DESTROY_EMAIL_REMINDER_SUCCESS';

export const AUTOMATE_REMINDERS = 'app/EmailReminders/AUTOMATE_REMINDERS';
export const AUTOMATE_REMINDERS_ERROR =
  'app/EmailReminders/AUTOMATE_REMINDERS_ERROR';
export const AUTOMATE_REMINDERS_SUCCESS =
  'app/EmailReminders/AUTOMATE_REMINDERS_SUCCESS';
export const FETCH_TRANQUILITY_INFO =
  'app/EmailReminders/FETCH_TRANQUILITY_INFO';
export const FETCH_TRANQUILITY_INFO_SUCCESS =
  'app/EmailReminders/FETCH_TRANQUILITY_INFO_SUCCESS';
export const FETCH_TRANQUILITY_INFO_ERROR =
  'app/EmailReminders/FETCH_TRANQUILITY_INFO_ERROR';
export const CLEAR_TRANQUILITY_INFO =
  'app/EmailReminders/CLEAR_TRANQUILITY_INFO';
