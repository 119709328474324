/**
 *
 * Reports
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { UikWidget, UikLayoutMain } from '@uik/lib';
import makeSelectReports from './selectors';
import reducer from './reducer';
import saga from './saga';
import GenerateReportsForm from './GenerateReportsForm';

export function Reports() {
  useInjectReducer({ key: 'reports', reducer });
  useInjectSaga({ key: 'reports', saga });

  return (
    <UikLayoutMain>
      <UikWidget padding style={{ position: 'relative' }}>
        <h2 className="header"> Generate Reports</h2>
        <GenerateReportsForm />
      </UikWidget>
    </UikLayoutMain>
  );
}

Reports.propTypes = {};

const mapStateToProps = createStructuredSelector({
  reports: makeSelectReports(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Reports);
