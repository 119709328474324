import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { EMPLOYEE_STANDARD_FIELDS } from '../constants';

export function DownloadTemplateButton({ customFieldsData }) {
  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], {
      type: fileType,
    });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const customFieldHeaders = customFieldsData.map((customField) => {
    return customField.internal_name;
  });

  const exportToCsv = () => {
    const headers = [...EMPLOYEE_STANDARD_FIELDS, ...customFieldHeaders].join();
    downloadFile({
      data: headers,
      fileName: 'template.csv',
      fileType: 'text/csv',
    });
  };

  return <Button onClick={exportToCsv}>Download Template File</Button>;
}

DownloadTemplateButton.propTypes = {
  customFieldsData: PropTypes.array,
};

export default DownloadTemplateButton;
