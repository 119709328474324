/*
 *
 * CompanyCreation actions
 *
 */

import {
  FETCH_PEOPLE_COMPANIES,
  FETCH_PEOPLE_COMPANIES_ERROR,
  FETCH_PEOPLE_COMPANIES_SUCCESS,
  CREATE_PEOPLE_COMPANY,
  CREATE_PEOPLE_COMPANY_ERROR,
  CREATE_PEOPLE_COMPANY_SUCCESS,
  UPLOAD_PEOPLE_EMPLOYEE_CSV,
  UPLOAD_PEOPLE_EMPLOYEE_CSV_SUCCESS,
  UPLOAD_PEOPLE_EMPLOYEE_CSV_ERROR,
  RESET_COMPANY_ERROR,
} from './constants';

export function fetchCompanies() {
  return {
    type: FETCH_PEOPLE_COMPANIES,
  };
}

export function fetchCompaniesSuccess(companies) {
  return {
    companies,
    type: FETCH_PEOPLE_COMPANIES_SUCCESS,
  };
}

export function fetchCompaniesError(error) {
  return {
    error,
    type: FETCH_PEOPLE_COMPANIES_ERROR,
  };
}

export function uploadEmployeeCsv(values, callback) {
  return {
    values,
    callback,
    type: UPLOAD_PEOPLE_EMPLOYEE_CSV,
  };
}

export function uploadEmployeeCsvSuccess() {
  return {
    type: UPLOAD_PEOPLE_EMPLOYEE_CSV_SUCCESS,
  };
}

export function uploadEmployeeCsvError(error) {
  return {
    error,
    type: UPLOAD_PEOPLE_EMPLOYEE_CSV_ERROR,
  };
}

export function createCompany(values, callback) {
  return {
    values,
    callback,
    type: CREATE_PEOPLE_COMPANY,
  };
}

export function createCompanySuccess(company) {
  return {
    company,
    type: CREATE_PEOPLE_COMPANY_SUCCESS,
  };
}

export function createCompanyError(error) {
  return {
    error,
    type: CREATE_PEOPLE_COMPANY_ERROR,
  };
}

export function resetCompanyError() {
  return {
    type: RESET_COMPANY_ERROR,
  };
}
