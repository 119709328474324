import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the companyCreation state domain
 */

const selectCompanyCreationDomain = (state) =>
  state.companyCreation || initialState;

/**
 * Other specific selectors
 */

const makeSelectCompanies = () =>
  createSelector(
    selectCompanyCreationDomain,
    (substate) => substate.companies.data,
  );

const makeSelectAccountManagers = () =>
  createSelector(
    selectCompanyCreationDomain,
    (substate) => substate.accountManagers.data,
  );
// prettier-ignore
const makeSelectCpNavigators = () =>
  createSelector(
    selectCompanyCreationDomain,
    substate =>
      substate.cpNavigators.data && substate.cpNavigators.data.length
        ? substate.cpNavigators.data.map(e => ({
          label: `${e.first_name} ${e.last_name} (id:${e.id})`,
          value: e.id, 
        }))
        : [],
  );
/**
 * Default selector used by CompanyCreation
 */

const makeSelectCompanyCreation = () =>
  createSelector(selectCompanyCreationDomain, (substate) => substate);

const makeSelectCompanyCreationError = () =>
  createSelector(
    (state) => state.companyCreation?.newCompany?.error?.data?.error,
    (substate) => substate,
  );

export default makeSelectCompanyCreation;
export {
  selectCompanyCreationDomain,
  makeSelectCompanyCreationError,
  makeSelectCompanies,
  makeSelectAccountManagers,
  makeSelectCpNavigators,
};
