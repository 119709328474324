/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { API_URL } from 'utils/environment';
import messages from './messages';
import { useHistory } from 'react-router-dom';

function SectionEightForm(props) {
  const { handleSubmit } = props;
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const isFr = localStorage.getItem('locale') === 'fr';

  const submitInformationRequest = (values) => {
    axios
      .post(`${API_URL}/sales_leads`, values)
      .then(setSuccess(true))
      .then(() => history.push('/thankyou?page=landing'));
  };

  return (
    <form onSubmit={handleSubmit(submitInformationRequest)}>
      <h2>
        <FormattedMessage {...messages.contactUs} />
      </h2>
      <p className="normal-weight">
        <FormattedMessage {...messages.ourTeam} />
      </p>
      <div>
        <label>
          <FormattedMessage {...messages.name} />
          <Field
            name="name"
            placeholder={isFr ? 'Nom' : 'Name'}
            component="input"
            type="text"
            required
          />
        </label>
        <label>
          <FormattedMessage {...messages.orgName} />
          <Field
            name="company"
            placeholder={isFr ? 'Organisation' : 'Organization'}
            component="input"
            type="text"
            required
          />
        </label>
        <label>
          <FormattedMessage {...messages.orgSize} />
          <Field name="size" component="select">
            <option value="1-99">1-99</option>
            <option value="100-499">100-499</option>
            <option value="500-2999">500-2999</option>
            <option value="3000+">3000+</option>
          </Field>
        </label>
        <label>
          <FormattedMessage {...messages.email} />
          <Field
            name="email"
            placeholder={isFr ? 'exemple@exemple.com' : 'example@example.com'}
            component="input"
            type="email"
            required
          />
        </label>
        <label>
          <FormattedMessage {...messages.phone} />
          <Field
            name="phone"
            placeholder="123-456-7890"
            component="input"
            type="text"
            required
          />
        </label>
        <label>
          <FormattedMessage {...messages.message} />
          <Field
            name="message"
            placeholder={
              isFr
                ? 'Conférence ou groupe de discussion'
                : 'e.g. I want to partner with Inkblot because...'
            }
            component="input"
            type="message"
          />
        </label>
        <label>
          <FormattedMessage {...messages.hearAbout} />
          <Field name="refer" component="select">
            <option value="Social Post">
              {isFr ? 'Publication sur les médias sociaux' : 'Social Post'}
            </option>
            <option value="Digital Ad">
              {isFr ? 'Publicité numérique' : 'Digital Ad'}
            </option>
            <option value="Colleague/Friend">
              {isFr ? 'Collègue ou ami' : 'Colleague/Friend'}
            </option>
            <option value="Panel/Conference">
              {isFr ? 'Conférence ou groupe de discussion' : 'Conference/Panel'}
            </option>
            <option value="Other">{isFr ? 'Autre' : 'Other'}</option>
          </Field>
        </label>
        {props.refer === 'Other' && (
          <label>
            <FormattedMessage {...messages.other} />
            <Field
              name="refer_other"
              placeholder={
                isFr
                  ? 'Comment avez-vous entendu parler de nous?'
                  : 'How did you hear?'
              }
              component="input"
              type="message"
            />
          </label>
        )}
      </div>
      <button
        type="submit"
        className={
          success ? 'landing-button-green landing-button' : 'landing-button'
        }
        disabled={success}
      >
        {success ? 'Success' : <FormattedMessage {...messages.getStarted} />}
      </button>
      <p className="hours">
        <FormattedHTMLMessage {...messages.weValue} />
      </p>
    </form>
  );
}

SectionEightForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refer: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

SectionEightForm.defaultProps = {
  refer: '',
};

const mapStateToProps = (state) => {
  const formSelector = formValueSelector('RequestInformationForm');
  return {
    initialValues: {
      size: '1-99',
      refer: 'Social Post',
    },
    refer: _.get(state, 'form.RequestInformationForm.values.refer'),
    paymentType: formSelector(state, 'size'),
  };
};

const withConnect = connect(mapStateToProps, null);

const SectionEightReduxForm = compose(withConnect)(
  reduxForm({
    form: 'RequestInformationForm',
  })(SectionEightForm),
);

export default SectionEightReduxForm;
