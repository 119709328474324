/*
 *
 * CompanyCreation reducer
 *
 */
import produce from 'immer';
import {
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  FETCH_AMS,
  FETCH_AMS_ERROR,
  FETCH_AMS_SUCCESS,
  FETCH_CP_NAVIGATORS_ERROR,
  FETCH_CP_NAVIGATORS_SUCCESS,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  RESET_COMPANY_ERROR,
} from './constants';

export const initialState = {
  companies: {
    loading: false,
    error: false,
    data: [],
  },
  newCompany: {
    loading: false,
    error: false,
    data: null,
  },
  accountManagers: {
    loading: false,
    error: false,
    data: [],
  },
  cpNavigators: {
    loading: false,
    error: false,
    data: [],
  },
};

/* eslint-disable default-case, no-param-reassign */
const companyCreationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_COMPANIES:
        draft.companies.loading = true;
        draft.companies.error = false;
        draft.companies.data = [];
        break;
      case FETCH_AMS:
        draft.accountManagers.loading = true;
        draft.accountManagers.error = false;
        draft.accountManagers.data = [];
        break;
      case FETCH_COMPANIES_SUCCESS:
        draft.companies.data = action.companies;
        draft.companies.loading = false;
        break;
      case CREATE_COMPANY:
        draft.newCompany.error = false;
        draft.newCompany.data = null;
        draft.newCompany.loading = true;
        break;
      case CREATE_COMPANY_SUCCESS:
        draft.newCompany.error = false;
        draft.newCompany.loading = false;
        draft.newCompany.data = action.company;
        break;
      case CREATE_COMPANY_ERROR:
        draft.newCompany.error = action.error;
        draft.newCompany.loading = false;
        draft.newCompany.data = null;
        break;
      case RESET_COMPANY_ERROR:
        draft.newCompany.error = false;
        break;
      case FETCH_AMS_SUCCESS:
        draft.accountManagers.data = action.ams;
        draft.accountManagers.loading = false;
        break;
      case FETCH_COMPANIES_ERROR:
        draft.companies.error = action.error;
        draft.companies.loading = false;
        break;
      case FETCH_AMS_ERROR:
        draft.accountManagers.error = action.error;
        draft.accountManagers.loading = false;
        break;
      case FETCH_CP_NAVIGATORS_SUCCESS:
        draft.cpNavigators.data = action.data;
        draft.cpNavigators.loading = false;
        break;
      case FETCH_CP_NAVIGATORS_ERROR:
        draft.cpNavigators.error = action.error;
        draft.cpNavigators.loading = false;
        break;
    }
  });

export default companyCreationReducer;
