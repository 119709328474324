/*
 *
 * MyAccount actions
 *
 */

import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  UPLOAD_EMPLOYEE_CSV,
  BACKFILL_PREVIEW,
  BACKFILL_PREVIEW_ERROR,
  BACKFILL_PREVIEW_SUCCESS,
  UPLOAD_EMPLOYEE_CSV_ERROR,
  UPLOAD_EMPLOYEE_CSV_SUCCESS,
  SAVE_PAYMENT_TYPE,
  SAVE_PAYMENT_TYPE_ERROR,
  SAVE_PAYMENT_TYPE_SUCCESS,
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  TOGGLE_DISABLE_COMPANY,
  TOGGLE_DISABLE_COMPANY_SUCCESS,
  TOGGLE_DISABLE_COMPANY_ERROR,
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE_LIST_SUCCESS,
  FETCH_EMPLOYEE_LIST_ERROR,
  UPDATE_EMPLOYEE_LIST_PARAMS,
  EXPORT_EMPLOYEE_LIST,
  EXPORT_EMPLOYEE_LIST_SUCCESS,
  EXPORT_EMPLOYEE_LIST_ERROR,
  FETCH_CUSTOM_FIELDS,
  FETCH_CUSTOM_FIELDS_SUCCESS,
  FETCH_CP_NAVIGATORS,
} from './constants';

export function fetchCompany(id) {
  return {
    id,
    type: FETCH_COMPANY,
  };
}

export function fetchCompanySuccess(company) {
  return {
    company,
    type: FETCH_COMPANY_SUCCESS,
  };
}

export function fetchCompanyError(error) {
  return {
    error,
    type: FETCH_COMPANY_ERROR,
  };
}

export function updateCompany(id, values, callback) {
  return {
    id,
    values,
    callback,
    type: UPDATE_COMPANY,
  };
}

export function updateCompanySuccess(company) {
  return {
    company,
    type: UPDATE_COMPANY_SUCCESS,
  };
}

export function updateCompanyError(error) {
  return {
    error,
    type: UPDATE_COMPANY_ERROR,
  };
}

export function uploadEmployeeCsv(values, callback) {
  return {
    values,
    callback,
    type: UPLOAD_EMPLOYEE_CSV,
  };
}

export function uploadEmployeeCsvSuccess() {
  return {
    type: UPLOAD_EMPLOYEE_CSV_SUCCESS,
  };
}

export function uploadEmployeeCsvError(error) {
  return {
    error,
    type: UPLOAD_EMPLOYEE_CSV_ERROR,
  };
}

export function backfillPreview(values, callback = () => {}) {
  return {
    values,
    callback,
    type: BACKFILL_PREVIEW,
  };
}

export function backfillPreviewSuccess() {
  return {
    type: BACKFILL_PREVIEW_SUCCESS,
  };
}

export function backfillPreviewError(error) {
  return {
    error,
    type: BACKFILL_PREVIEW_ERROR,
  };
}

export function savePaymentType(values, callback) {
  return {
    values,
    callback,
    type: SAVE_PAYMENT_TYPE,
  };
}

export function savePaymentTypeSuccess() {
  return {
    type: SAVE_PAYMENT_TYPE_SUCCESS,
  };
}

export function savePaymentTypeError(error) {
  return {
    error,
    type: SAVE_PAYMENT_TYPE_ERROR,
  };
}

export function createEmployee(values, callback) {
  return {
    values,
    callback,
    type: CREATE_EMPLOYEE,
  };
}

export function createEmployeeSuccess(employee) {
  return {
    employee,
    type: CREATE_EMPLOYEE_SUCCESS,
  };
}

export function createEmployeeError(error) {
  return {
    error,
    type: CREATE_EMPLOYEE_ERROR,
  };
}

export function deleteEmployee(values, callback) {
  return {
    values,
    callback,
    type: DELETE_EMPLOYEE,
  };
}

export function deleteEmployeeSuccess(employee) {
  return {
    employee,
    type: DELETE_EMPLOYEE_SUCCESS,
  };
}

export function deleteEmployeeError(error) {
  return {
    error,
    type: DELETE_EMPLOYEE_ERROR,
  };
}

export function toggleDisableCompany(values, callback) {
  return {
    values,
    callback,
    type: TOGGLE_DISABLE_COMPANY,
  };
}

export function toggleDisableCompanySuccess(company) {
  return {
    company,
    type: TOGGLE_DISABLE_COMPANY_SUCCESS,
  };
}

export function toggleDisableCompanyError(error) {
  return {
    error,
    type: TOGGLE_DISABLE_COMPANY_ERROR,
  };
}

export function fetchEmployeeList(values, callback) {
  return {
    values,
    callback,
    type: FETCH_EMPLOYEE_LIST,
  };
}

export function fetchEmployeeListSuccess(employeeList) {
  return {
    employeeList,
    type: FETCH_EMPLOYEE_LIST_SUCCESS,
  };
}

export function fetchEmployeeListError(error) {
  return {
    error,
    type: FETCH_EMPLOYEE_LIST_ERROR,
  };
}

export function updateEmployeeListParams(params) {
  return {
    params,
    type: UPDATE_EMPLOYEE_LIST_PARAMS,
  };
}

export function exportEmployeeList(values, callback) {
  return {
    values,
    callback,
    type: EXPORT_EMPLOYEE_LIST,
  };
}

export function exportEmployeeListSuccess() {
  return {
    type: EXPORT_EMPLOYEE_LIST_SUCCESS,
  };
}

export function exportEmployeeListError(error) {
  return {
    error,
    type: EXPORT_EMPLOYEE_LIST_ERROR,
  };
}

export function fetchCustomFields(companyId) {
  return {
    companyId,
    type: FETCH_CUSTOM_FIELDS,
  };
}

export function fetchCustomFieldsSuccess(customFields) {
  return {
    customFields,
    type: FETCH_CUSTOM_FIELDS_SUCCESS,
  };
}

export function fetchClinicalNavigators(callback) {
  return {
    callback,
    type: FETCH_CP_NAVIGATORS,
  };
}
