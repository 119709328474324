/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { API_URL } from 'utils/environment';
import messages from './messages';
import { useHistory } from 'react-router-dom';

function ReduxForm(props) {
  const { handleSubmit } = props;
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const submitInformationRequest = (values) => {
    axios
      .post(`${API_URL}/sales_leads/eap`, values)
      .then(setSuccess(true))
      .then(() => history.push('/thankyou?page=eap'));
  };

  return (
    <form onSubmit={handleSubmit(submitInformationRequest)}>
      <div className="info">
        <div className="left">
          <h2>
            <FormattedMessage {...messages.connectWith} />
          </h2>
          <FormattedHTMLMessage {...messages.haveOptions} />
        </div>
        <div className="right">
          <FormattedHTMLMessage {...messages.byNumbers} />
          <div className="by-the-numbers">
            <div className="stat">
              <h5>1M+</h5>
              <FormattedHTMLMessage {...messages.feelBetter} />
            </div>
            <div className="stat">
              <h5>400+</h5>
              <FormattedHTMLMessage {...messages.referredInkblot} />
            </div>
            <div className="stat">
              <h5>950+</h5>
              <FormattedHTMLMessage {...messages.trustedPractitioners} />
            </div>
          </div>
        </div>
      </div>
      <div className="fields">
        <label>
          <FormattedMessage {...messages.name} />
          <Field name="name" component="input" type="text" required />
        </label>
        <label>
          <FormattedMessage {...messages.email} />
          <Field name="email" component="input" type="email" required />
        </label>
        <label>
          <FormattedMessage {...messages.companyName} />
          <Field name="company" component="input" type="text" required />
        </label>
        <label>
          <FormattedMessage {...messages.jobTitle} />
          <Field name="title" component="input" type="text" required />
        </label>
        <label>
          <FormattedMessage {...messages.organizationSize} />
          <Field name="size" component="select" required>
            <option value="1-99">1-99</option>
            <option value="100-499">100-499</option>
            <option value="500-2999">500-2999</option>
            <option value="3000+">3000+</option>
          </Field>
        </label>
        <label>
          <FormattedMessage {...messages.messageOptional} />
          <Field name="message" component="input" type="message" />
        </label>
        <label>
          <FormattedMessage {...messages.howHear} />
          <Field name="refer" component="select" required>
            <option />
            <option value="Colleague/Friend">Colleague/Friend</option>
            <option value="Social Media">Social Media</option>
            <option value="Advertisement">Advertisement</option>
            <option value="Panel/Conference">Panel/Conference</option>
            <option value="Other">Other</option>
          </Field>
        </label>
        {props.refer === 'Other' && (
          <label>
            Other
            <Field
              name="refer_other"
              placeholder="How did you hear?"
              component="input"
              type="message"
            />
          </label>
        )}
      </div>
      <div className="button-wrapper">
        <button
          type="submit"
          className={
            success ? 'landing-button-green landing-button' : 'landing-button'
          }
          disabled={success}
        >
          {success ? (
            'Success'
          ) : (
            <FormattedMessage {...messages.sendingRequest} />
          )}
        </button>
      </div>
    </form>
  );
}

ReduxForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  refer: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

ReduxForm.defaultProps = {
  refer: null,
};

const mapStateToProps = (state) => {
  const formSelector = formValueSelector('RequestInformationForm');
  return {
    initialValues: {
      size: '1-99',
    },
    refer: _.get(state, 'form.RequestInformationForm.values.refer'),
    paymentType: formSelector(state, 'size'),
  };
};

const withConnect = connect(mapStateToProps, null);

const Section10Form = compose(withConnect)(
  reduxForm({
    form: 'RequestInformationForm',
  })(ReduxForm),
);

export default Section10Form;
