/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';
import inkblotBizLogo from '../../images/EapPage/inkblot-biz-logo.svg';
import close from '../../images/EapPage/close.svg';

export function MobileNavOverlay({ scrollToBottom, height, setShowOverlay }) {
  function scrollToForm() {
    setShowOverlay(false);
    scrollToBottom();
  }
  return (
    <div className="mobile-nav-overlay" style={{ height }}>
      <img className="close" role="button" src={close} alt="Close" onClick={() => setShowOverlay(false)} />
      <Link to="/" onClick={() => setShowOverlay(false)}>
        <img src={inkblotBizLogo} alt="Inkblot for Business logo" />
      </Link>
      <div className="links">
        <Link
          to="/"
          onClick={() => setShowOverlay(false)}
        >
          <FormattedMessage {...messages.home} />
        </Link>
        <Link
          to="/eap"
          onClick={() => setShowOverlay(false)}
        >
          <FormattedMessage {...messages.eapCaps} />
        </Link>
        <a href="https://inkblottherapy.com/about">
          <FormattedMessage {...messages.aboutInkblot} />
        </a>
      </div>
      <button type="button" onClick={() => scrollToForm()}>
        <FormattedMessage {...messages.connect} />
      </button>
    </div>
  );
}

MobileNavOverlay.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
  height: PropTypes.string.isRequired,
};
