import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { MARKETING_URL } from 'utils/environment';
import FacebookLogo from 'images/LandingPage/footer/facebook-button.png';
import InstagramLogo from 'images/LandingPage/footer/instagram-button.png';
import LinkedInLogo from 'images/LandingPage/footer/linkedin-button.png';
import messages from './messages';

export function Footer() {
  return (
    <div className="footer-container">
      <div className="fc-sub-link">
        <ul>
          <li>
            <a href="https://inkblot.zendesk.com/hc/en-us">
              <span>
                <FormattedMessage {...messages.helpCentre} />
              </span>
            </a>
          </li>
          <li>
            <a href={`${MARKETING_URL}/blog`}>
              <span>
                <FormattedMessage {...messages.blog} />
              </span>
            </a>
          </li>
          <li>
            <a href={`${MARKETING_URL}/privacy`}>
              <span>
                <FormattedMessage {...messages.privacy} />
              </span>
            </a>
          </li>
          <li>
            <a href={`${MARKETING_URL}/conditions`}>
              <span>
                <FormattedMessage {...messages.termsAndConditions} />
              </span>
            </a>
          </li>
        </ul>
        <FormattedHTMLMessage {...messages.contactAt} />
        <div className="fc-contact">
          <FormattedHTMLMessage {...messages.forPress} />
        </div>
      </div>
      <div className="fc-social-link">
        <ul>
          <li>
            <a href="https://www.facebook.com/inkblotpsychotherapy/">
              <img src={FacebookLogo} alt="Twitter" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/inkblottherapy/">
              <img src={InstagramLogo} alt="Instagram" />
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/inkblottherapy/">
              <img src={LinkedInLogo} alt="LinkedIn" />
            </a>
          </li>
        </ul>
        <p className="copyright">
          © {new Date().getFullYear()} INKBLOT TECHNOLOGIES INC.
        </p>
      </div>
    </div>
  );
}
