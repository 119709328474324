import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

const TextWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: 'Roboto';
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 0.75rem;
    color: #9ea0a5;
    line-height: 1.125rem;
    text-transform: uppercase;
  }
`;
function AntMultiSelect({ input, width, options, label, placeholder }) {
  const { Option } = Select;
  return (
    <TextWrapper>
      <p className="tag">{label}</p>
      <Select
        {...input}
        mode="multiple"
        placeholder={placeholder}
        style={{ width: width || '400px' }}
      >
        {_.map(options, (o) => (
          <Option key={o.value} value={o.value}>
            <div>{o.label}</div>
          </Option>
        ))}
      </Select>
    </TextWrapper>
  );
}

AntMultiSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default AntMultiSelect;
