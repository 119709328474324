/* eslint-disable camelcase */
/**
 *
 * TeamManagement
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { Button, Icon } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import { userTypeOptions } from './constants';
import { makeSelectAdminMember } from './selectors';
import { createAdminMember, fetchAdminMembers } from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import DescriptionPopup from './DescriptionPopup';
import { required, validateEmail } from '../../utils/validation';
import { makeSelectCurrentUser } from '../App/selectors';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

export function AddAdmin(props) {
  const {
    currentUser: { inkblot_admin_yn },
  } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const onSubmit = (values) => {
    props.createAdminMember(values, (_member, error) => {
      if (!error) {
        setLoading(true);
        props.fetchAdminMembers(() => {
          setLoading(false);
          history.push('/administration');
        });
      }
    });
  };

  // if you are only admin, you cant be on this page
  if (!inkblot_admin_yn) {
    history.push('/my_account/company_information');
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">New Admin</h3>
      </SubHeaderContainer>
      <p style={{ fontStyle: 'italic' }}>
        Email must already exist in the system in order to add them.
      </p>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="email"
            label="ACCOUNT EMAIL"
            component={AntInput}
            validate={[required, validateEmail]}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="user_type"
            label="USER TYPE"
            width="180px"
            component={AntSelect}
            options={userTypeOptions()}
          />
          <DescriptionPopup />
        </FormRowContainer>
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.adminMember.error &&
              ((props.adminMember.error.data &&
                props.adminMember.error.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        <ButtonContainer>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Button
                htmlType="submit"
                type="primary"
                loading={props.adminMember.loading}
              >
                <Icon type="edit" style={{ verticalAlign: 0 }} />
                Add Admin
              </Button>
              <Button
                type="danger"
                onClick={() => history.push('/administration')}
              >
                Cancel
              </Button>
            </>
          )}
        </ButtonContainer>
      </form>
    </SubContainer>
  );
}

AddAdmin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createAdminMember: PropTypes.func.isRequired,
  fetchAdminMembers: PropTypes.func.isRequired,
  adminMember: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  adminMember: makeSelectAdminMember(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createAdminMember: (values, callback, companyId) =>
      dispatch(createAdminMember(values, callback, companyId)),
    fetchAdminMembers: (callback) => dispatch(fetchAdminMembers(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddAdminForm',
    initialValues: { user_type: 1 },
  })(AddAdmin),
);
