/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import messages from './messages';
import inkblotBizLogo from '../../images/EapPage/inkblot-biz-logo.svg';
import hamburger from '../../images/EapPage/hamburger-menu.svg';

export function NavbarTwo({ scrollToBottom, setShowOverlay }) {
  const path = window && window.location && window.location.pathname;
  const isFr = localStorage.getItem('locale') === 'fr';
  const aboutLink = isFr
    ? 'https://therapieinkblot.com/about'
    : 'https://inkblottherapy.com/about';
  return (
    <div className="nav-two">
      <Link to="/">
        <img src={inkblotBizLogo} alt="Inkblot for Business logo" />
      </Link>
      <div className="links">
        <Link
          to="/"
          style={
            path === '/'
              ? {
                fontWeight: 'bold',
                borderBottom: 'solid 3px #f9c200',
                paddingBottom: '5px',
              }
              : {}
          }
        >
          <FormattedMessage {...messages.home} />
        </Link>
        <Link
          to="/eap"
          style={
            path === '/eap'
              ? {
                fontWeight: 'bold',
                borderBottom: 'solid 3px #f9c200',
                paddingBottom: '5px',
              }
              : {}
          }
        >
          <FormattedMessage {...messages.eapCaps} />
        </Link>
        <a href={aboutLink}>
          <FormattedMessage {...messages.aboutInkblot} />
        </a>
      </div>
      <button type="button" onClick={scrollToBottom}>
        <FormattedMessage {...messages.connect} />
      </button>
      <div className="hamburger">
        <div role="presentation" onClick={() => setShowOverlay(true)}>
          <img
            style={{ marginTop: '5px', cursor: 'pointer' }}
            src={hamburger}
            alt="hamburger"
          />
        </div>
      </div>
    </div>
  );
}

NavbarTwo.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
  setShowOverlay: PropTypes.func.isRequired,
};
