import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the signIn state domain
 */

const selectSignInDomain = (state) => state.signIn || initialState;

/**
 * Other specific selectors
 */
const makeSelectSignInResult = () =>
  createSelector(selectSignInDomain, (substate) => substate.signInResult);

/**
 * Default selector used by SignIn
 */
const selectGlobal = (state) => state.global || initialState;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, (globalState) => globalState.currentUser);

const makeSelectSignIn = () =>
  createSelector(selectSignInDomain, (substate) => substate);

export default makeSelectSignIn;
export { selectSignInDomain, makeSelectSignInResult, makeSelectCurrentUser };
