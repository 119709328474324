import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: inline-block;
  text {
    font-family: 'Roboto';
    font-size: 16px;
    color: #3e3f42;
    font-weight: 500;
    margin-right: 10px;
  }
  > * {
    margin-right: 20px;
  }
`;
const Logo = styled.img`
  height: 50px;
`;

const LogoData = ({ url, type, key }) => {
  return (
    <Wrapper>
      <text>{type.replace(type[0], type[0].toUpperCase())}</text>
      <Logo id={key} src={url} alt={type || 'logo'} />
    </Wrapper>
  );
};

LogoData.propTypes = {
  key: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default LogoData;
