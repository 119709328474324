export const required = (value) => (value ? undefined : 'Required');
export const checkTrailingSpace = (value) =>
  value && /\s+$/i.test(value) ? 'Remove trailing space' : undefined;

export const validateEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const validatePhone = (value) =>
  value && !/^\d{3}-\d{3}-\d{4}$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;

export const validatePostalCode = (value) =>
  value && !/^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/i.test(value)
    ? 'Invalid postal code, must follow A1A 1A1'
    : undefined;

export const validateUrl = (value) =>
  value && !/^[a-zA-Z0-9_-]*$/i.test(value)
    ? 'A url must not contain any special characters'
    : undefined;

export const validateNumeric = (value) =>
  value && !/^[1-9]\d*(\.\d+)?$/i.test(value) ? 'Invalid entry' : undefined;

export const passwordsMatch = (value, allValues) =>
  value !== allValues.password ? "Passwords don't match" : undefined;

export const passwordLength = (val) =>
  val && val.length < 8 ? 'Passwords must be at least 8 characters' : undefined;

export const optional = (_, allValues) =>
  allValues.email || allValues.employee_id
    ? undefined
    : 'Either work email or employee ID is required';

export const isTranquilityURL = (value) => {
  const tranquilityURLRegex = /(^https?:\/\/.*\.tranquility.app\/*)/gm;

  if (tranquilityURLRegex.test(value)) {
    return null;
  }
  return 'Please enter a url. Example: https://cineple.tranquility.app';
};
