import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the companyCreation state domain
 */

const selectCompanyCreationDomain = (state) =>
  state.peopleCompanyCreation || initialState;

/**
 * Other specific selectors
 */

const makeSelectCompanies = () =>
  createSelector(
    selectCompanyCreationDomain,
    (substate) => substate.companies.data,
  );
/**
 * Default selector used by CompanyCreation
 */

const makeSelectCompanyCreation = () =>
  createSelector(selectCompanyCreationDomain, (substate) => substate);

const makeSelectCompanyCreationError = () =>
  createSelector(
    (state) => {
      return state.peopleCompanyCreation?.companies?.error?.data?.error;
    },
    (substate) => substate,
  );

export default makeSelectCompanyCreation;
export {
  selectCompanyCreationDomain,
  makeSelectCompanies,
  makeSelectCompanyCreationError,
};
