/**
 *
 * AntInput
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const TextWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: 'Roboto';
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 0.75rem;
    color: #9ea0a5;
    line-height: 1.125rem;
    text-transform: uppercase;
  }
  p.data {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3e3f42;
  }
  .error-warning {
    display: block;
    color: red;
    font-size: 9px;
    margin-top: 8px;
  }
`;
function AntInput(props) {
  const {
    type,
    style,
    input,
    label,
    meta: { touched, error, warning },
  } = props;
  return (
    <TextWrapper {...props}>
      <p className="tag">{label}</p>
      {type === 'password' ? (
        <Input.Password
          {...props}
          {...input}
          value={[input.value]}
          style={{ width: '180px', ...style }}
          type="password"
        />
      ) : (
        <Input
          {...props}
          {...input}
          value={[input.value]}
          style={{ width: '180px', ...style }}
          type="text"
        />
      )}
      {touched &&
        ((error && <span className="error-warning">{error}</span>) ||
          (warning && <span className="error-warning">{warning}</span>))}
    </TextWrapper>
  );
}

AntInput.propTypes = {
  input: PropTypes.object.isRequired,
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.func,
};

export default AntInput;
