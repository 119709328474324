import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';
import queryString from 'query-string';

import { API_URL } from 'utils/environment';
import { get, post, put } from 'utils/request';

import {
  FETCH_TEAM_MEMBERS,
  CREATE_TEAM_MEMBER,
  FETCH_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER,
  DESTROY_TEAM_MEMBER,
} from './constants';
import {
  fetchTeamMembersSuccess,
  fetchTeamMembersError,
  createTeamMemberSuccess,
  createTeamMemberError,
  fetchTeamMemberSuccess,
  fetchTeamMemberError,
  updateTeamMemberSuccess,
  updateTeamMemberError,
  destroyTeamMemberSuccess,
  destroyTeamMemberError,
} from './actions';

function* fetchTeamMembers({ companyId }) {
  const requestUrl = `${API_URL}/team_members`;
  const params = companyId && queryString.stringify({ company_id: companyId });

  try {
    const members = yield call(get, `${requestUrl}?${params}`);
    yield reduxPut(fetchTeamMembersSuccess(members));
  } catch (error) {
    yield reduxPut(fetchTeamMembersError(error));
  }
}

function* createTeamMember({ values, callback, companyId }) {
  const requestUrl = `${API_URL}/team_members`;

  try {
    const member = yield call(
      post,
      requestUrl,
      companyId ? { ...values, company_id: companyId } : values,
    );
    yield reduxPut(createTeamMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(createTeamMemberError(error));
    if (callback) callback(null, error);
  }
}

function* fetchTeamMember({ id, callback, companyId }) {
  const requestUrl = `${API_URL}/team_members/${id}`;
  const params = companyId && queryString.stringify({ company_id: companyId });

  try {
    const member = yield call(get, `${requestUrl}?${params}`);
    yield reduxPut(fetchTeamMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(fetchTeamMemberError(error));
    if (callback) callback(null, error);
  }
}

function* updateTeamMember({ id, values, callback, companyId }) {
  const requestUrl = `${API_URL}/team_members/${id}`;

  try {
    const member = yield call(
      put,
      requestUrl,
      companyId ? { ...values, company_id: companyId } : values,
    );
    yield reduxPut(updateTeamMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(updateTeamMemberError(error));
    if (callback) callback(null, error);
  }
}

function* destroyTeamMember({ id, companyId }) {
  const requestUrl = `${API_URL}/team_members/remove_user`;

  try {
    const member = yield call(put, requestUrl, { id, company_id: companyId });
    yield reduxPut(destroyTeamMemberSuccess(member));
  } catch (error) {
    yield reduxPut(destroyTeamMemberError(error));
  }
}

export default function* teamManagementSaga() {
  yield takeLatest(FETCH_TEAM_MEMBERS, fetchTeamMembers);
  yield takeLatest(CREATE_TEAM_MEMBER, createTeamMember);
  yield takeLatest(FETCH_TEAM_MEMBER, fetchTeamMember);
  yield takeLatest(UPDATE_TEAM_MEMBER, updateTeamMember);
  yield takeLatest(DESTROY_TEAM_MEMBER, destroyTeamMember);
}
