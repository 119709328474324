import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import tableDesktop from '../../images/LandingPage/tables/feature-table-desktop.png';
import tableTablet from '../../images/LandingPage/tables/feature-table-tablet.png';
import tableMobile from '../../images/LandingPage/tables/feature-table-mobile.png';
import tableDesktopFr from '../../images/LandingPage/tables/feature-table-desktop-fr.png';
import tableTabletFr from '../../images/LandingPage/tables/feature-table-tablet-fr.png';
import tableMobileFr from '../../images/LandingPage/tables/feature-table-mobile-fr.png';
import messages from './messages';

export function SectionSix({ windowWidth }) {
  const isFr = localStorage.getItem('locale') === 'fr';

  return (
    <div className="section-6">
      <div>
        <h2>
          <FormattedMessage {...messages.oneSize} />
        </h2>
        <p>
          <FormattedMessage {...messages.ourMental} />
        </p>
        <img
          src={isFr ? tableDesktopFr : tableDesktop}
          alt="Table of features"
          className="table-desktop"
        />
        <img
          src={
            windowWidth > 768
              ? isFr
                ? tableTabletFr
                : tableTablet
              : isFr
              ? tableMobileFr
              : tableMobile
          }
          alt="Table of features"
          className="table-mobile"
        />
      </div>
    </div>
  );
}

SectionSix.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};
