/**
 *
 * Reporting
 *
 */

import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { createStructuredSelector } from 'reselect';
import { Select, DatePicker, Button } from 'antd';
import { compose } from 'redux';
import queryString from 'query-string';

import { UikWidget, UikLayoutMain } from '@uik/lib';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { API_URL } from 'utils/environment';
import { makeSelectCurrentCompanyId } from 'containers/App/selectors';
import { headers } from 'utils/auth';

import reducer from './reducer';
import saga from './saga';
import { reportTypeOptions, reportPeriods } from './reportTypeOptions';

export function Reporting({ currentCompanyId }) {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reportType, setReportType] = useState('');
  const [reportPeriod, setReportPeriod] = useState(3);
  const [emailSent, setEmailSent] = useState('');
  const { Option } = Select;

  useInjectReducer({ key: 'reporting', reducer });
  useInjectSaga({ key: 'reporting', saga });

  const periodReports = [
    'access_small',
    'access_large',
    'access_small_plus',
    'access_large_plus',
    'efap_small',
    'efap_large',
  ];

  const onSubmit = () => {
    const params = queryString.stringify({
      company_id: currentCompanyId,
      start_date: startDate,
      end_date: endDate,
      report_type: reportType,
      period_length: reportPeriod,
    });

    axios
      .get(`${API_URL}/reports?${params}`, headers())
      .then(() => {
        setEmailSent('Reporting email sent!');
      })
      .catch(() => {
        setEmailSent('Reporting email failed!');
      });
  };

  const validateTime = () => {
    const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 365) return false;
    return true;
  };

  return (
    <UikLayoutMain>
      <Helmet>
        <title>Reporting</title>
        <meta name="description" content="Description of Reporting" />
      </Helmet>
      <UikWidget padding>
        <h2 className="header">Reporting</h2>
        <div>
          <p>Report Type</p>
          <Select
            value={reportType}
            onChange={(value) => setReportType(value)}
            style={{ width: '170px' }}
          >
            {reportTypeOptions.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
          <br />
          <br />
          {reportType && periodReports.includes(reportType) && (
            <>
              <p>Period Length</p>
              <Select
                value={reportPeriod}
                onChange={(value) => setReportPeriod(value)}
                style={{ width: '170px' }}
              >
                {reportPeriods.map(({ value, label }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
              <br />
              <br />
            </>
          )}
          <p>Start Range</p>
          <DatePicker
            name="start_date"
            onChange={(_date, dateString) => setStartDate(dateString)}
          />
          <br />
          <br />
          <p>End Range</p>
          <DatePicker
            name="end_date"
            onChange={(_date, dateString) => setEndDate(dateString)}
          />
          {startDate && endDate && !validateTime() && (
            <p style={{ color: 'gray' }}>
              Please note that the report may not run properly if a date range
              is selected for longer than 1 year.
            </p>
          )}
          <br />
          <br />
          <Button type="primary" onClick={onSubmit}>
            Submit
          </Button>
          {emailSent && (
            <p style={{ marginTop: '20px', marginBottom: 0 }}>{emailSent}</p>
          )}
        </div>
      </UikWidget>
    </UikLayoutMain>
  );
}

Reporting.propTypes = {
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Reporting);
