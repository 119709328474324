/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/stable';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import FontFaceObserver from 'fontfaceobserver';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import history from 'utils/history';
import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.css';
// Import root app
import App from 'containers/App';
import LDProvider from 'containers/LDProvider';
import { BrowserRouter } from 'react-router-dom';

// Import Language Provider
import { LanguageProvider } from 'containers/LanguageProvider';

// import environment
import { SENTRY_DSN, ENVIRONMENT, GA_KEY } from 'utils/environment';

import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Hook Sentry up
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_APP_VERSION, // eslint-disable-line no-undef
  });
}

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Open Sans', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

ReactGA.initialize(GA_KEY);

const render = (messages) => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider
        messages={messages}
        locale={localStorage.getItem('locale') || 'en'}
      >
        <HelmetProvider>
          <BrowserRouter history={history}>
            <LDProvider>
              <App />
            </LDProvider>
          </BrowserRouter>
        </HelmetProvider>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() =>
      Promise.all([
        import('intl/locale-data/jsonp/en.js'),
        import('intl/locale-data/jsonp/fr.js'),
      ]),
    ) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch((err) => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
