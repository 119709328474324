/**
 *
 * TeamManagement
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { UikWidget, UikLayoutMain } from '@uik/lib';
import { fetchTeamMembers } from './actions';
import makeSelectTeamManagement from './selectors';
import reducer from './reducer';
import saga from './saga';
import TeamList from './TeamList';
import AddAdmin from './AddAdmin';
import ModifyAdmin from './ModifyAdmin';

export function TeamManagement({
  match,

  currentCompanyId,
  ...props
}) {
  useInjectReducer({ key: 'teamManagement', reducer });
  useInjectSaga({ key: 'teamManagement', saga });

  useEffect(() => {
    if (currentCompanyId) {
      props.fetchTeamMembers(currentCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  return (
    <UikLayoutMain>
      <UikWidget padding>
        <h2 className="header">Team Management</h2>
        <Switch>
          <Route path={`${match.url}/add_admin`} component={AddAdmin} />
          <Route
            path={`${match.url}/:id/modify_admin`}
            component={ModifyAdmin}
          />
          <Route path={`${match.url}`} component={TeamList} />
        </Switch>
      </UikWidget>
    </UikLayoutMain>
  );
}

TeamManagement.propTypes = {
  fetchTeamMembers: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  teamManagement: makeSelectTeamManagement(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchTeamMembers: (companyId) => dispatch(fetchTeamMembers(companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TeamManagement);
