/*
 *
 * AddCompany actions
 *
 */

import {
  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR,
} from './constants';

export function createReport(values, callback) {
  return {
    values,
    callback,
    type: CREATE_REPORT,
  };
}

export function createReportSuccess(company) {
  return {
    company,
    type: CREATE_REPORT_SUCCESS,
  };
}

export function createReportError(error) {
  return {
    error,
    type: CREATE_REPORT_ERROR,
  };
}
