import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: ${(props) => props.maxWidth || '500px'};
  margin-bottom: 40px;
  gap: 12px;
`;

const StyledContainer = ({ children, maxWidth, style }) => {
  return (
    <Container maxWidth={maxWidth} style={style}>
      {children}
    </Container>
  );
};

export default StyledContainer;
