/*
 *
 * AddCompany reducer
 *
 */

import produce from 'immer';
import {
  CREATE_REPORT,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_ERROR,
} from './constants';

export const initialState = {
  report: {
    loading: false,
    error: false,
    errorMessage: '',
    data: undefined,
  },
};

/* eslint-disable default-case, no-param-reassign */
const dashboardReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_REPORT:
        draft.report.loading = true;
        draft.report.error = false;
        draft.report.data = undefined;
        break;
      case CREATE_REPORT_SUCCESS:
        draft.report.loading = false;
        draft.report.error = false;
        draft.report.errorMessage = false;
        draft.report.data = action.report;
        break;
      case CREATE_REPORT_ERROR:
        draft.report.loading = false;
        draft.report.error = true;
        break;
    }
  });

export default dashboardReducer;
