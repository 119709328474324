/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/**
 *
 * TeamManagement
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import _ from 'lodash';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { Button, Icon } from 'antd';
import moment from 'moment';
import AntDatePicker from 'components/AntDatePicker';
import AntTimePicker from 'components/AntTimePicker';
import AntSelect from 'components/AntSelect';
import AntInput from 'components/AntInput';
import Checkbox from 'components/RfCheckbox';
import TextEditor from 'components/TextEditor';
import {
  fetchEmailReminder,
  updateEmailReminder,
  fetchEmailReminderTemplates,
  fetchEmailReminders,
} from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import {
  makeSelectEmailReminder,
  makeSelectEmailReminderTemplates,
} from './selectors';
import { useHistory } from 'react-router-dom';

const required = (value) => (value ? undefined : 'Required');

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;

const FormColContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 263px;
  display: flex;
  > div {
    width: 100%;
  }
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

const audience = [
  { label: 'Everybody', value: 'full' },
  { label: "Those who haven't registered", value: 'no_register' },
  { label: "Those who haven't done assess", value: 'no_assess' },
];

const language_options = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'English and French', value: 'en_fr' },
  { label: 'French and English', value: 'fr_en' },
];

export function ModifyEmployee(props) {
  const { currentCompanyId, currentUser, template, send_now, language } = props;
  const [lines, setLines] = useState(1);
  const [middleLines, setMiddleLines] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    props.fetchEmailReminderTemplates();
    props.fetchEmailReminder(
      props.match.params.id,
      (reminder, error) => {
        if (!error) {
          props.change(
            'email_reminder_template_id',
            reminder.email_reminder_template_id,
          );
          props.change(
            'use_as_welcome_email_yn',
            reminder.custom_email.use_as_welcome_email_yn,
          );
          props.change(
            'send_date',
            moment(reminder.scheduled_send_date, 'YYYY-MM-DDTHH:mm:ss'),
          );
          props.change(
            'send_time',
            moment(reminder.scheduled_send_date, 'YYYY-MM-DDTHH:mm:ss'),
          );
          props.change('status', reminder.status);
          if (!_.isEmpty(reminder.custom_email)) {
            const { custom_email } = reminder;
            const mainLine =
              custom_email.main_lines || custom_email.stacked_lines;
            if (mainLine) {
              setLines(parseInt(mainLine.length, 10));
            }
            const middleLine =
              custom_email.middle_lines || custom_email.middle_stacked_lines;
            if (middleLine) {
              setMiddleLines(parseInt(middleLine.length, 10));
            }
            props.change('subject', custom_email.subject);
            props.change('audience', custom_email.audience);
            props.change('language', custom_email.language);
            _.each(custom_email.main_lines, (mLine, i) =>
              props.change(`line${i}`, mLine),
            );
            _.each(custom_email.middle_lines, (mLine, i) =>
              props.change(`middle_line${i}`, mLine),
            );
            _.each(custom_email.signature_lines, (sLine, i) =>
              props.change(`signature_line${i}`, sLine),
            );
            _.each(custom_email.stacked_lines, (sLine, i) =>
              props.change(`french_line${i}`, sLine),
            );
            _.each(custom_email.middle_stacked_lines, (sLine, i) =>
              props.change(`middle_french_line${i}`, sLine),
            );
            _.each(custom_email.french_signature_lines, (fLine, i) =>
              props.change(`french_signature_line${i}`, fLine),
            );
          }
        }
      },
      currentCompanyId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const onSubmit = (values) => {
    setLoading(true);
    const time = values.send_time ? values.send_time.format('HH:mm') : '05:00';
    if (values.send_now_yn) {
      values.scheduled_send_date = moment().format();
    } else {
      values.scheduled_send_date = moment(
        `${values.send_date.format('YYYY-MM-DD')} ${time}`,
      ).format('YYYY-MM-DD HH:mm');
    }
    if (values.language !== 'fr' && !_.isNull(values.line0)) {
      values.main_lines = [];
      values.middle_lines = [];
      for (let i = 0; i < lines; i += 1) {
        values.main_lines.push(values[`line${i}`]);
      }
      for (let i = 0; i < middleLines; i += 1) {
        values.middle_lines.push(values[`middle_line${i}`]);
      }
      values.signature_lines = [values.signature_line0, values.signature_line1];
    }
    if (values.language !== 'en' && !_.isNull(values.french_line0)) {
      values.stacked_lines = [];
      values.middle_stacked_lines = [];
      for (let i = 0; i < lines; i += 1) {
        values.stacked_lines.push(values[`french_line${i}`]);
      }
      for (let i = 0; i < middleLines; i += 1) {
        values.middle_stacked_lines.push(values[`middle_french_line${i}`]);
      }
      values.french_signature_lines = [
        values.french_signature_line0,
        values.french_signature_line1,
      ];
    }
    props.updateEmailReminder(
      props.match.params.id,
      values,
      (_reminder, error) => {
        if (!error) {
          setLoading(false);
          props.fetchEmailReminders(currentCompanyId);
          history.push('/email_reminders');
        }
      },
    );
  };

  const formatEmailTemplates = (reminders) => {
    if (!reminders) return [];
    const array = reminders.map((email) => ({
      id: email.id,
      label: email.friendly_name,
      value: email.id,
    }));
    return array;
  };

  const custom = (reminders, template_id) => {
    const custom_template = _.find(
      reminders,
      (temp) => temp.template_name === 'custom_email',
    );
    if (_.get(custom_template, ['id'], null) === template_id) {
      return true;
    }
    return false;
  };

  const renderLines = (selectedLang) => {
    if (selectedLang === 'fr') {
      return renderFrench();
    }
    if (selectedLang === 'en_fr') {
      return (
        <>
          {renderEnglish()}
          {renderFrench()}
        </>
      );
    }
    if (selectedLang === 'fr_en') {
      return (
        <>
          {renderFrench()}
          {renderEnglish()}
        </>
      );
    }
    return renderEnglish();
  };
  const renderLineNumberButton = (type) => {
    const increase = (pre) => pre + 1;
    const decrease = (pre) => pre - 1;
    return (
      <ButtonContainer style={{ marginBottom: 25 }}>
        <Button
          htmlType="button"
          type="primary"
          onClick={() =>
            type === 'middle' ? setMiddleLines(increase) : setLines(increase)
          }
        >
          <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
        </Button>
        <Button
          htmlType="button"
          type="danger"
          onClick={() =>
            type === 'middle' ? setMiddleLines(decrease) : setLines(decrease)
          }
        >
          <Icon type="delete" style={{ verticalAlign: 0 }} />
        </Button>
      </ButtonContainer>
    );
  };
  const renderEnglish = () => {
    return (
      <>
        {/* Before CTA Button Paragraph Lines */}
        {unlimitedLines()}
        {renderLineNumberButton()}
        {/* After CTA Button Paragraph Lines */}
        {unlimitedLines('middle')}
        {renderLineNumberButton('middle')}
        <>
          <FormRowContainer>
            <Field
              name="signature_line0"
              label="Closing"
              style={{ width: 300 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="signature_line1"
              label="From"
              style={{ width: 750 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
        </>
      </>
    );
  };

  const renderFrench = () => {
    return (
      <>
        {/* Before CTA Button Paragraph Lines */}
        {unlimitedLines(null, 'french')}
        {renderLineNumberButton()}
        {/* After CTA Button Paragraph Lines */}
        {unlimitedLines('middle', 'french')}
        {renderLineNumberButton('middle')}
        <>
          <FormRowContainer>
            <Field
              name="french_signature_line0"
              label="French Closing"
              style={{ width: 300 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="french_signature_line1"
              label="French From"
              style={{ width: 750 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
        </>
      </>
    );
  };
  const unlimitedLines = (lineType, lang) => {
    const paragraphs = Array(
      lineType === 'middle' ? middleLines : lines,
    ).fill();
    const languageKey = lang ? `${lang}_` : '';
    return paragraphs.map((a, i) => {
      const name =
        lineType === 'middle'
          ? `middle_${languageKey}line${i}`
          : `${languageKey}line${i}`;
      return (
        <FormColContainer key={name}>
          <Field
            name={name}
            label={`${lineType === 'middle' ? 'After CTA button ' : ''}${
              lang ? lang.charAt(0).toUpperCase() + lang.slice(1) : 'English'
            } Paragraph ${i + 1}`}
            component={TextEditor}
            validate={[required]}
          />
        </FormColContainer>
      );
    });
  };

  // if you are only admin, you cant be on this page
  if (!_.isEmpty(currentUser) && !currentUser.inkblot_admin_yn) {
    history.push('/my_account/company_information');
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">Edit Reminder</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="email_reminder_template_id"
            label="Email Template"
            width="300px"
            component={AntSelect}
            options={formatEmailTemplates(props.emailReminderTemplates.data)}
          />
        </FormRowContainer>
        {custom(props.emailReminderTemplates.data, template) && (
          <>
            <FormRowContainer>
              <Field
                name="use_as_welcome_email_yn"
                label="Replace as welcome email"
                component={Checkbox}
              />
            </FormRowContainer>
            <FormRowContainer>
              <Field
                name="subject"
                label="Subject"
                style={{ width: 500 }}
                component={AntInput}
                validate={[required]}
              />
            </FormRowContainer>
            <FormRowContainer>
              <Field
                name="language"
                label="Language"
                width="300px"
                component={AntSelect}
                options={language_options}
                validate={[required]}
              />
            </FormRowContainer>
            {renderLines(language)}
            <FormRowContainer>
              <Field
                name="audience"
                label="Audience"
                width="300px"
                component={AntSelect}
                options={audience}
                validate={[required]}
              />
            </FormRowContainer>
          </>
        )}
        <FormRowContainer>
          <Field name="send_now_yn" label="Send Now" component={Checkbox} />
        </FormRowContainer>
        {!send_now && (
          <FormRowContainer>
            <Field
              name="send_date"
              label="Day to send"
              component={AntDatePicker}
              validate={[required]}
            />
            <Field
              name="send_time"
              label="Time to send (EST)"
              component={AntTimePicker}
            />
          </FormRowContainer>
        )}
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.emailReminderTemplates.error &&
              ((props.emailReminderTemplates.error &&
                props.emailReminderTemplates.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        <ButtonContainer>
          <Button loading={loading} htmlType="submit" type="primary">
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Schedule Reminder
          </Button>
          <Button
            type="danger"
            onClick={() => history.push('/email_reminders')}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </SubContainer>
  );
}

ModifyEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchEmailReminder: PropTypes.func.isRequired,
  fetchEmailReminders: PropTypes.func,
  updateEmailReminder: PropTypes.func.isRequired,
  emailReminderTemplates: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  fetchEmailReminderTemplates: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
  template: PropTypes.number,
  send_now: PropTypes.bool,
  language: PropTypes.string,
};

function mapStateToProps(state) {
  const selector = formValueSelector('ModifyAdminForm');

  const sagaMapStateToProps = createStructuredSelector({
    currentUser: makeSelectCurrentUser(),
    emailReminder: makeSelectEmailReminder(),
    emailReminderTemplates: makeSelectEmailReminderTemplates(),
    currentCompanyId: makeSelectCurrentCompanyId(),
  })(state);

  return {
    template: selector(state, 'email_reminder_template_id'),
    send_now: selector(state, 'send_now_yn'),
    language: selector(state, 'language'),
    ...sagaMapStateToProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchEmailReminder: (id, callback, companyId) =>
      dispatch(fetchEmailReminder(id, callback, companyId)),
    updateEmailReminder: (id, values, callback, companyId) =>
      dispatch(updateEmailReminder(id, values, callback, companyId)),
    fetchEmailReminderTemplates: () => dispatch(fetchEmailReminderTemplates()),
    fetchEmailReminders: (companyId) =>
      dispatch(fetchEmailReminders(companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    enableReinitialize: true,
    form: 'ModifyAdminForm',
  })(ModifyEmployee),
);
