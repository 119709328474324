/** *
 * CompanyInformation
 *
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'lodash';
import { Button, Divider } from 'antd';
import Basics from 'containers/MyAccount/components/CompanyInformation/Basics';
import Contacts from 'containers/MyAccount/components/CompanyInformation/Contacts';
import BasicsForm from 'containers/MyAccount/components/CompanyInformation/BasicsForm';
import AddressForm from 'containers/MyAccount/components/CompanyInformation/AddressForm';
import { parseContact, safe } from './CompanyInformation/helpers';
import StyledContainer from './CompanyInformation/Container';
import TextData from './CompanyInformation/TextData';

class CompanyInformation extends Component {
  state = {
    editingBasics: false,
    editingAddresses: false,
  };

  toggleEdit = (field) => {
    this.setState((state) => ({
      [field]: !state[field],
    }));
  };

  render() {
    const { company = {}, currentUser } = this.props;
    const { editingBasics, editingAddresses } = this.state;
    const renderTranquilityType = (type) => {
      if (type === 0) return 'Self-Directed';
      if (type === 1) return 'Coach-Assisted';
      return type;
    };
    return (
      <>
        <h3 className="sub-header">Basics</h3>
        {editingBasics ? (
          <BasicsForm
            company={company}
            toggle={() => this.toggleEdit('editingBasics')}
          />
        ) : (
          <Basics
            currentUser={currentUser}
            company={company}
            toggle={() => this.toggleEdit('editingBasics')}
          />
        )}
        {
          company.company_service &&
          company.company_service
            .tranquility_service_yn && <>
            <Divider />
            <h3 className="sub-header">Tranquility</h3>
            <StyledContainer>
              <TextData
                tag="Tranquility Type"
                data={renderTranquilityType(
                  safe(company, 'company_service.tranquility_type'),
                )}
                width="100%"
              />
              <TextData
                tag="Tranquility URL"
                data={safe(company, 'company_service.tranquility_url')}
                width="100%"
              />
            </StyledContainer>
          </>
          // prettier-ignore
        }
        {currentUser.inkblot_admin_yn && (
          <>
            <Divider />
            <h3 className="sub-header">Contact Information</h3>
            {editingAddresses ? (
              <AddressForm
                toggle={() => this.toggleEdit('editingAddresses')}
                company={company}
                primary={parseContact(company.company_contacts, 'primary')}
                secondary={parseContact(company.company_contacts, 'secondary')}
                billing={parseContact(company.company_contacts, 'billing')}
              />
            ) : (
              <>
                <Contacts
                  tag="Primary contact"
                  contact={parseContact(company.company_contacts, 'primary')}
                />
                <Contacts
                  tag="Secondary contact"
                  contact={parseContact(company.company_contacts, 'secondary')}
                />
                <Contacts
                  tag="Billing contact"
                  contact={parseContact(company.company_contacts, 'billing')}
                />
                <Button
                  onClick={() => this.toggleEdit('editingAddresses')}
                  htmlType="submit"
                  type="default"
                >
                  Edit Contact Information
                </Button>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const company = _.get(state, 'myAccount.company.data');
  const currentUser = _.get(state, 'global.currentUser');
  return {
    currentUser,
    company,
    initialValues: {},
  };
};

const withConnect = connect(mapStateToProps, null);

CompanyInformation.propTypes = {
  company: PropTypes.object,
  currentUser: PropTypes.object,
};

CompanyInformation.defaultProps = {
  company: {},
};

export default compose(withConnect)(CompanyInformation);
