import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import rewritingThePlaybook from '../../images/EapPage/rewritingtheplaybook.png';
import yellowQuote from '../../images/EapPage/yellow-quote.svg';
import weatherNetworkLogo from '../../images/EapPage/weather-network-logo-clear.svg';
import messages from './messages';

export function SectionThree() {
  return (
    <div className="section-three">
      <img
        className="image"
        src={rewritingThePlaybook}
        alt="Rewriting the playbook"
      />
      <div className="quote">
        <img className="yellow-quote" src={yellowQuote} alt="Yellow quote" />
        <FormattedHTMLMessage {...messages.thePlaybook} />
        <div className="person">
          <img src={weatherNetworkLogo} alt="Weather Network logo" />
          <div>
            <h4>Anna Petosa</h4>
            <span className="job-title">
              <FormattedMessage {...messages.vpPeople} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

SectionThree.propTypes = {};
