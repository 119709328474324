/**
 *
 * CompanyCreation
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Button, Icon } from 'antd';
import moment from 'moment';
import AntCheckbox from 'components/AntCheckbox';
import AntInput from 'components/AntInput';
import AntDatePicker from 'components/AntDatePicker';
import AntTimePicker from 'components/AntTimePicker';
import AntSelect from 'components/AntSelect';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { required } from 'utils/validation';
import { LAST_COMPANY_KEY } from 'containers/App/constants';
import { fetchCurrentUser } from 'containers/App/actions';
import TextEditor from 'components/TextEditor';
import {
  makeSelectCompanies,
  makeSelectCompanyCreationError,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import ProviderCoveragesField from '../CompanyCreation/ProviderCoveragesField';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import { createCompany, resetCompanyError } from './actions';
import { useHistory } from 'react-router-dom';

const FormRowContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  > div {
    margin: 0 5% 0 0;
  }
`;

const SectionContainer = styled.div`
  h4 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const planTypes = [
  { value: 'eap', label: 'EAP' },
  { value: 'total_health', label: 'PC Total Health' },
  { value: 'people_connect', label: 'People Connect' },
  {
    value: 'people_connect_integrated_solutions',
    label: 'People Connect Integrated Solutions',
  },
  { value: 'bbd_eap', label: 'BBD EAP' },
  { value: 'bbd_bap', label: 'BBD Carepath BAP' },
];

const peopleTypes = [
  { value: 'people_corp', label: 'People Corp' },
  { value: 'life_inc', label: 'Life Inc' },
];

export function PeopleCompanyCreation(props) {
  const { companyCreationError, resetCompanyError } = props;
  useInjectReducer({ key: 'peopleCompanyCreation', reducer });
  useInjectSaga({ key: 'peopleCompanyCreation', saga });
  const history = useHistory();
  const [successMessage, setSuccessMessage] = useState(null);
  useEffect(() => {
    setSuccessMessage(companyCreationError);
  }, [companyCreationError]);
  const handleFormChanged = () => {
    resetCompanyError();
  };

  const onSubmit = (values) => {
    setSuccessMessage('Creating company...');
    const params = { ...values };
    params.contract_details.launch_date = moment(
      `${values.contract_details.launch_date.format(
        'YYYY-MM-DD',
      )} ${values.contract_details.launch_time.format('HH:mm:ss')}`,
    );

    if (!additionalServiceSelector) {
      delete values.company_additional_services.financial_yn;
      delete values.company_additional_services.legal_yn;
      delete values.company_additional_services.career_yn;
      delete values.company_additional_services.health_yn;
      delete values.company_additional_services.research_yn;
    }

    if (!couplesCounselling) {
      delete values.company_minutes.video_couples_access_employee_minutes;
      delete values.company_minutes.video_couples_access_dependant_minutes;
      delete values.company_minutes.video_couples_bite_employee_minutes;
      delete values.company_minutes.video_couples_bite_dependant_minutes;
    }

    if (!values.company_infos.requires_special_notice_for_employees) {
      delete values.company_infos.special_notice_for_employees;
    }

    if (!values.company_infos.requires_special_notice_for_dependants) {
      delete values.company_infos.special_notice_for_dependants;
    }

    props.createCompany(values, (resp) => {
      setSuccessMessage('Setting dropdown...');
      localStorage.setItem(LAST_COMPANY_KEY, resp.id);
      props.fetchCurrentUser();
      setSuccessMessage('Redirecting...');
      history.push('/my_account/company_information');
      setSuccessMessage(null);
    });
  };

  const renderEmployeeMinutes = () => {
    const { planSelector } = props;
    if (planSelector === 'eap') {
      return (
        <Field
          name="company_minutes.video_bite_employee_minutes"
          label="Employee Minutes"
          component={AntInput}
          onChange={handleFormChanged}
        />
      );
    }
    return (
      <Field
        name="company_minutes.video_bite_employee_minutes"
        label="Employee Minutes"
        component={AntInput}
        onChange={handleFormChanged}
      />
    );
  };

  const renderDependentMinutes = () => {
    const { planSelector } = props;
    if (planSelector === 'eap') {
      return (
        <Field
          name="company_minutes.video_bite_dependant_minutes"
          label="Dependent Minutes"
          onChange={handleFormChanged}
          component={AntInput}
        />
      );
    }
    return (
      <Field
        name="company_minutes.video_access_dependant_minutes"
        label="Dependent Minutes"
        onChange={handleFormChanged}
        component={AntInput}
      />
    );
  };
  const renderCouplesMinutes = () => {
    const { planSelector, dependantsSelector } = props;
    if (planSelector === 'eap') {
      return (
        <React.Fragment>
          <Field
            name="company_minutes.video_couples_bite_employee_minutes"
            label="Employee Minutes (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
          {dependantsSelector && (
            <Field
              name="company_minutes.video_couples_bite_dependant_minutes"
              label="Dependant Minutes (Couples)"
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Field
          name="company_minutes.video_couples_access_employee_minutes"
          label="Employee Minutes (Couples)"
          component={AntInput}
          validate={[required]}
          onChange={handleFormChanged}
        />
        {dependantsSelector && (
          <Field
            name="company_minutes.video_couples_access_dependant_minutes"
            label="Dependant Minutes (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
        )}
      </React.Fragment>
    );
  };
  const {
    // eslint-disable-next-line react/prop-types
    automatedEmails,
    // eslint-disable-next-line react/prop-types
    requiresSpecialNoticeForEmployees,
    // eslint-disable-next-line react/prop-types
    requiresSpecialNoticeForDependants,
    additionalServiceSelector,
    dependantsSelector,
    couplesCounselling,
  } = props;

  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">New People Company</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <SectionContainer>
          <h4>Basics</h4>
          <FormRowContainer>
            <Field
              name="companies.name"
              label="Company Name"
              style={{ width: 250 }}
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="company_infos.alias"
              label="Alias(es)"
              style={{ width: 250 }}
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="people_type"
              label="What portal?"
              width="180px"
              component={AntSelect}
              options={peopleTypes}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="french_yn"
              label="Include French in Emails"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="automate_emails_yn"
              label="Launch with standard email cadence"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            {automatedEmails && (
              <Field
                name="contract_details.launch_time"
                label="Welcome email send time"
                component={AntTimePicker}
                validate={[required]}
                onChange={handleFormChanged}
              />
            )}
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <h4>Benefit Information</h4>
          <FormRowContainer>
            <Field
              name="companies.plan_type"
              label="Plan Type"
              width="300px"
              component={AntSelect}
              options={planTypes}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="contract_details.contract_start"
              label="Contract Start Date"
              component={AntDatePicker}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="contract_details.launch_date"
              label="Launch Date"
              component={AntDatePicker}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="contract_details.dependants_yn"
              label="Dependents?"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            <Field
              name="company_services.in_person_counselling_yn"
              label="In Person Counselling"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            {renderEmployeeMinutes()}
            {dependantsSelector && renderDependentMinutes()}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.couples_counselling_yn"
              label="Couples Counselling"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            {couplesCounselling && renderCouplesMinutes()}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_minutes.health_work_life_minutes"
              label="Career/Health/Life Minutes"
              component={AntInput}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="companies.additional_service_yn"
              label="Advisory Services"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            {additionalServiceSelector && (
              <FormRowContainer>
                <Field
                  name="company_additional_services.financial_yn"
                  label="Financial"
                  wrapperwidth="80px"
                  component={AntCheckbox}
                  onChange={handleFormChanged}
                />
                <Field
                  name="company_additional_services.legal_yn"
                  label="Legal"
                  wrapperwidth="80px"
                  component={AntCheckbox}
                  onChange={handleFormChanged}
                />
                <Field
                  name="company_additional_services.research_yn"
                  label="Life"
                  wrapperwidth="80px"
                  component={AntCheckbox}
                  onChange={handleFormChanged}
                />
                <Field
                  name="company_additional_services.health_yn"
                  label="Health"
                  wrapperwidth="80px"
                  component={AntCheckbox}
                  onChange={handleFormChanged}
                />
                <Field
                  name="company_additional_services.career_yn"
                  label="Career"
                  wrapperwidth="80px"
                  component={AntCheckbox}
                  onChange={handleFormChanged}
                />
              </FormRowContainer>
            )}
          </FormRowContainer>
          <FormRowContainer>
            <ProviderCoveragesField />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.wellness_hub_yn"
              label="Wellness Hub"
              wrapperwidth="100px"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            <Field
              name="company_services.crisis_support_yn"
              label="Crisis Support"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            <Field
              name="company_services.meditation_yn"
              label="Meditation"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <FormRowContainer>
            <Field
              name="include_eligibility_list_yn"
              label="Use eligibility list"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer style={{ marginBottom: '50px' }}>
          <h4>Special Notice</h4>
          <Field
            name="company_infos.requires_special_notice_for_employees"
            label="Add special notice for employees"
            component={AntCheckbox}
            onChange={() => {
              if (requiresSpecialNoticeForEmployees) {
                props.change(
                  'company_infos.special_notice_for_employees',
                  null,
                );
              }
            }}
          />
          <Field
            name="company_infos.special_notice_for_employees"
            component={TextEditor}
            style={{ marginBottom: '30px' }}
            readOnly={!requiresSpecialNoticeForEmployees === true}
            disable={!requiresSpecialNoticeForEmployees === true}
            placeholder="Specific information that care providers need to know about their employees. (e.g. to be made aware of employees' access to firearms)"
          />
          <Field
            name="company_infos.requires_special_notice_for_dependants"
            label="Add special notice for dependants"
            component={AntCheckbox}
            onChange={() => {
              if (requiresSpecialNoticeForDependants) {
                props.change(
                  'company_infos.special_notice_for_dependants',
                  null,
                );
              }
            }}
          />
          <Field
            name="company_infos.special_notice_for_dependants"
            component={TextEditor}
            readOnly={!requiresSpecialNoticeForDependants === true}
            style={{ marginBottom: '30px' }}
            placeholder="Specific information that care providers need to know about their employees' dependant. (e.g. to be made aware of employees' access to firearms)"
          />
        </SectionContainer>
        <ButtonContainer>
          <Button htmlType="submit" type="primary" loading={false}>
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Create People Company
          </Button>
          <Button
            type="danger"
            onClick={() => history.push('/email_reminders')}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
      <p>{successMessage}</p>
    </SubContainer>
  );
}

PeopleCompanyCreation.propTypes = {
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  automatedEmails: PropTypes.bool,
  additionalServiceSelector: PropTypes.bool,
  planSelector: PropTypes.string,
  dependantsSelector: PropTypes.bool,
  couplesCounselling: PropTypes.bool,
};

PeopleCompanyCreation.defaultValues = {
  planSelector: 'people_connect',
};

// validationError={validationError}
const mapStateToProps = (state) => {
  const formSelector = formValueSelector('AddPeopleCompanyForm');

  const sagaMapStateToProps = createStructuredSelector({
    companies: makeSelectCompanies(),
    companyCreationError: makeSelectCompanyCreationError(),
  })(state);

  return {
    initialValues: {
      company_minutes: {
        video_access_employee_minutes: 0,
        video_access_dependant_minutes: 0,
        video_bite_employee_minutes: 0,
        video_bite_dependant_minutes: 0,
        video_couples_access_employee_minutes: 0,
        video_couples_access_dependant_minutes: 0,
        video_couples_bite_employee_minutes: 0,
        video_couples_bite_dependant_minutes: 0,
      },
      contract_details: {
        launch_time: moment('2020-01-01 05:00'),
        dependants_yn: false,
      },
      company_additional_services: {
        financial_yn: true,
        legal_yn: true,
        career_yn: true,
        health_yn: true,
        research_yn: true,
      },
      company_services: {
        meditation_yn: false,
        in_person_counselling_yn: false,
        crisis_support_yn: false,
      },
      automate_emails_yn: true,
      tags: {
        ids: [],
      },
    },
    planSelector: formSelector(state, 'companies.plan_type'),
    uploadEmployeeFile: formSelector(state, 'include_eligibility_list_yn'),
    automatedEmails: formSelector(state, 'automate_emails_yn'),
    dependantsSelector: formSelector(state, 'contract_details.dependants_yn'),
    couplesCounselling: formSelector(
      state,
      'company_services.couples_counselling_yn',
    ),
    additionalServiceSelector: formSelector(
      state,
      'companies.additional_service_yn',
    ),
    requiresSpecialNoticeForEmployees: formSelector(
      state,
      'company_infos.requires_special_notice_for_employees',
    ),
    requiresSpecialNoticeForDependants: formSelector(
      state,
      'company_infos.requires_special_notice_for_dependants',
    ),
    ...sagaMapStateToProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createCompany: (values, callback) =>
      dispatch(createCompany(values, callback)),
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    resetCompanyError: () => dispatch(resetCompanyError()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddPeopleCompanyForm',
    enableReinitialize: true,
  })(PeopleCompanyCreation),
);
