/**
 *
 * EmployeeManagement
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Modal, Button, Divider } from 'antd';
import 'antd/dist/antd.css';
import Dropzone from 'react-dropzone';
import _ from 'lodash';
import Papa from 'papaparse';

import { makeSelectCurrentCompanyId } from 'containers/App/selectors';
import { fetchCustomFields, backfillPreview } from '../actions';
import makeSelectMyAccount, { makeSelectCustomFields } from '../selectors';
import HeaderValidation from './HeaderValidation';
import StandardFieldTable from './StandardFieldTable';
import CustomFieldTable from './CustomFieldTable';
import AddEmployee from './AddEmployee';
import EmployeeList from './EmployeeList';
import { DownloadTemplateButton } from './DownloadTemplateButton';

export function EmployeeManagement({
  renderDoubleInput,
  renderInput,
  renderMapping,
  uploadEmployeeCsv,
  fetchBackfillPreview,
  company,
  fetchCustomFieldProp,
  customFields,
  currentCompanyId,
}) {
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [modal, setModal] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [importRows, setImportRows] = useState(0);
  const [uploadType, setUploadType] = useState('consolidation');
  const [sendEmail, setSendEmail] = useState(undefined);
  const [backfillPreviewRes, setBackfillPreviewRes] = useState(null);

  const customFieldsData = _.get(customFields, 'data', []);

  useEffect(() => {
    fetchCustomFieldProp(currentCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId]);

  const handleOk = () => {
    setModal(false);
    handleUpload();
  };

  const handleCancel = () => {
    setModal(false);
    setFile(null);
    setHeaders(null);
    setSuccess(null);
    setValidationError(null);
    setSendEmail(true);
  };

  const handleDrop = (acceptedFile) => {
    setFileError(null);
    setFile(null);
    Papa.parse(acceptedFile[0], {
      header: true,
      preview: 1,
      step: (row) => {
        const csvHeaders = row.meta.fields;

        if (isValid(csvHeaders)) setFile(acceptedFile[0]);
        if (company && company.backfill_required) {
          previewBackfill(acceptedFile[0]);
        }
      },
    });

    setHeaders(null);
    setSuccess(null);
  };

  const isValid = (csvHeaders) => {
    const currentFileError = [];
    if (
      !csvHeaders.includes('first_name') ||
      !csvHeaders.includes('last_name')
    ) {
      currentFileError.push(
        'Please supply appropriate headers (first_name AND last_name)',
      );
    }
    if (!csvHeaders.includes('id')) {
      currentFileError.push('Please supply appropriate headers (id)');
    }
    setFileError(currentFileError);

    return !currentFileError.length;
  };

  const previewBackfill = (acceptedFile) => {
    const body = new FormData();
    body.append('id', company.id);
    body.append('file', acceptedFile);
    fetchBackfillPreview(body, (res) => {
      setBackfillPreviewRes(res);
    });
  };

  const handleUpload = () => {
    const body = new FormData();
    body.append('id', company.id);
    body.append('file', file);
    body.append('upload_type', uploadType);

    body.append(
      'source',
      uploadType === 'consolidation'
        ? 'employee_management'
        : 'company_creation',
    );
    body.append('send_email', sendEmail);

    uploadEmployeeCsv(body, () => {
      resetDefaults();
      if (uploadType === 'backfill') {
        setSuccess('Backfill complete');
      } else {
        setSuccess(
          "Your CSV is processing. You'll be emailed a preview of results to confirm.",
        );
      }
    });
  };

  const resetDefaults = () => {
    setFile(null);
    setModal(false);
    setHeaders(null);
    setSendEmail(true);
    setUploadType('consolidation');
  };

  const handleCheck = () => {
    Papa.parse(file, {
      headers: true,
      skipEmptyLines: 'greedy',
      complete: (results) => {
        setImportRows(results.data.length - 1);
        setHeaders(results.meta.fields);
        setModal(true);
      },
    });
  };

  const onUploadTypeChange = (type) => {
    setUploadType(type);
    setSendEmail(false);
  };

  return (
    <div>
      <h3 className="sub-header">Upload Eligibility File</h3>
      <span style={{ maxWidth: '500px', display: 'block' }}>
        Use this section to upload an eligibility file for additions, or for a
        consolidation.
      </span>
      <br />
      <span style={{ maxWidth: '500px', display: 'block' }}>
        Please ensure that the CSV file you upload is formatted correctly, with
        the first row displaying only the following allowed column names.
      </span>
      <br />
      <DownloadTemplateButton customFieldsData={customFieldsData} />
      <br />
      <br />
      <Dropzone
        accept=".csv"
        multiple={false}
        onDrop={(acceptedFile) => handleDrop(acceptedFile)}
      >
        {({ getRootProps, getInputProps }) => (
          <Button {...getRootProps()}>
            <input {...getInputProps()} />
            Select File
          </Button>
        )}
      </Dropzone>
      <div style={{ marginTop: '20px' }}>{_.get(file, 'name', '')}</div>
      {fileError && (
        <div style={{ marginTop: '20px', color: 'red' }}>
          <ul>
            {fileError.map((error) => {
              return <li key={error}>{error}</li>;
            })}
          </ul>
        </div>
      )}
      <div style={{ marginTop: '20px', color: 'green' }}>{success}</div>
      {file && (
        <Button type="primary" htmlType="submit" onClick={handleCheck}>
          Next
        </Button>
      )}
      <br />
      <br />
      <StandardFieldTable />
      <br />
      <CustomFieldTable customFieldsData={customFieldsData} />
      <br />
      <Divider />
      <AddEmployee
        renderDoubleInput={renderDoubleInput}
        renderInput={renderInput}
        company={company}
        customFieldsData={customFieldsData}
      />
      <Divider />
      <EmployeeList />
      <Modal
        title="Import Details"
        visible={modal}
        width={900}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <HeaderValidation
          renderMapping={renderMapping}
          rawHeaders={headers}
          rowCount={importRows}
          validationError={validationError}
          onUploadTypeChange={onUploadTypeChange}
          uploadType={uploadType}
          sendEmail={sendEmail}
          setSendEmail={setSendEmail}
          // TODO: Please figure out how to delay the rendering until fetch is complete
          backfillRequired={company && company.backfill_required}
          backfillPreviewRes={backfillPreviewRes}
        />
      </Modal>
    </div>
  );
}

EmployeeManagement.propTypes = {
  currentCompanyId: PropTypes.number,
  renderDoubleInput: PropTypes.func.isRequired,
  renderInput: PropTypes.func.isRequired,
  renderDropdown: PropTypes.func.isRequired,
  renderMapping: PropTypes.func.isRequired,
  uploadEmployeeCsv: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  fetchCustomFieldProp: PropTypes.func,
  fetchBackfillPreview: PropTypes.func,
  customFields: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  myAccount: makeSelectMyAccount(),
  currentCompanyId: makeSelectCurrentCompanyId(),
  customFields: makeSelectCustomFields(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomFieldProp: (companyId) => dispatch(fetchCustomFields(companyId)),
    fetchBackfillPreview: (file, cb) => dispatch(backfillPreview(file, cb)),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'EmployeeManagementForm',
    enableReinitialize: true,
  })(EmployeeManagement),
);
