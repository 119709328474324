/**
 *
 * LandingPage
 *
 */

import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Element, scroller } from 'react-scroll';
import { Helmet } from 'react-helmet-async';

import 'styles/containers/landing.scss';

import { NavbarOne } from '../../pagePartials/LandingPage/NavbarOne';
import { NavbarTwo } from '../../pagePartials/EapPage/Navbar2';
import { MobileNavOverlay } from '../../pagePartials/EapPage/MobileNavOverlay';
import { SectionOne } from '../../pagePartials/LandingPage/SectionOne';
import { SectionTwo } from '../../pagePartials/LandingPage/SectionTwo';
import { SectionThree } from '../../pagePartials/LandingPage/SectionThree';
import { SectionFour } from '../../pagePartials/LandingPage/SectionFour';
import { SectionFive } from '../../pagePartials/LandingPage/SectionFive';
import { SectionSix } from '../../pagePartials/LandingPage/SectionSix';
import { SectionSeven } from '../../pagePartials/LandingPage/SectionSeven';
import { SectionEight } from '../../pagePartials/LandingPage/SectionEight';
import { Footer } from '../../pagePartials/LandingPage/Footer';

export function LandingPage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const scrollToBottom = () => {
    scroller.scrollTo('scroll-to-element', {
      duration: 2500,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Inkblot for Business - Your Workplace Mental Health Partner
        </title>
        <meta
          name="description"
          content="Inkblot offers easy and effective mental health support for businesses. Help your team perform at their best with fast access to best-in-class counsellors."
        />
      </Helmet>
      <IntlProvider locale="en">
        <div className="landing">
          <NavbarOne />
          <NavbarTwo
            scrollToBottom={scrollToBottom}
            setShowOverlay={setShowOverlay}
          />
          <MobileNavOverlay
            scrollToBottom={scrollToBottom}
            height={showOverlay ? '100%' : '0%'}
            setShowOverlay={setShowOverlay}
          />
          <SectionOne scrollToBottom={scrollToBottom} />
          <SectionTwo />
          <SectionThree />
          <SectionFour windowWidth={windowWidth} />
          <SectionFive scrollToBottom={scrollToBottom} />
          <SectionSix windowWidth={windowWidth} />
          <div className="section-7-8">
            <SectionSeven windowWidth={windowWidth} />
            <Element name="scroll-to-element">
              <SectionEight />
            </Element>
          </div>
          <Footer />
        </div>
      </IntlProvider>
    </div>
  );
}

export default LandingPage;
