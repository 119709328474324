/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { Button, Icon, Modal, Tag, Tooltip } from 'antd';
import { UikWidgetTable } from '@uik/lib';
import { destroyEmailReminder } from './actions';
import { useHistory } from 'react-router-dom';

function EmailReminderTable(props) {
  const history = useHistory();
  const [showModal, setModal] = useState(false);
  const [deletingReminder, setDeletingReminder] = useState(false);
  const {
    reminders,
    dispatch,
    currentUser: { inkblot_admin_yn },
  } = props;

  let modify;
  let modal;
  let remove;

  if (inkblot_admin_yn) {
    modify = (reminder) => (
      <Button
        htmlType="submit"
        type="default"
        disabled={!inkblot_admin_yn}
        onClick={() =>
          history.push(`/email_reminders/${reminder.id}/modify_reminder`)
        }
      >
        <Icon type="edit" style={{ verticalAlign: 0 }} />
        Modify
      </Button>
    );
  } else {
    modify = () => <div />;
  }

  if (inkblot_admin_yn) {
    remove = (reminder) => (
      <Button
        style={{ marginLeft: '20px' }}
        htmlType="submit"
        type="danger"
        disabled={!inkblot_admin_yn}
        onClick={() => {
          setModal(true);
          setDeletingReminder(reminder);
        }}
      >
        <Icon type="minus-circle" style={{ verticalAlign: 0 }} />
        Remove
      </Button>
    );
  } else {
    remove = () => <div />;
  }
  if (inkblot_admin_yn) {
    modal = () => (
      <Modal
        title="Remove Reminder from Campaign"
        visible={showModal}
        closable={false}
        onCancel={() => {
          setModal(false);
        }}
        onOk={() => {
          dispatch(
            destroyEmailReminder(deletingReminder.id, () => {
              setModal(false);
            }),
          );
        }}
        cancelText="Return to Email Reminders"
        okText="Remove Reminder"
      >
        You are about to remove <b>{deletingReminder.name}</b> from the
        campaign.
      </Modal>
    );
  } else {
    modal = () => <div />;
  }

  const renderTag = (status) => {
    if (status === 'scheduled') {
      return <Tag color="#1890ff">Scheduled</Tag>;
    }
    if (status === 'cancelled') {
      return <Tag color="#FF0000">Cancelled</Tag>;
    }
    if (status === 'pending') {
      return <Tag color="#FFD700">Pending</Tag>;
    }
    if (status === 'completed') {
      return <Tag color="#87d068">Completed</Tag>;
    }
    return <Tag color="#ccc">Unknown</Tag>;
  };
  const renderReminder = (reminder) => (
    <tr key={`key-${reminder.id}`}>
      <td>
        {reminder.name}{' '}
        {reminder.custom_email &&
          reminder.custom_email.use_as_welcome_email_yn && (
            <Tooltip title="This email is currently being used as the welcome email for new employees">
              <Tag color="#87d068">
                <Icon type="mail" style={{ verticalAlign: 0 }} />
              </Tag>
            </Tooltip>
          )}
      </td>
      <td>
        {moment(reminder.scheduled_send_date).format('MMMM Do, YYYY HH:mmA')}
      </td>
      <td>{renderTag(reminder.status)}</td>
      <td>
        {modify(reminder)}
        {remove(reminder)}
        {modal()}
      </td>
    </tr>
  );

  return (
    <UikWidgetTable>
      <thead>
        <tr>
          <th>NAME</th>
          <th>DATE</th>
          <th>STATUS</th>
          <th>ACTIONS</th>
        </tr>
      </thead>
      <tbody>
        {reminders && reminders.length ? (
          reminders.map((reminder) => renderReminder(reminder))
        ) : (
          <tr>
            <td colSpan="4">No Reminders</td>
          </tr>
        )}
      </tbody>
    </UikWidgetTable>
  );
}

EmailReminderTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  reminders: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    destroyEmailReminder: (id, callback) =>
      dispatch(destroyEmailReminder(id, callback)),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(EmailReminderTable);
