import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MARKETING_URL } from 'utils/environment';
import FacebookLogo from './facebook-button.svg';
import InstagramLogo from './instagram-button.svg';
import LinkedInLogo from './linkedin-button.svg';
import TwitterLogo from './twitter-button.svg';
import emailLogo from './email-button.svg';
import chatLogo from './chat-button.svg';
import { whatWeTreat, aboutUs, legal, learning } from './footerData';

const Footer = () => {
  const isFr = localStorage.getItem('locale') === 'fr';
  return (
    <div className="footer-wrapper">
      <div className="footer-header">
        <div>
          <h1>
            <FormattedMessage
              id="footer.contactUs"
              defaultMessage="Contact Us"
            />
          </h1>
        </div>
        <div>
          <div className="support-container">
            <div className="contact-box" style={{ wordBreak: 'break-word' }}>
              <div>
                <img src={emailLogo} alt="email logo" />
                <a
                  role="button"
                  href={
                    isFr
                      ? 'mailto:ventes@therapieinkblot.com'
                      : 'mailto:business@inkblottherapy.com'
                  }
                >
                  <span>
                    <FormattedMessage
                      id="footer.email"
                      defaultMessage="business@inkblottherapy.com"
                    />
                  </span>
                </a>
              </div>
              <div className="live-chat-container">
                <img src={chatLogo} alt="chat logo" />
                <div className="live-chat">
                  <span>
                    <FormattedMessage
                      id="footer.liveChat"
                      defaultMessage="Live Chat"
                    />
                  </span>
                  <span>
                    <FormattedMessage
                      id="footer.chatHours"
                      defaultMessage="Every day from 8:00am to 11:00pm (ET)"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="footer-row">
          <div className="treatment-container">
            <h4>
              <FormattedMessage
                id="footer.whatWeTreat"
                defaultMessage="What We Treat"
              />
            </h4>
            <div className="what-we-treat">
              <ul>
                {whatWeTreat.slice(0, 7).map((treat) => (
                  <li key={treat.id}>
                    <FormattedMessage
                      id={`footer.${treat.id}`}
                      defaultMessage={treat.name}
                    />
                  </li>
                ))}
              </ul>
              <ul className="www-two">
                {whatWeTreat.slice(7).map((treat) => (
                  <li key={treat.id}>
                    <FormattedMessage
                      id={`footer.${treat.id}`}
                      defaultMessage={treat.name}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="about-services">
          <div>
            <h4>
              <FormattedMessage id="footer.aboutUs" defaultMessage="About Us" />
            </h4>
            <ul>
              {aboutUs.map((about) => (
                <li key={about.id}>
                  <a
                    href={
                      about.id !== 'helpCentre'
                        ? `${MARKETING_URL}${about.link}`
                        : `${about.link}`
                    }
                  >
                    <FormattedMessage
                      id={`footer.${about.id}`}
                      defaultMessage={about.name}
                    />
                  </a>
                </li>
              ))}
              <li>
                <a
                  role="button"
                  href={
                    isFr
                      ? 'mailto:media@therapieinkblot.com'
                      : 'mailto:media@inkblottherapy.com'
                  }
                >
                  <span>
                    <FormattedMessage
                      id="footer.pressInquiries"
                      defaultMessage="Press Inquiries"
                    />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-column">
          <div className="footer-row">
            <div>
              <h4 className="h4-last">
                <FormattedMessage id="footer.legal" defaultMessage="Legal" />
              </h4>
              <ul>
                {legal.map((legalItem) => (
                  <li key={legalItem.id}>
                    <a href={`${MARKETING_URL}${legalItem.link}`}>
                      <FormattedMessage
                        id={`footer.${legalItem.id}`}
                        defaultMessage={legalItem.name}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h4>
                <FormattedMessage
                  id="footer.learning"
                  defaultMessage="Learning"
                />
              </h4>
              <ul>
                {learning.map((learn) => (
                  <li key={learn.id}>
                    <a href={`${MARKETING_URL}${learn.link}`}>
                      <FormattedMessage
                        id={`footer.${learn.id}`}
                        defaultMessage={learn.name}
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-row">
            <div className="fc-social-link">
              <h2>
                <FormattedMessage
                  id="footer.letsGetSocial"
                  defaultMessage="Let's get social."
                />
              </h2>
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/inkblotpsychotherapy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookLogo} alt="Facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/inkblottherapy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={InstagramLogo} alt="Instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/inkblottherapy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={LinkedInLogo} alt="LinkedIn" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/inkblottherapy?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitterLogo} alt="Twitter" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="copyright">
              © {new Date().getFullYear()} INKBLOT TECHNOLOGIES INC.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
