/**
 *
 * CompanyCreation
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import {
  Field,
  reduxForm,
  formValueSelector,
  isDirty,
  getFormSyncErrors,
} from 'redux-form';
import { Button, Icon } from 'antd';
import moment from 'moment';
import AntCheckbox from 'components/AntCheckbox';
import AntInput from 'components/AntInput';
import AntDatePicker from 'components/AntDatePicker';
import AntTimePicker from 'components/AntTimePicker';
import AntSelect from 'components/AntSelect';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { checkTrailingSpace, required, validateUrl } from 'utils/validation';
import { LAST_COMPANY_KEY } from 'containers/App/constants';
import { fetchCurrentUser } from 'containers/App/actions';
import TextEditor from 'components/TextEditor';
import {
  makeSelectCompanies,
  makeSelectAccountManagers,
  makeSelectCpNavigators,
  makeSelectCompanyCreationError,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import ProviderCoveragesField from './ProviderCoveragesField';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import {
  fetchCompanies,
  fetchAccountManagers,
  createCompany,
  uploadEmployeeCsv,
  fetchClinicalNavigators,
  createLogo,
  resetCompanyError,
} from './actions';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';

const FormRowContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  > div {
    margin: 0 5% 0 0;
  }
`;

const SectionContainer = styled.div`
  h4 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;
const Message = styled.p`
  color: ${(props) => (props.isError ? 'red' : 'inherit')};
  margin-top: 5px;
`;

const contractTypes = [
  { value: 'full', label: 'Full' },
  { value: 'adhoc', label: 'Ad-Hoc' },
];

const tranquilityTypes = [
  { value: '0', label: 'Self-Directed' },
  { value: '1', label: 'Coach-Assisted' },
];

const gatingOptions = [
  { value: 'full', label: 'Full' },
  { value: 'partial', label: 'Partial' },
  { value: 'domain', label: 'Domain' },
  { value: 'trust', label: 'Trust-Based' },
];

const planTypes = [
  { value: 'eap', label: 'EAP' },
  { value: 'access', label: 'Access' },
  { value: 'access_plus', label: 'Access Plus' },
  { value: 'custom_pepm', label: 'Custom-PEPM' },
  { value: 'custom_other', label: 'Custom-Other' },
];
const LogoTypes = { 0: 'primary', 1: 'secondary', 2: 'other' };

export function CompanyCreation(props) {
  const { formSyncErrors, isDirty, companyCreationError, resetCompanyError } =
    props;
  useInjectReducer({ key: 'companyCreation', reducer });
  useInjectSaga({ key: 'companyCreation', saga });
  useEffect(() => {
    props.fetchCompanies();
    props.fetchAccountManagers();
    props.fetchClinicalNavigators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const [formMessage, setFormMessage] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [logos, setLogos] = useState([null, null, null]);

  function redirecWhenSuccess() {
    history.push('/my_account/company_information');
  }
  function handleFormChanged() {
    setFormChanged(true);
    setFormMessage(null);
    resetCompanyError();
  }

  const checkSyncError = () => Object.keys(formSyncErrors).length > 0;
  const checkSubmitError = () =>
    isDirty && !formChanged && (checkSyncError() || companyCreationError);

  useEffect(() => {
    if (isDirty && checkSyncError()) {
      setFormMessage('Missing required fields');
    } else if (companyCreationError) {
      setFormMessage(companyCreationError);
    } else if (!submitting) {
      setFormMessage(null);
    }
  }, [companyCreationError, isDirty, formSyncErrors, formChanged]);

  const renderIndividualMinutes = (planType, dependants) => {
    if (planType === 'access_plus' || planType === 'access') {
      return (
        <FormRowContainer>
          <Field
            name="company_minutes.video_access_employee_minutes"
            label="Employee Minutes (Ind.)"
            component={AntInput}
          />
          {dependants && (
            <Field
              name="company_minutes.video_access_dependant_minutes"
              label="Dependant Minutes (Ind.)"
              component={AntInput}
            />
          )}
          <Field
            name="company_minutes.unlimited_video_access_employee"
            label="Unlimited Employee Minutes? (Ind.)"
            component={AntCheckbox}
          />
          {dependants && (
            <Field
              name="company_minutes.unlimited_video_access_dependant"
              label="Unlimited Dependant Minutes? (Ind.)"
              component={AntCheckbox}
            />
          )}
        </FormRowContainer>
      );
    }
    if (planType === 'eap') {
      return (
        <FormRowContainer>
          <Field
            name="company_minutes.video_bite_employee_minutes"
            label="Employee Minutes (Ind.)"
            component={AntInput}
          />
          {dependants && (
            <Field
              name="company_minutes.video_bite_dependant_minutes"
              label="Dependant Minutes (Ind.)"
              component={AntInput}
            />
          )}
          <Field
            name="company_minutes.unlimited_video_bite_employee"
            label="Unlimited Employee Minutes? (Ind.)"
            component={AntCheckbox}
          />
          {dependants && (
            <Field
              name="company_minutes.unlimited_video_bite_dependant"
              label="Unlimited Dependant Minutes? (Ind.)"
              component={AntCheckbox}
            />
          )}
        </FormRowContainer>
      );
    }
    return (
      <React.Fragment>
        <FormRowContainer>
          <Field
            name="company_minutes.video_bite_employee_minutes"
            label="Employee Bite Minutes (Ind.)"
            component={AntInput}
          />
          {dependants && (
            <Field
              name="company_minutes.video_bite_dependant_minutes"
              label="Dependant Bite Minutes (Ind.)"
              component={AntInput}
            />
          )}
          <Field
            name="company_minutes.unlimited_video_bite_employee"
            label="Unlimited Bite Employee Minutes? (Ind.)"
            component={AntCheckbox}
          />
          {dependants && (
            <Field
              name="company_minutes.unlimited_video_bite_dependant"
              label="Unlimited Bite Dependant Minutes? (Ind.)"
              component={AntCheckbox}
            />
          )}
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="company_minutes.video_access_employee_minutes"
            label="Employee Access Minutes (Ind.)"
            component={AntInput}
          />
          {dependants && (
            <Field
              name="company_minutes.video_access_dependant_minutes"
              label="Dependant Access Minutes (Ind.)"
              component={AntInput}
            />
          )}
          <Field
            name="company_minutes.unlimited_video_access_employee"
            label="Unlimited Access Employee Minutes? (Ind.)"
            component={AntCheckbox}
          />
          {dependants && (
            <Field
              name="company_minutes.unlimited_video_access_dependant"
              label="Unlimited Access Dependant Minutes? (Ind.)"
              component={AntCheckbox}
            />
          )}
        </FormRowContainer>
      </React.Fragment>
    );
  };

  const renderCouplesMinutes = (planType, dependants) => {
    if (planType === 'access' || planType === 'access_plus') {
      return (
        <React.Fragment>
          <Field
            name="company_minutes.video_couples_access_employee_minutes"
            label="Employee Minutes (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
          {dependants && (
            <Field
              name="company_minutes.video_couples_access_dependant_minutes"
              label="Dependant Minutes (Couples)"
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          )}
        </React.Fragment>
      );
    }
    if (planType === 'eap') {
      return (
        <React.Fragment>
          <Field
            name="company_minutes.video_couples_bite_employee_minutes"
            label="Employee Minutes (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
          {dependants && (
            <Field
              name="company_minutes.video_couples_bite_dependant_minutes"
              label="Dependant Minutes (Couples)"
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <React.Fragment>
          <Field
            name="company_minutes.video_couples_bite_employee_minutes"
            label="Employee Minutes Bite (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
          {dependants && (
            <Field
              name="company_minutes.video_couples_bite_dependant_minutes"
              label="Dependant Minutes Bite (Couples)"
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          )}
        </React.Fragment>
        <React.Fragment>
          <Field
            name="company_minutes.video_couples_access_employee_minutes"
            label="Employee Minutes Access (Couples)"
            component={AntInput}
            validate={[required]}
            onChange={handleFormChanged}
          />
          {dependants && (
            <Field
              name="company_minutes.video_couples_access_dependant_minutes"
              label="Dependant Minutes Access (Couples)"
              component={AntInput}
              validate={[required]}
              onChange={handleFormChanged}
            />
          )}
        </React.Fragment>
      </React.Fragment>
    );
  };

  const handleDrop = (acceptedFile, index) => {
    setLogos((logo) => {
      logo[index] = acceptedFile[0];
      return [...logo];
    });
  };
  const onSubmit = (values) => {
    setFormMessage('Creating company...');
    setSubmitting(true);
    setFormChanged(false);
    const company = values;
    company.contract_details.launch_date = moment(
      `${values.contract_details.launch_date.format(
        'YYYY-MM-DD',
      )} ${values.contract_details.launch_time.format('HH:mm:ss')}`,
    );
    if (values.companies.customized_url === 'custom') {
      company.companies.customized_url = values.companies.custom_url;
    }

    if (!additionalServiceSelector) {
      delete company.company_additional_services.financial_yn;
      delete company.company_additional_services.legal_yn;
      delete company.company_additional_services.career_yn;
      delete company.company_additional_services.health_yn;
      delete company.company_additional_services.research_yn;
    }

    if (!tranquilitySelector) {
      delete company.company_services.tranquility_type;
      delete company.company_services.tranquility_url;
    }

    if (!mapleSelector) {
      delete company.company_services.maple_service;
      delete company.company_services.maple_telemedicine_url;
    }

    if (!hasClinicalNavigator) {
      delete company.company_services.clinical_navigator_id;
    }

    if (!couplesCounselling) {
      delete company.company_minutes.video_couples_access_employee_minutes;
      delete company.company_minutes.video_couples_access_dependant_minutes;
      delete company.company_minutes.video_couples_bite_employee_minutes;
      delete company.company_minutes.video_couples_bite_dependant_minutes;
    }

    if (!company.company_infos.requires_special_notice_for_employees) {
      delete company.company_infos.special_notice_for_employees;
    }

    if (!company.company_infos.requires_special_notice_for_dependants) {
      delete company.company_infos.special_notice_for_dependants;
    }
    props.createCompany(company, (resp) => {
      setFormMessage('Setting dropdown...');
      localStorage.setItem(LAST_COMPANY_KEY, resp.id);
      if (logos.filter((e) => !!e).length) {
        const formData = new FormData();
        logos.forEach((logo, index) => {
          if (logo) {
            formData.append('logos[][logo]', logo);
            formData.append('logos[][type_of]', LogoTypes[index]);
          }
        });

        props.createLogo(formData, resp.id, () => {
          props.fetchCurrentUser();
          redirecWhenSuccess();
        });
      } else {
        redirecWhenSuccess();
        setSubmitting(false);
      }
    });
  };
  const {
    companies,
    selectedReseller,
    accountManagers,
    customizedUrl,
    dependants,
    planSelector,
    additionalServiceSelector,
    tranquilitySelector,
    mapleSelector,
    cpNavigators,
    customizedUrlText,
    automatedEmails,
    couplesCounselling,
    registrationFlow,
    // eslint-disable-next-line react/prop-types
    requiresSpecialNoticeForEmployees,
    // eslint-disable-next-line react/prop-types
    requiresSpecialNoticeForDependants,
    hasClinicalNavigator,
  } = props;

  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">New Company</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <SectionContainer>
          <h4>Account Owners</h4>
          <FormRowContainer>
            <Field
              name="companies.account_management_owner_id"
              label="Account Manager"
              width="180px"
              component={AntSelect}
              options={_.map(accountManagers, (manager) => ({
                label: `${manager.first_name} ${manager.last_name}`,
                value: manager.id,
              }))}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="companies.secondary_account_management_owner_id"
              label="Secondary Account Manager"
              width="180px"
              component={AntSelect}
              options={_.map(accountManagers, (manager) => ({
                label: `${manager.first_name} ${manager.last_name}`,
                value: manager.id,
              }))}
            />
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <h4>Company Logo(s)</h4>
          <p>(optional)</p>
          <FormRowContainer
            style={{ gap: '24px' }}
            onClick={(e) => e.preventDefault()}
          >
            {[0, 1, 2].map((index) => (
              <div key={index}>
                <Dropzone
                  multiple={false}
                  accept=".jpeg, .png, .jpg"
                  onDrop={(acceptedImage) => handleDrop(acceptedImage, index)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <button {...getRootProps()}>
                      <input {...getInputProps()} />
                      {logos[index]
                        ? logos[index].name
                        : `${LogoTypes[index]} logo`}
                    </button>
                  )}
                </Dropzone>
                {logos[index] && (
                  <Button
                    style={{ border: 0, padding: 0, left: '5px' }}
                    onClick={() => {
                      delete logos[index];
                      setLogos([...logos]);
                    }}
                  >
                    ❌
                  </Button>
                )}
              </div>
            ))}
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <h4>Basics</h4>
          <FormRowContainer>
            <Field
              name="companies.name"
              label="Company Name"
              style={{ width: 250 }}
              component={AntInput}
              validate={[required, checkTrailingSpace]}
              onChange={handleFormChanged}
            />
            <Field
              name="company_infos.alias"
              label="Alias"
              style={{ width: 250 }}
              component={AntInput}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="companies.customized_url_yn"
              label="Portal Page Needed?"
              component={AntCheckbox}
              onChange={handleFormChanged}
            />
            {customizedUrl && (
              <Field
                name="companies.customized_url"
                label="Portal URL"
                width="180px"
                component={AntSelect}
                options={[
                  { label: 'Care', value: 'care' },
                  { label: 'Custom', value: 'custom' },
                  { label: 'GSC - EAP', value: 'GSCgroup' },
                  { label: 'GSC - ACCESS', value: 'gsc-access' },
                  { label: 'Benecaid - EAP', value: 'benecaid-eap' },
                  { label: 'Honeybee - EAP', value: 'honeybee-eap' },
                  { label: 'Maple - EAP', value: 'maple-eap' },
                  { label: 'ASEBP - EAP', value: 'asebp' },
                  { label: 'First Canadian', value: 'firstcanadian' },
                  { label: 'Maple UV', value: 'maple-eap-uv' },
                  { label: 'inConfidence Group', value: 'inconfidence' },
                  {
                    label: 'inConfidence (Personal)',
                    value: 'inconfidence_personal',
                  },
                ]}
                validate={[required]}
                onChange={handleFormChanged}
              />
            )}
            {customizedUrl && customizedUrlText === 'custom' && (
              <Field
                name="companies.custom_url"
                label="Customized URL"
                width="180px"
                component={AntInput}
                validate={[required, validateUrl]}
              />
            )}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_infos.registration_flow"
              label="New Registration Flow?"
              component={AntSelect}
              validate={[required]}
              onChange={handleFormChanged}
              style={{ width: '180px' }}
              options={[
                {
                  label: 'Registration (2023)',
                  value: 'redesign_2023',
                },
                {
                  label: 'Legacy Registration',
                  value: 'legacy',
                },
              ]}
            />
            <Field
              name="company_infos.company_code"
              label="Company Code"
              style={{ width: 250 }}
              component={AntInput}
              validate={
                registrationFlow === 'redesign_2023' ? [required] : null
              }
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_infos.insurance_claims_enabled"
              label="Enable Insurance Claims"
              component={AntCheckbox}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_reseller_yn"
              label="Partner/Reseller"
              component={AntCheckbox}
            />
            {selectedReseller && (
              <Field
                name="reseller_company"
                label="Reseller Company"
                width="180px"
                component={AntSelect}
                options={_.map(companies, (company) => ({
                  value: company.id,
                  label: company.name,
                }))}
                validate={[required]}
                onChange={handleFormChanged}
              />
            )}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="french_yn"
              label="Include French in Emails"
              component={AntCheckbox}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.newsletter_en_yn"
              label="Newsletter (EN)"
              component={AntCheckbox}
            />
            <Field
              name="company_services.newsletter_fr_yn"
              label="Newsletter (FR)"
              component={AntCheckbox}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="automate_emails_yn"
              label="Launch with standard email cadence"
              component={AntCheckbox}
            />
            {automatedEmails && (
              <Field
                name="contract_details.launch_time"
                label="Welcome email send time"
                component={AntTimePicker}
                validate={[required]}
                onChange={handleFormChanged}
              />
            )}
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <h4>Benefit Information</h4>
          <FormRowContainer>
            <Field
              name="companies.contract_type"
              label="Contract Type"
              width="180px"
              component={AntSelect}
              options={contractTypes}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="companies.plan_type"
              label="Plan Type"
              width="180px"
              component={AntSelect}
              options={planTypes}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="companies.additional_service_yn"
              label="Additional/Advisory Services"
              component={AntCheckbox}
            />
            <Field
              name="company_services.wellness_hub_yn"
              label="Wellness Hub"
              wrapperwidth="80px"
              component={AntCheckbox}
            />
          </FormRowContainer>
          {additionalServiceSelector && (
            <FormRowContainer>
              <Field
                name="company_additional_services.financial_yn"
                label="Financial"
                wrapperwidth="80px"
                component={AntCheckbox}
              />
              <Field
                name="company_additional_services.legal_yn"
                label="Legal"
                wrapperwidth="80px"
                component={AntCheckbox}
              />
              <Field
                name="company_additional_services.research_yn"
                label="Life"
                wrapperwidth="80px"
                component={AntCheckbox}
              />
              <Field
                name="company_additional_services.health_yn"
                label="Health"
                wrapperwidth="80px"
                component={AntCheckbox}
              />
              <Field
                name="company_additional_services.career_yn"
                label="Career"
                wrapperwidth="80px"
                component={AntCheckbox}
              />
            </FormRowContainer>
          )}
          <FormRowContainer>
            <Field
              name="contract_details.contract_start"
              label="Contract Start Date"
              component={AntDatePicker}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="contract_details.launch_date"
              label="Launch Date"
              component={AntDatePicker}
              validate={[required]}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_infos.portal_gating"
              label="Portal Gating"
              width="180px"
              component={AntSelect}
              options={gatingOptions}
              validate={[required]}
              onChange={handleFormChanged}
            />
            <Field
              name="contract_details.dependants_yn"
              label="Dependants?"
              component={AntCheckbox}
            />
            <Field
              name="company_services.in_person_counselling_yn"
              label="In Person Counselling"
              component={AntCheckbox}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.crisis_support_yn"
              label="Crisis Support"
              component={AntCheckbox}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.collaborative_care_yn"
              label="Collaborative Care"
              component={AntCheckbox}
            />
          </FormRowContainer>
          {renderIndividualMinutes(planSelector, dependants)}
          <FormRowContainer>
            <Field
              name="company_services.couples_counselling_yn"
              label="Couples Counselling"
              component={AntCheckbox}
            />
            {couplesCounselling &&
              renderCouplesMinutes(planSelector, dependants)}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_minutes.health_work_life_minutes"
              label="Career/Health/Life Minutes"
              component={AntInput}
              onChange={handleFormChanged}
            />
          </FormRowContainer>
          <FormRowContainer>
            <ProviderCoveragesField />
          </FormRowContainer>
          <h4>Additional Services</h4>
          <FormRowContainer>
            <Field
              name="company_services.tranquility_service_yn"
              label="Tranquility Access"
              width="180px"
              component={AntCheckbox}
            />
            {tranquilitySelector && (
              <>
                <Field
                  name="company_services.tranquility_type"
                  label="Tranquility Type"
                  width="180px"
                  component={AntSelect}
                  options={tranquilityTypes}
                  validate={[required]}
                  onChange={handleFormChanged}
                />
                <Field
                  name="company_services.tranquility_url"
                  label="Tranquility URL"
                  width="180px"
                  component={AntInput}
                  validate={[required]}
                  onChange={handleFormChanged}
                />
              </>
            )}
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="company_services.maple_service"
              label="Maple Access"
              width="180px"
              component={AntCheckbox}
            />
            {mapleSelector && (
              <Field
                name="company_services.maple_telemedicine_url"
                label="Maple URL"
                width="180px"
                component={AntInput}
                validate={[required]}
                onChange={handleFormChanged}
              />
            )}
          </FormRowContainer>
          <h4>Clinical Navigator Service</h4>
          <FormRowContainer>
            <Field
              name="clinical_navigator_yn"
              label="Clinical Navigator"
              width="250px"
              component={AntCheckbox}
            />
            {hasClinicalNavigator && (
              <>
                <Field
                  name="company_services.clinical_navigator_id"
                  label="Select Clinical Navigators"
                  width="250px"
                  component={AntSelect}
                  options={cpNavigators || []}
                  validate={[required]}
                  onChange={handleFormChanged}
                />
              </>
            )}
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer>
          <h4>Details</h4>
          <FormRowContainer>
            <Field
              name="company_infos.employee_id_yn"
              label="Use employee ID"
              component={AntCheckbox}
            />
            <Field
              name="company_infos.accepted_domains"
              label="Accepted email domains (comma separated)"
              component={AntInput}
            />
          </FormRowContainer>
          <FormRowContainer
            style={{ alignItems: 'baseline', margin: '3rem auto' }}
          >
            <Field
              name="company_infos.id_custom_name"
              label="id custom name (en)"
              component={AntInput}
            />
            <Field
              name="company_infos.gating_instructions"
              component={TextEditor}
              style={{ marginBottom: '30px' }}
              placeholder="instruction to find the employee ID (e.g. Enter the number at the bottom of your ARTA Membership Card.)."
              label="gating instructions (en)"
              type="gating_instructions"
            />
          </FormRowContainer>
          <FormRowContainer
            style={{ alignItems: 'baseline', margin: '3rem auto' }}
          >
            <Field
              name="company_infos.id_custom_name_fr"
              label="id custom name (fr)"
              component={AntInput}
            />
            <Field
              name="company_infos.gating_instructions_fr"
              component={TextEditor}
              style={{ marginBottom: '30px' }}
              placeholder="instruction to find the employee ID (e.g. Enter the number at the bottom of your ARTA Membership Card.)."
              label="gating instructions (fr)"
              type="gating_instructions"
            />
          </FormRowContainer>
        </SectionContainer>
        <SectionContainer style={{ marginBottom: '50px' }}>
          <h4>Special Notice</h4>
          <Field
            name="company_infos.requires_special_notice_for_employees"
            label="Add special notice for employees"
            component={AntCheckbox}
            onChange={() => {
              if (requiresSpecialNoticeForEmployees) {
                props.change(
                  'company_infos.special_notice_for_employees',
                  null,
                );
              }
            }}
          />
          <FormRowContainer>
            <Field
              name="company_infos.special_notice_for_employees"
              component={TextEditor}
              style={{ marginBottom: '30px' }}
              readOnly={!requiresSpecialNoticeForEmployees === true}
              disable={!requiresSpecialNoticeForEmployees === true}
              placeholder="Specific information that care providers need to know about their employees."
            />
          </FormRowContainer>
          <Field
            name="company_infos.requires_special_notice_for_dependants"
            label="Add special notice for dependants"
            component={AntCheckbox}
            onChange={() => {
              if (requiresSpecialNoticeForDependants) {
                props.change(
                  'company_infos.special_notice_for_dependants',
                  null,
                );
              }
            }}
          />
          <FormRowContainer>
            <Field
              name="company_infos.special_notice_for_dependants"
              component={TextEditor}
              readOnly={!requiresSpecialNoticeForDependants === true}
              style={{ marginBottom: '30px' }}
              placeholder="Specific information that care providers need to know about their employees' dependant."
            />
          </FormRowContainer>
        </SectionContainer>
        <ButtonContainer>
          <Button htmlType="submit" type="primary" loading={false}>
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Create Company
          </Button>
          <Button
            type="danger"
            onClick={() => {
              history.push('/email_reminders');
            }}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
      <Message isError={checkSubmitError() || checkSyncError()}>
        {formMessage}
      </Message>
    </SubContainer>
  );
}

CompanyCreation.propTypes = {
  accountManagers: PropTypes.array,
  additionalServiceSelector: PropTypes.bool,
  automatedEmails: PropTypes.bool,
  change: PropTypes.func,
  companies: PropTypes.array,
  couplesCounselling: PropTypes.bool,
  createCompany: PropTypes.func.isRequired,
  customizedUrl: PropTypes.bool,
  customizedUrlText: PropTypes.string,
  registrationFlow: PropTypes.string,
  dependants: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  fetchAccountManagers: PropTypes.func.isRequired,
  fetchCompanies: PropTypes.func.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  resetCompanyError: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  planSelector: PropTypes.string,
  selectedReseller: PropTypes.bool,
  tranquilitySelector: PropTypes.bool,
  mapleSelector: PropTypes.bool,
  cpNavigators: PropTypes.array,
  fetchClinicalNavigators: PropTypes.func.isRequired,
  createLogo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const formSelector = formValueSelector('AddCompanyForm');

  const sagaMapStateToProps = createStructuredSelector({
    companies: makeSelectCompanies(),
    companyCreationError: makeSelectCompanyCreationError(),
    accountManagers: makeSelectAccountManagers(),
    cpNavigators: makeSelectCpNavigators(),
  })(state);
  return {
    initialValues: {
      companies: {
        customized_url_yn: true,
        additional_service_yn: false,
      },
      tags: {
        ids: [],
      },
      company_minutes: {
        video_access_employee_minutes: 0,
        video_access_dependant_minutes: 0,
        video_bite_employee_minutes: 0,
        video_bite_dependant_minutes: 0,
        video_couples_access_employee_minutes: 0,
        video_couples_access_dependant_minutes: 0,
        video_couples_bite_employee_minutes: 0,
        video_couples_bite_dependant_minutes: 0,
      },
      company_additional_services: {
        financial_yn: true,
        legal_yn: true,
        career_yn: true,
        health_yn: true,
        research_yn: true,
      },
      company_services: {
        couples_counselling_yn: false,
        in_person_counselling_yn: false,
        wellness_hub_yn: true,
        newsletter_en_yn: false,
        newsletter_fr_yn: false,
      },
      contract_details: {
        dependants_yn: true,
        launch_time: moment('2020-01-01 05:00'),
      },
      automate_emails_yn: true,
    },
    selectedReseller: formSelector(state, 'company_reseller_yn'),
    customizedUrl: formSelector(state, 'companies.customized_url_yn'),
    hasClinicalNavigator: formSelector(state, 'clinical_navigator_yn'),
    customizedUrlText: formSelector(state, 'companies.customized_url'),
    registrationFlow: formSelector(state, 'company_infos.registration_flow'),
    dependants: formSelector(state, 'contract_details.dependants_yn'),
    couplesCounselling: formSelector(
      state,
      'company_services.couples_counselling_yn',
    ),
    planSelector: formSelector(state, 'companies.plan_type'),
    additionalServiceSelector: formSelector(
      state,
      'companies.additional_service_yn',
    ),
    tranquilitySelector: formSelector(
      state,
      'company_services.tranquility_service_yn',
    ),
    mapleSelector: formSelector(state, 'company_services.maple_service'),
    automatedEmails: formSelector(state, 'automate_emails_yn'),
    requiresSpecialNoticeForEmployees: formSelector(
      state,
      'company_infos.requires_special_notice_for_employees',
    ),
    requiresSpecialNoticeForDependants: formSelector(
      state,
      'company_infos.requires_special_notice_for_dependants',
    ),
    formSyncErrors: getFormSyncErrors('AddCompanyForm')(state),
    isDirty: isDirty('AddCompanyForm')(state),
    ...sagaMapStateToProps,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchCompanies: () => dispatch(fetchCompanies()),
    fetchClinicalNavigators: () => dispatch(fetchClinicalNavigators()),
    fetchAccountManagers: () => dispatch(fetchAccountManagers()),
    resetCompanyError: () => dispatch(resetCompanyError()),
    createCompany: (values, callback) =>
      dispatch(createCompany(values, callback)),
    uploadEmployeeCsv: (values, callback) =>
      dispatch(uploadEmployeeCsv(values, callback)),
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
    createLogo: (values, companyId, callback) =>
      dispatch(createLogo(values, companyId, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddCompanyForm',
  })(CompanyCreation),
);
