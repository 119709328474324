/*
 *
 * EmailReminders reducer
 *
 */
import produce from 'immer';
import _ from 'lodash';
import {
  FETCH_EMAIL_REMINDER_TEMPLATES,
  FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS,
  FETCH_EMAIL_REMINDER_TEMPLATES_ERROR,
  FETCH_EMAIL_REMINDERS,
  FETCH_EMAIL_REMINDERS_SUCCESS,
  FETCH_EMAIL_REMINDERS_ERROR,
  CREATE_EMAIL_REMINDER,
  CREATE_EMAIL_REMINDER_SUCCESS,
  CREATE_EMAIL_REMINDER_ERROR,
  AUTOMATE_REMINDERS,
  AUTOMATE_REMINDERS_SUCCESS,
  AUTOMATE_REMINDERS_ERROR,
  FETCH_EMAIL_REMINDER,
  FETCH_EMAIL_REMINDER_SUCCESS,
  FETCH_EMAIL_REMINDER_ERROR,
  UPDATE_EMAIL_REMINDER,
  UPDATE_EMAIL_REMINDER_SUCCESS,
  UPDATE_EMAIL_REMINDER_ERROR,
  DESTROY_EMAIL_REMINDER_SUCCESS,
  DESTROY_EMAIL_REMINDER,
  DESTROY_EMAIL_REMINDER_ERROR,
  FETCH_TRANQUILITY_INFO_SUCCESS,
  FETCH_TRANQUILITY_INFO_ERROR,
  FETCH_TRANQUILITY_INFO,
  CLEAR_TRANQUILITY_INFO,
} from './constants';

export const initialState = {
  emailReminderTemplates: {
    loading: false,
    error: false,
    data: false,
  },
  emailReminders: {
    loading: false,
    error: false,
    data: [],
  },
  emailReminder: {
    loading: false,
    error: false,
    data: false,
    tranquilityInfo: {},
  },
};

/* eslint-disable default-case, no-param-reassign */
const teamManagementReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_EMAIL_REMINDER_TEMPLATES:
        draft.emailReminderTemplates.loading = true;
        draft.emailReminderTemplates.error = false;
        draft.emailReminderTemplates.data = false;
        break;

      case FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS:
        draft.emailReminderTemplates.data = action.templates;

        draft.emailReminderTemplates.loading = false;
        break;

      case FETCH_EMAIL_REMINDER_TEMPLATES_ERROR:
        draft.emailReminderTemplates.error = action.error;
        draft.emailReminderTemplates.loading = false;
        break;
      case FETCH_EMAIL_REMINDERS:
      case AUTOMATE_REMINDERS:
        draft.emailReminders.data = [];
        draft.emailReminders.loading = true;
        draft.emailReminders.error = false;
        break;
      case DESTROY_EMAIL_REMINDER:
      case CREATE_EMAIL_REMINDER:
      case FETCH_EMAIL_REMINDER:
      case UPDATE_EMAIL_REMINDER:
        draft.emailReminder.loading = true;
        draft.emailReminder.error = false;
        draft.emailReminder.data = false;
        break;

      case FETCH_EMAIL_REMINDERS_SUCCESS:
      case AUTOMATE_REMINDERS_SUCCESS:
        draft.emailReminders.data = action.reminders;
        draft.emailReminders.loading = false;
        draft.emailReminders.error = false;
        break;
      case FETCH_EMAIL_REMINDERS_ERROR:
      case AUTOMATE_REMINDERS_ERROR:
        draft.emailReminders.data = [];
        draft.emailReminders.loading = false;
        draft.emailReminders.error = action.error;
        break;
      case FETCH_EMAIL_REMINDER_SUCCESS:
        draft.emailReminder.data = action.reminder;
        draft.emailReminder.loading = false;
        break;
      case UPDATE_EMAIL_REMINDER_SUCCESS:
        draft.emailReminders.data.forEach((element, index) => {
          if (element.id === action.reminder.id) {
            draft.emailReminders.data[index] = action.reminder;
          }
        });
        draft.emailReminders.data = _.orderBy(
          draft.emailReminders.data,
          ['scheduled_send_date'],
          ['asc'],
        );
        draft.emailReminder.data = action.reminder;
        draft.emailReminder.loading = false;
        break;
      case CREATE_EMAIL_REMINDER_SUCCESS:
        draft.emailReminders.data.push(action.reminder);
        draft.emailReminders.data = _.orderBy(
          draft.emailReminders.data,
          ['scheduled_send_date'],
          ['asc'],
        );
        draft.emailReminders.loading = false;
        break;
      case DESTROY_EMAIL_REMINDER_SUCCESS:
        draft.emailReminders.data = draft.emailReminders.data.filter((x) => {
          return x.id !== action.reminder.id;
        });
        draft.emailReminder.data = action.reminder;
        draft.emailReminder.loading = false;
        break;

      case CREATE_EMAIL_REMINDER_ERROR:
      case DESTROY_EMAIL_REMINDER_ERROR:
      case FETCH_EMAIL_REMINDER_ERROR:
      case UPDATE_EMAIL_REMINDER_ERROR:
        draft.emailReminder.error = action.error;
        draft.emailReminder.loading = false;
        break;
      case FETCH_TRANQUILITY_INFO_SUCCESS:
        draft.emailReminder.tranquilityInfo.loading = false;
        draft.emailReminder.tranquilityInfo.renderURLInput = true;
        break;
      case FETCH_TRANQUILITY_INFO_ERROR:
        draft.emailReminder.tranquilityInfo.loading = false;
        draft.emailReminder.tranquilityInfo.renderURLInput = false;
        draft.emailReminder.tranquilityInfo.error = action.error.data.error;
        break;
      case FETCH_TRANQUILITY_INFO:
        draft.emailReminder.tranquilityInfo.loading = true;
        break;
      case CLEAR_TRANQUILITY_INFO:
        draft.emailReminder.tranquilityInfo = {};
        break;
    }
  });

export default teamManagementReducer;
