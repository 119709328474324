/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Icon, Modal } from 'antd';
import { UikWidgetTable, UikTag } from '@uik/lib';
import { destroyTeamMember } from './actions';
import { useHistory } from 'react-router-dom';

function TeamMemberTable(props) {
  const [showModal, setModal] = useState(false);
  const [userToRemove, setUserToRemove] = useState({});
  const {
    members,
    dispatch,
    currentUser: { owner_yn, senior_admin_yn },
  } = props;

  const history = useHistory();
  let modify;
  let modal;
  let remove;

  if (owner_yn || senior_admin_yn) {
    modify = (member) => (
      <Button
        htmlType="submit"
        type="default"
        disabled={!owner_yn && senior_admin_yn && member.senior_admin_yn}
        onClick={() =>
          history.push(`/team_management/${member.id}/modify_admin`)
        }
      >
        <Icon type="edit" style={{ verticalAlign: 0 }} />
        Modify
      </Button>
    );
  } else {
    modify = () => <div />;
  }

  if (owner_yn || senior_admin_yn) {
    remove = (member) => (
      <Button
        style={{ marginLeft: '20px' }}
        htmlType="submit"
        type="danger"
        disabled={!owner_yn && senior_admin_yn && member.senior_admin_yn}
        onClick={() => {
          setUserToRemove(member);
          setModal(true);
        }}
      >
        <Icon type="minus-circle" style={{ verticalAlign: 0 }} />
        Remove
      </Button>
    );
  } else {
    remove = () => <div />;
  }
  if (owner_yn || senior_admin_yn) {
    modal = () => (
      <Modal
        title="Remove User from Admin"
        visible={showModal}
        closable={false}
        onCancel={() => {
          setModal(false);
          setUserToRemove({});
        }}
        onOk={() => {
          dispatch(destroyTeamMember(userToRemove.id, userToRemove.company.id));
          setModal(false);
          setUserToRemove({});
        }}
        cancelText="Return to Team Management"
        okText="Remove User"
      >
        You are about to revoke{' '}
        <b>
          {userToRemove.first_name} {userToRemove.last_name}&apos;s
        </b>{' '}
        admin privileges.
        <br />
        <br /> If you wish to re-add this user as an admin, please contact{' '}
        <a href="mailto:support@inkblottherapy.com">
          support@inkblottherapy.com
        </a>
      </Modal>
    );
  } else {
    modal = () => <div />;
  }

  const renderMember = (member) => {
    let tag;
    let color;

    if (member.owner_yn) {
      tag = 'OWNER';
      color = 'orange';
    } else if (member.admin_yn) {
      tag = 'ADMIN';
      color = 'violet';
    } else if (member.senior_admin_yn) {
      tag = 'SENIOR ADMIN';
      color = 'blue';
    }

    return (
      <tr key={`key-${member.id}`}>
        <td>
          {member.first_name} {member.last_name}
        </td>
        <td>{member.email}</td>
        <td>
          <UikTag color={color} fill>
            {tag}
          </UikTag>
        </td>
        <td>
          {modify(member)}
          {remove(member)}
          {modal()}
        </td>
      </tr>
    );
  };

  return (
    <UikWidgetTable>
      <thead>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>USER TYPE</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {members && members.length ? (
          members.map((member) => renderMember(member))
        ) : (
          <tr>
            <td colSpan="4">No members</td>
          </tr>
        )}
      </tbody>
    </UikWidgetTable>
  );
}

TeamMemberTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(TeamMemberTable);
