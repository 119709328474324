import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Select, Modal, Button, Icon } from 'antd';

import {
  UikTopBarSection,
  UikTopBar,
  UikAvatar,
  UikDropdown,
  UikDropdownItem,
} from '@uik/lib';

import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { removeToken } from 'utils/auth';
import { clearCurrentUser, setCurrentCompanyId } from 'containers/App/actions';
import SetInitialPassword from 'containers/SetInitialPassword';
import { LAST_COMPANY_KEY } from 'containers/App/constants';

import Logo from '../../images/logo.png';
import { useHistory } from 'react-router-dom';
const { Option } = Select;

function TopBar(props) {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { currentUser, currentCompanyId } = props;
  const history = useHistory();

  useEffect(() => {
    setShowPasswordModal(currentUser.password_change_yn);
  }, [currentUser]);

  const signOut = () => {
    removeToken();
    localStorage.removeItem(LAST_COMPANY_KEY);
    props.clearCurrentUser();
    history.push('/signin');
  };

  const getRole = (user) => {
    if (user.super_admin_yn) return 'Inkblot Admin';
    if (user.inkblot_admin_yn) return 'Marchel Admin';
    if (user.owner_yn) return 'Owner';
    if (user.senior_admin_yn) return 'Senior Admin';
    if (user.admin_yn) return 'Admin';
    if (user.people_yn) return 'People Admin';

    return 'Unknown';
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleChangeCompany = (companyId) => {
    props.setCurrentCompanyId(companyId);
    localStorage.setItem(LAST_COMPANY_KEY, companyId);
  };

  const renderCompanySelector = () => {
    if (
      currentUser.managed_companies &&
      currentUser.managed_companies.length > 1 &&
      currentCompanyId
    ) {
      return (
        <div style={{ width: 200, marginRight: 20 }}>
          <Select
            showSearch
            value={currentCompanyId}
            placeholder="Search to Select"
            onChange={handleChangeCompany}
            style={{ width: '100%' }}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentUser.managed_companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </div>
      );
    }

    return <noscript />;
  };

  const renderCreateCompany = (user) => {
    if (user.inkblot_admin_yn) {
      return (
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => history.push('/create_company')}
        >
          <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
          Create Company
        </Button>
      );
    }
    return <noscript />;
  };

  const renderCreatePeopleCompany = (user) => {
    if (user.inkblot_admin_yn) {
      return (
        <Button
          type="primary"
          style={{ marginRight: 15 }}
          onClick={() => history.push('/create_people_company')}
        >
          <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
          Create People Corp Company
        </Button>
      );
    }
    return <noscript />;
  };

  const renderUserInfo = (user) => {
    return (
      <div>
        <UikDropdown
          DisplayComponent={({ onClick }) => (
            <UikAvatar
              highlighted
              avatarPlaceholder={{
                content: `${user.first_name
                  .charAt(0)
                  .toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`,
                color: 'blue',
              }}
              name={`${user.first_name} ${user.last_name}`}
              textBottom={getRole(user)}
              onClick={onClick}
            />
          )}
          position="bottomLeft"
        >
          <UikDropdownItem onClick={signOut}>Sign Out</UikDropdownItem>
        </UikDropdown>
      </div>
    );
  };

  return (
    <div>
      <UikTopBar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UikTopBarSection>
          <img
            style={{ width: '174px', height: '29px' }}
            src={Logo}
            alt="logo"
          />
        </UikTopBarSection>
        <UikTopBarSection>
          {renderCreatePeopleCompany(currentUser)}
          {renderCreateCompany(currentUser)}
          {renderCompanySelector(currentUser)}
          {currentUser && currentUser.first_name && renderUserInfo(currentUser)}
        </UikTopBarSection>
      </UikTopBar>
      <Modal
        title="New Password"
        visible={showPasswordModal}
        closable={false}
        footer={null}
      >
        <SetInitialPassword closeModal={closePasswordModal} />
      </Modal>
    </div>
  );
}

TopBar.propTypes = {
  dispatch: PropTypes.func.isRequired,
  clearCurrentUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
  setCurrentCompanyId: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    clearCurrentUser: () => dispatch(clearCurrentUser()),
    setCurrentCompanyId: (id) => dispatch(setCurrentCompanyId(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TopBar);
