import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';
import queryString from 'query-string';

import { API_URL } from 'utils/environment';
import { get, post, put, destroy } from 'utils/request';

import {
  FETCH_EMAIL_REMINDER_TEMPLATES,
  CREATE_EMAIL_REMINDER,
  AUTOMATE_REMINDERS,
  FETCH_EMAIL_REMINDER,
  UPDATE_EMAIL_REMINDER,
  DESTROY_EMAIL_REMINDER,
  FETCH_EMAIL_REMINDERS,
  FETCH_TRANQUILITY_INFO,
} from './constants';
import {
  fetchEmailReminderTemplatesSuccess,
  fetchEmailReminderTemplatesError,
  fetchEmailRemindersSuccess,
  fetchEmailRemindersError,
  automateEmailRemindersSuccess,
  automateEmailRemindersError,
  createEmailReminderSuccess,
  createEmailReminderError,
  fetchEmailReminderSuccess,
  fetchEmailReminderError,
  updateEmailReminderSuccess,
  updateEmailReminderError,
  destroyEmailReminderSuccess,
  destroyEmailReminderError,
  fetchTranquilityInfoSuccess,
  fetchTranquilityInfoError,
} from './actions';

function* fetchEmailReminderTemplates() {
  const requestUrl = `${API_URL}/reminders/templates`;

  try {
    const templates = yield call(get, requestUrl);
    yield reduxPut(fetchEmailReminderTemplatesSuccess(templates));
  } catch (error) {
    yield reduxPut(fetchEmailReminderTemplatesError(error));
  }
}

function* createEmailReminder({ values, callback }) {
  const requestUrl = `${API_URL}/reminders`;

  try {
    const reminder = yield call(post, requestUrl, values);
    yield reduxPut(createEmailReminderSuccess(reminder));
    if (callback) callback(reminder);
  } catch (error) {
    yield reduxPut(createEmailReminderError(error));
    if (callback) callback(null, error);
  }
}

function* fetchEmailReminders({ companyId }) {
  const requestUrl = `${API_URL}/reminders`;
  const params = companyId && queryString.stringify({ company_id: companyId });

  try {
    const reminders = yield call(get, `${requestUrl}?${params}`);
    yield reduxPut(fetchEmailRemindersSuccess(reminders));
  } catch (error) {
    yield reduxPut(fetchEmailRemindersError(error));
  }
}

function* automateEmailReminders({ callback, companyId }) {
  const requestUrl = `${API_URL}/reminders/automate`;

  try {
    const reminders = yield call(post, requestUrl, { company_id: companyId });
    yield reduxPut(automateEmailRemindersSuccess(reminders));
    if (callback) callback(reminders);
  } catch (error) {
    yield reduxPut(automateEmailRemindersError(error));
    if (callback) callback(null, error);
  }
}

function* fetchEmailReminder({ id, callback }) {
  const requestUrl = `${API_URL}/reminders/${id}`;

  try {
    const reminder = yield call(get, `${requestUrl}`);
    yield reduxPut(fetchEmailReminderSuccess(reminder));
    if (callback) callback(reminder);
  } catch (error) {
    yield reduxPut(fetchEmailReminderError(error));
    if (callback) callback(null, error);
  }
}

function* updateEmailReminder({ id, values, callback, companyId }) {
  const requestUrl = `${API_URL}/reminders/${id}`;

  try {
    const reminder = yield call(
      put,
      requestUrl,
      companyId ? { ...values, company_id: companyId } : values,
    );
    yield reduxPut(updateEmailReminderSuccess(reminder));
    if (callback) callback(reminder);
  } catch (error) {
    yield reduxPut(updateEmailReminderError(error));
    if (callback) callback(null, error);
  }
}

function* destroyEmailReminder({ id, callback }) {
  const requestUrl = `${API_URL}/reminders/${id}`;

  try {
    const reminder = yield call(destroy, requestUrl);
    yield reduxPut(destroyEmailReminderSuccess(reminder));
    if (callback) callback(reminder);
  } catch (error) {
    yield reduxPut(destroyEmailReminderError(error));
    if (callback) callback(error);
  }
}

function* fetchTranquilityInfo({ companyId }) {
  const requestUrl = `${API_URL}/reminders/tranquility_infos`;
  const params = companyId && queryString.stringify({ company_id: companyId });

  try {
    const tranquilityInfo = yield call(get, `${requestUrl}?${params}`);
    yield reduxPut(fetchTranquilityInfoSuccess(tranquilityInfo));
  } catch (error) {
    yield reduxPut(fetchTranquilityInfoError(error));
  }
}

export default function* teamManagementSaga() {
  yield takeLatest(FETCH_EMAIL_REMINDER_TEMPLATES, fetchEmailReminderTemplates);
  yield takeLatest(FETCH_EMAIL_REMINDERS, fetchEmailReminders);
  yield takeLatest(CREATE_EMAIL_REMINDER, createEmailReminder);
  yield takeLatest(FETCH_EMAIL_REMINDER, fetchEmailReminder);
  yield takeLatest(UPDATE_EMAIL_REMINDER, updateEmailReminder);
  yield takeLatest(DESTROY_EMAIL_REMINDER, destroyEmailReminder);
  yield takeLatest(AUTOMATE_REMINDERS, automateEmailReminders);
  yield takeLatest(FETCH_TRANQUILITY_INFO, fetchTranquilityInfo);
}
