/**
 *
 * EapPage
 *
 */

import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { scroller, Element } from 'react-scroll';
import { Helmet } from 'react-helmet-async';

import 'styles/containers/landing.scss';
import 'styles/containers/eap.scss';

import { NavbarOne } from '../../pagePartials/EapPage/Navbar1';
import { NavbarTwo } from '../../pagePartials/EapPage/Navbar2';
import { MobileNavOverlay } from '../../pagePartials/EapPage/MobileNavOverlay';
import { SectionOne } from '../../pagePartials/EapPage/Section1';
import { SectionTwo } from '../../pagePartials/EapPage/Section2';
import { SectionThree } from '../../pagePartials/EapPage/Section3';
import { SectionFour } from '../../pagePartials/EapPage/Section4';
import { SectionFive } from '../../pagePartials/EapPage/Section5';
import { SectionSix } from '../../pagePartials/EapPage/Section6';
import { SectionSeven } from '../../pagePartials/EapPage/Section7';
import { Section8 } from '../../pagePartials/EapPage/Section8';
import { SectionNine } from '../../pagePartials/EapPage/Section9';
import { SectionTen } from '../../pagePartials/EapPage/Section10';
import Footer from '../../components/EapFooter';

export function EapPage() {
  const [showOverlay, setShowOverlay] = useState(false);
  const scrollToBottom = () => {
    scroller.scrollTo('scroll-to-element', {
      duration: 2500,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div>
      <Helmet>
        <title>
          Inkblot EAP - Canada’s First Digital Employee Assistance Program
        </title>
        <meta
          name="description"
          content="Canada’s first digital Employee Assistance Program (EAP). Easy-to-use. Superior short- and long-term comprehensive  care. Best-in-class mental health support."
        />
      </Helmet>
      <IntlProvider locale="en">
        <div className="eap landing">
          <NavbarOne />
          <NavbarTwo
            scrollToBottom={scrollToBottom}
            setShowOverlay={setShowOverlay}
          />
          <MobileNavOverlay
            scrollToBottom={scrollToBottom}
            height={showOverlay ? '100%' : '0%'}
            setShowOverlay={setShowOverlay}
          />
          <SectionOne scrollToBottom={scrollToBottom} />
          <SectionTwo />
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <SectionSix />
          <SectionSeven />
          <Section8 />
          <SectionNine />
          <Element name="scroll-to-element">
            <SectionTen />
          </Element>
          <Footer />
        </div>
      </IntlProvider>
    </div>
  );
}

export default EapPage;
