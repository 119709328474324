/**
 *
 * AntSelect
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

const TextWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: 'Roboto';
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 0.75rem;
    color: #9ea0a5;
    line-height: 1.125rem;
    text-transform: uppercase;
  }
  .error-warning {
    display: block;
    color: red;
    font-size: 9px;
    margin-top: 8px;
  }
`;
function AntSelect({
  input,
  width,
  options,
  label,
  placeholder,
  normalize,
  validate,
  meta: { touched, error, warning },
}) {
  const { Option } = Select;
  return (
    <TextWrapper>
      <p className="tag">{label}</p>
      <Select
        {...input}
        value={[input.value]}
        placeholder={placeholder}
        style={{ width: width || '400px' }}
        normalize={normalize}
        validate={validate}
      >
        {_.map(options, (o) => (
          <Option key={o.value} value={o.value}>
            {o.label}
          </Option>
        ))}
      </Select>
      {touched &&
        ((error && <span className="error-warning">{error}</span>) ||
          (warning && <span className="error-warning">{warning}</span>))}
    </TextWrapper>
  );
}

AntSelect.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  meta: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.func,
};

export default AntSelect;
