import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import axios from 'axios';
import { headers } from 'utils/auth';
import AntMultiSelect from 'components/AntMultiSelect';
import { API_URL } from '../../utils/environment';

function ProviderCoveragesField() {
  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = () => {
    axios.get(`${API_URL}/tags`, headers()).then((res) => {
      setTags(res.data);
    });
  };

  const [tags, setTags] = useState([]);

  return (
    <Field
      name="tags.ids"
      label="Provider Coverages"
      component={AntMultiSelect}
      options={tags}
      placeholder="Select coverages"
    />
  );
}

ProviderCoveragesField.propTypes = {};

export default ProviderCoveragesField;
