/*
 * Thank You Messages
 *
 * This contains all the text for the Thank You component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'marchel.containers.thankyou';

export default defineMessages({
  thankYou: {
    id: `${scope}.thankYou`,
    defaultMessage: 'Thank you for your submission!',
  },
  beInTouch: {
    id: `${scope}.beInTouch`,
    defaultMessage:
      '<b>An Inkblot Team Member will be in touch.</b> In the meantime, we’d love to connect with you via our social platforms.',
    tagName: 'p',
  },
  joinOther: {
    id: `${scope}.joinOther`,
    defaultMessage:
      'Join other leading organizations already partnering with Inkblot:',
    tagName: 'span',
  },
});
