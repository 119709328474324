import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { DatePicker } from 'antd';
import UikCheckbox from 'components/RfCheckbox';
import _ from 'lodash';
import {
  UikDivider,
  UikContainerVertical,
  UikFormInputGroup,
  UikButton,
} from '@uik/lib';
import { SERVER_URL } from '../../utils/environment';
import { createReport as newReport } from './actions';
// import NavigationPanel from 'components/NavigationPanel';
// import redux

const DateWrapper = styled.div`
  display; flex;
  span.versus{
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #9ea0a5;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

class GenerateReportsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sucessful: false,
      comparative: false,
    };
  }

  handleCheckbox = (e) => {
    const { name } = e.target;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  downloadURI = (uri, name) => {
    const link = document.createElement('a');
    link.download = name;
    link.target = '_blank';
    link.href = uri;
    link.click();
  };

  onSubmit = (v) => {
    this.props.createCompany(v, (response) => {
      const link = `${SERVER_URL}${response.file}`;
      this.downloadURI(link, 'hello.xls');
    });
  };

  toggleComparative = () => {
    this.setState((prevState) => ({
      comparative: !prevState.comparative,
    }));
  };

  renderDatePicker = ({ input }) => {
    return (
      <DatePicker.RangePicker
        size="small"
        {...input}
        onFocus={(e) => e.preventDefault()}
        onBlur={(e) => e.preventDefault()}
        onChange={input.onChange}
      />
    );
  };

  render() {
    return (
      <UikContainerVertical>
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <UikFormInputGroup style={{ position: 'relative' }}>
            <h3 className="sub-header">Date Range</h3>
            <Field
              onClick={this.toggleComparative}
              component={UikCheckbox}
              color="green"
              label="Comparative Report"
            />
            <DateWrapper>
              <Field
                name="first_date"
                component={this.renderDatePicker}
                label="Start Date"
              />
              {this.state.comparative && <span className="versus">vs.</span>}
              {this.state.comparative && (
                <Field
                  name="second_date"
                  component={this.renderDatePicker}
                  label="Start Date"
                />
              )}
            </DateWrapper>
            <UikDivider />
            <h3 className="sub-header">Usage</h3>
            <Field
              name="total_sessions"
              component={UikCheckbox}
              color="green"
              label="Total number of sessions"
            />
            <Field
              name="total_hours"
              component={UikCheckbox}
              color="green"
              label="Total session hours"
            />
            <Field
              name="breakdown_of_user_type"
              component={UikCheckbox}
              color="green"
              label="Breakdown of user type"
              description="employee/member, spouse, dependent, retiree, people leader"
            />
            <Field
              name="breakdown_of_location"
              component={UikCheckbox}
              color="green"
              label="Breakdown of location"
            />

            <Field
              name="breakdown_of_gender"
              component={UikCheckbox}
              color="green"
              label="Breakdown of Gender Utilization"
              description="male, female, other specified, not specified"
            />
            <Field
              name="breakdown_of_age_decade"
              component={UikCheckbox}
              color="green"
              label="Breakdown of Age by decade"
              description="under 20, 20-29, 30-39, 40-49, 50-59, 60+"
            />
            <Field
              name="breakdown_of_age_generation"
              component={UikCheckbox}
              color="green"
              label="Breakdown of Age by generation"
              description="Gen Z- born 1995-2015, Millennials- born 1980-1994, Gen X- born 1965-1979, Baby Boomer- born 1944-1964, Silent- born 1945 and before"
            />
            <Field
              name="breakdown_of_issues"
              component={UikCheckbox}
              color="green"
              label="Type of issue treated"
            />
            <Field
              name="report_breakdown_of_business_unit"
              component={UikCheckbox}
              disabled
              color="green"
              label="Breakdown of business unit"
            />
            <Field
              name="report_breakdown_of_occupation"
              component={UikCheckbox}
              disabled
              color="green"
              label="Breakdown of occupation"
            />
            <Field
              name="breakdown_of_work_status"
              component={UikCheckbox}
              disabled
              color="green"
              label="Breakdown of work status"
              description="part-time, full-time, contractor, etc."
            />
            <Field
              name="report_breakdown_of_secondary_issues_present"
              component={UikCheckbox}
              disabled
              color="green"
              label="Secondary issues present"
            />
            {this.props.rangeError && (
              <span
                style={{ color: '#E6492D', fontWeight: 500, marginBottom: 0 }}
              >
                Please select a date range
              </span>
            )}
            <div>
              <UikButton type="submit" success xs style={{ marginTop: '40px' }}>
                Generate Report
              </UikButton>
            </div>
          </UikFormInputGroup>
        </form>
      </UikContainerVertical>
    );
  }
}

GenerateReportsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  rangeError: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    rangeError:
      state.reports && state.reports.report && state.reports.report.error,
    initialValues: {
      company_id: _.get(state, 'global.currentUser.company.id'),
      total_sessions: true,
      total_hours: true,
      breakdown_of_user_type: true,
      breakdown_of_location: true,
      breakdown_of_gender: true,
      breakdown_of_age_decade: true,
      breakdown_of_age_generation: true,
      breakdown_of_issues: true,
    },
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createCompany: (values, callback) => dispatch(newReport(values, callback)),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'GenerateReportsForm',
    enableReinitialize: true,
  })(GenerateReportsForm),
);
