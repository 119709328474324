/*
 *
 * SignIn actions
 *
 */

import { SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_ERROR } from './constants';

export function signIn(values, callback) {
  return {
    values,
    callback,
    type: SIGN_IN,
  };
}

export function signInSuccess(user) {
  return {
    user,
    type: SIGN_IN_SUCCESS,
  };
}

export function signInError(error) {
  return {
    error,
    type: SIGN_IN_ERROR,
  };
}
