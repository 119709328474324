import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the AdminMember state domain
 */

const selectAdminMemberDomain = (state) => state.adminMembers || initialState;

/**
 * Other specific selectors
 */
const makeSelectAdminMembers = () =>
  createSelector(selectAdminMemberDomain, (substate) => substate.adminMembers);

const makeSelectAdminMember = () =>
  createSelector(selectAdminMemberDomain, (substate) => substate.adminMember);

/**
 * Default selector used by AdminMember
 */

// const makeSelectAdminMember = () =>
//   createSelector(selectAdminMemberDomain, (substate) => substate);

export default makeSelectAdminMember;
export {
  selectAdminMemberDomain,
  makeSelectAdminMembers,
  makeSelectAdminMember,
};
