import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon, message, Alert } from 'antd';

import AntInput from 'components/AntInput';
import { required, passwordsMatch, passwordLength } from 'utils/validation';
import { API_URL } from 'utils/environment';
import { headers } from 'utils/auth';

export function SetInitialPassword(props) {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const onSubmit = (values) => {
    setErrorMessage(null);
    setSubmitting(true);

    axios
      .put(
        `${API_URL}/users/me`,
        {
          ...values,
          password_change_yn: false,
        },
        headers(),
      )
      .then(() => {
        setSubmitting(false);
        props.closeModal();
        message.info('Password changed successfully');
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please try again.');
      });
  };

  return (
    <div>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <Field
          name="password"
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          label="Password"
          placeholder="·····"
          component={AntInput}
          style={{ width: '100%' }}
          validate={[required, passwordLength]}
          type="password"
        />
        <div style={{ height: '30px' }} />
        <Field
          prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
          name="password_confirmation"
          label="Confirm Password"
          placeholder="·····"
          component={AntInput}
          style={{ width: '100%' }}
          validate={[required, passwordsMatch, passwordLength]}
          type="password"
        />
        {errorMessage && (
          <Alert
            message={errorMessage}
            type="error"
            showIcon
            style={{ marginTop: '40px' }}
          />
        )}
        <Button
          style={{ marginTop: '40px' }}
          htmlType="submit"
          type="primary"
          block
          loading={submitting}
        >
          Confirm New Password
        </Button>
      </form>
    </div>
  );
}

SetInitialPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'SetInitialPasswordForm',
  })(SetInitialPassword),
);
