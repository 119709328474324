import React from 'react';
import PropTypes from 'prop-types';
import { UikSelect } from '@uik/lib';
import '@uik/lib/styles.scss';
import styled from 'styled-components';

const DropdownStyles = styled.div`
  .uik-select__wrapper {
    max-width: ${(props) => props.width};
    width: 50vw;
    .uik-select__arrowWrapper {
      top: 50%;
    }
  }
`;

function DropdownField({ input, label, options, placeholder, width }) {
  return (
    <DropdownStyles width={width}>
      <UikSelect
        {...input}
        label={label}
        options={options}
        placeholder={placeholder}
        value={[input.value]}
      />
    </DropdownStyles>
  );
}

DropdownField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  width: PropTypes.string,
};

export default DropdownField;
