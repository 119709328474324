import React from 'react';
import { Table, Typography, Collapse } from 'antd';
import DOMPurify from 'dompurify';

export function StandardFieldTable() {
  const { Text } = Typography;
  const columns = [
    {
      title: 'Column Name',
      dataIndex: 'name',
      key: 'name',
      width: '150px',
      render: (name) => <Text code>{name}</Text>,
    },
    {
      title: 'Type',
      dataIndex: 'format',
      key: 'type',
      width: '110px',
      render: (type) => <Text code>{type}</Text>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) => {
        return (
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(record.message),
              }}
            />{' '}
            <Text>{description}</Text>
          </div>
        );
      },
    },
  ];

  const dataSource = [
    {
      key: 'id',
      name: 'id',
      description:
        'A unique, unchangeable identifier for your employees. May be any format, including an email address.',
      format: 'string',
      message: '<b>Required.</b>',
    },
    {
      key: 'first_name',
      name: 'first_name',
      description: "The employee's first name.",
      format: 'string',
      message: '<b>Required.</b>',
    },
    {
      key: 'last_name',
      name: 'last_name',
      description: "The employee's last name.",
      format: 'string',
      message: '<b>Required.</b>',
    },
    {
      key: 'email',
      name: 'email',
      description: "The employee's email address.",
      format: 'string',
    },
    {
      key: 'dob',
      name: 'dob',
      description:
        'The employee’s date of birth. Accepts format of DD/MM/YYYY and D/M/YYYY.',
      format: 'datetime',
    },
    {
      key: 'gender',
      name: 'gender',
      description: 'The employee’s gender.',
      format: 'string',
    },
    {
      key: 'start_date',
      name: 'start_date',
      description:
        'The date at which service starts. Accepts format of DD/MM/YYYY and D/M/YYYY.',
      format: 'datetime',
    },
    {
      key: 'end_date',
      name: 'end_date',
      description:
        'The date at which service ends. The value of this column must be later than that of start_date. Accepts format of DD/MM/YYYY and D/M/YYYY.',
      format: 'datetime',
    },
  ];

  const { Panel } = Collapse;

  return (
    <>
      <Collapse>
        <Panel header="Standard Fields">
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        </Panel>
      </Collapse>
    </>
  );
}

export default StandardFieldTable;
