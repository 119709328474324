import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import leagueBackgroundDesktopHD from '../../images/LandingPage/people/league-background-desktop-hd.png';
import pelmorexBackgroundDesktopHD from '../../images/LandingPage/people/pelmorex-background-desktop-hd.png';
import atbBackgroundDesktopHD from '../../images/LandingPage/people/atb-background-desktop-hd.png';
import leagueBackgroundDesktop from '../../images/LandingPage/people/league-background-desktop.png';
import pelmorexBackgroundDesktop from '../../images/LandingPage/people/pelmorex-background-desktop.png';
import atbBackgroundDesktop from '../../images/LandingPage/people/atb-background-desktop.png';
import leagueBackgroundTablet from '../../images/LandingPage/people/league-background-tablet.png';
import pelmorexBackgroundTablet from '../../images/LandingPage/people/pelmorex-background-tablet.png';
import atbBackgroundTablet from '../../images/LandingPage/people/atb-background-tablet.png';
import messages from './messages';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} next-arrow`}
      style={{
        ...style,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} prev-arrow`}
      style={{
        ...style,
      }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    />
  );
}

export function SectionSeven({ windowWidth }) {
  const settings = {
    dots: false,
    swipe: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="testimonial-carousel">
      <Slider {...settings}>
        <div className="testimonial-container">
          <img
            src={
              windowWidth > 1155
                ? leagueBackgroundDesktopHD
                : leagueBackgroundDesktop
            }
            alt="League background"
            className="league-background-desktop"
          />
          <img
            src={leagueBackgroundTablet}
            alt="League background"
            className="league-background-mobile"
          />
          <div>
            <FormattedHTMLMessage {...messages.atLeague} />
            <h4>
              <FormattedMessage {...messages.kimTabac} />
            </h4>
          </div>
        </div>
        <div className="testimonial-container">
          <img
            src={
              windowWidth > 1155 ? atbBackgroundDesktopHD : atbBackgroundDesktop
            }
            alt="League background"
            className="league-background-desktop"
          />
          <img
            src={atbBackgroundTablet}
            alt="League background"
            className="league-background-mobile"
          />
          <div>
            <FormattedMessage {...messages.makeEasy} />
            <h4>
              <FormattedMessage {...messages.kerileeSnatchen} />
            </h4>
          </div>
        </div>
        <div className="testimonial-container">
          <img
            src={
              windowWidth > 1155
                ? pelmorexBackgroundDesktopHD
                : pelmorexBackgroundDesktop
            }
            alt="League background"
            className="league-background-desktop"
          />
          <img
            src={pelmorexBackgroundTablet}
            alt="League background"
            className="league-background-mobile"
          />
          <div>
            <FormattedMessage {...messages.rewriting} />
            <h4>
              <FormattedMessage {...messages.annaPetosa} />
            </h4>
          </div>
        </div>
      </Slider>
    </div>
  );
}

SectionSeven.propTypes = {
  windowWidth: PropTypes.number.isRequired,
};

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

NextArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

PrevArrow.defaultProps = {
  className: '',
  style: {},
  onClick: null,
};
