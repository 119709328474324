import { LAST_COMPANY_KEY } from 'containers/App/constants';
import { ROOT_URL } from './environment';

export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function removeToken() {
  // clean local storage
  localStorage.removeItem('token');
}

export function headers() {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
}

export function isUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    return true;
  }

  return false;
}

export function signOut() {
  // clean local storage
  localStorage.removeItem('token');
  localStorage.removeItem('last_action_at');
  localStorage.removeItem(LAST_COMPANY_KEY);

  // redirect to marketing signin page
  window.location = `${ROOT_URL}/signin`;
}

export function unauthSignOut() {
  const exceptionalRoutes = [
    '/redirect',
    '/confirmation',
    '/signin',
    '/forgot_password',
    '/reset_password',
  ];
  const currentPath = window.location.pathname;
  const isExceptional = exceptionalRoutes.some((route) =>
    currentPath.includes(route),
  );

  if (
    !isExceptional &&
    currentPath !== '/' &&
    currentPath !== '/eap' &&
    currentPath !== '/thankyou'
  ) {
    signOut();
  }
}
