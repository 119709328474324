import * as Sentry from '@sentry/browser';
import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get } from 'utils/request';

import { FETCH_CURRENT_USER, LAST_COMPANY_KEY } from './constants';
import {
  fetchCurrentUserSuccess,
  fetchCurrentUserError,
  setCurrentCompanyId,
} from './actions';

function* fetchCurrentUser({ callback }) {
  const requestUrl = `${API_URL}/users/me`;

  try {
    const user = yield call(get, requestUrl);
    const lastCompany = Number.parseInt(
      localStorage.getItem(LAST_COMPANY_KEY),
      10,
    );

    yield reduxPut(fetchCurrentUserSuccess(user));
    yield reduxPut(setCurrentCompanyId(lastCompany || user.company.id));
    if (callback) callback(user);

    Sentry.configureScope((scope) => {
      scope.setUser({ id: user.id, email: user.email });
    });
  } catch (error) {
    yield reduxPut(fetchCurrentUserError(error));
  }
}

export default function* AppSaga() {
  yield takeLatest(FETCH_CURRENT_USER, fetchCurrentUser);
}
