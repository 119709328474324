/*
 *
 * TeamManagement actions
 *
 */

import {
  FETCH_ADMIN_MEMBERS,
  FETCH_ADMIN_MEMBERS_SUCCESS,
  FETCH_ADMIN_MEMBERS_ERROR,
  CREATE_ADMIN_MEMBER,
  CREATE_ADMIN_MEMBER_SUCCESS,
  CREATE_ADMIN_MEMBER_ERROR,
  FETCH_ADMIN_MEMBER,
  FETCH_ADMIN_MEMBER_SUCCESS,
  FETCH_ADMIN_MEMBER_ERROR,
  UPDATE_ADMIN_MEMBER,
  UPDATE_ADMIN_MEMBER_SUCCESS,
  UPDATE_ADMIN_MEMBER_ERROR,
  DESTROY_ADMIN_MEMBER,
  DESTROY_ADMIN_MEMBER_SUCCESS,
  DESTROY_ADMIN_MEMBER_ERROR,
} from './constants';

export function fetchAdminMembers(callback) {
  return {
    callback,
    type: FETCH_ADMIN_MEMBERS,
  };
}

export function fetchAdminMembersSuccess(members) {
  return {
    members,
    type: FETCH_ADMIN_MEMBERS_SUCCESS,
  };
}

export function fetchAdminMembersError(error) {
  return {
    error,
    type: FETCH_ADMIN_MEMBERS_ERROR,
  };
}

export function createAdminMember(values, callback) {
  return {
    values,
    callback,
    type: CREATE_ADMIN_MEMBER,
  };
}

export function createAdminMemberSuccess(member) {
  return {
    member,
    type: CREATE_ADMIN_MEMBER_SUCCESS,
  };
}

export function createAdminMemberError(error) {
  return {
    error,
    type: CREATE_ADMIN_MEMBER_ERROR,
  };
}

export function fetchAdminMember(id, callback) {
  return {
    id,
    callback,
    type: FETCH_ADMIN_MEMBER,
  };
}

export function fetchAdminMemberSuccess(member) {
  return {
    member,
    type: FETCH_ADMIN_MEMBER_SUCCESS,
  };
}

export function fetchAdminMemberError(error) {
  return {
    error,
    type: FETCH_ADMIN_MEMBER_ERROR,
  };
}

export function updateAdminMember(id, values, callback) {
  return {
    id,
    values,
    callback,
    type: UPDATE_ADMIN_MEMBER,
  };
}

export function updateAdminMemberSuccess(member) {
  return {
    member,
    type: UPDATE_ADMIN_MEMBER_SUCCESS,
  };
}

export function updateAdminMemberError(error) {
  return {
    error,
    type: UPDATE_ADMIN_MEMBER_ERROR,
  };
}

export function destroyAdminMember(id) {
  return {
    id,
    type: DESTROY_ADMIN_MEMBER,
  };
}

export function destroyAdminMemberSuccess(member) {
  return {
    member,
    type: DESTROY_ADMIN_MEMBER_SUCCESS,
  };
}

export function destroyAdminMemberError(error) {
  return {
    error,
    type: DESTROY_ADMIN_MEMBER_ERROR,
  };
}
