import React from 'react';
import Section10Form from './Section10Form';

export function SectionTen() {
  return (
    <div className="section-ten">
      <Section10Form />
    </div>
  );
}
