import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the myAccount state domain
 */

const selectMyAccountDomain = (state) => state.myAccount || initialState;

/**
 * Other specific selectors
 */
const makeSelectCompany = () =>
  createSelector(selectMyAccountDomain, (substate) => substate.company);

const makeSelectEmployee = () =>
  createSelector(selectMyAccountDomain, (substate) => substate.employee);

const makeSelectEmployeeList = () =>
  createSelector(selectMyAccountDomain, (substate) => substate.employeeList);

const makeSelectCustomFields = () =>
  createSelector(selectMyAccountDomain, (substate) => substate.customFields);

/**
 * Default selector used by MyAccount
 */

const makeSelectMyAccount = () =>
  createSelector(selectMyAccountDomain, (substate) => substate);

export default makeSelectMyAccount;
export {
  selectMyAccountDomain,
  makeSelectCompany,
  makeSelectEmployee,
  makeSelectEmployeeList,
  makeSelectCustomFields,
};
