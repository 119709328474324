import React from 'react';
// import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import customMatching from '../../images/EapPage/susancustomcounsellormatchcard.png';
import sessionScreen from '../../images/EapPage/sessionscreenlandscape.png';
import darkGreenCheck from '../../images/EapPage/dark-green-check.svg';
import percent from '../../images/EapPage/49-percent.png';
import messages from './messages';
import customMatchingFr from '../../images/EapPage/susancustomcounsellormatchcardfr.png';
import sessionScreenFr from '../../images/EapPage/sessionscreenlandscapefr.png';

export function SectionFive() {
  return (
    <div className="section-five">
      <div className="part-1">
        <img
          src={
            localStorage.getItem('locale') !== 'fr'
              ? customMatching
              : customMatchingFr
          }
          alt="Custom matching"
          width="624px"
          height="445px"
        />
        <div className="text">
          <FormattedHTMLMessage {...messages.employeeDriven} />
          <FormattedHTMLMessage {...messages.uniqueMatching} />
          <div className="self-booked">
            <img src={darkGreenCheck} alt="Dark green check" />
            <FormattedMessage {...messages.selfBooked} />
          </div>
        </div>
      </div>
      <div className="part-2">
        <div className="text">
          <FormattedHTMLMessage {...messages.lastingRelationships} />
          <FormattedHTMLMessage {...messages.rightMatch} />
          <div className="the-proof">
            <img src={percent} alt="49 percent" />
            <div>
              <FormattedHTMLMessage {...messages.theProof} />
              <FormattedHTMLMessage {...messages.threeSessions} />
            </div>
          </div>
        </div>
        <img
          src={
            localStorage.getItem('selectedLanguage') !== 'fr'
              ? sessionScreen
              : sessionScreenFr
          }
          alt="Session screen"
        />
      </div>
    </div>
  );
}

SectionFive.propTypes = {};

SectionFive.defaultProps = {};
