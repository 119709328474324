/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/**
 *
 * InvoicesBilling
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { Button } from 'antd';

import { UikDivider } from '@uik/lib';

import _ from 'lodash';

const paymentOptions = [
  { label: 'Credit Card', value: 'credit_card' },
  { label: 'Direct Deposit', value: 'direct_deposit' },
  { label: 'Cheque', value: 'cheque' },
];

export function InvoicesBilling({
  savePaymentType,
  company,
  renderRadio,
  paymentType,

  handleSubmit,
}) {
  const [token] = useState(null);

  const handlePayment = () => {
    const payment_type = paymentType;
    savePaymentType(
      { id: company.data.id, token, payment_type },
      (payment, error) => {
        if (!error) {
          // to refresh the fetchCompany for new payment details
          window.location.reload();
        }
      },
    );
  };

  return (
    <div>
      <h3 className="sub-header">Payment Options</h3>
      <form onSubmit={handleSubmit(handlePayment)}>
        {renderRadio({
          name: 'paymentType',
          options: paymentOptions,
        })}
        {paymentType === 'credit_card' && (
          <>
            {_.get(company.data, 'company_billing.payment_type') ===
              'credit_card' && <p>We already have a credit card on file.</p>}
            <p>Coming Soon!</p>
          </>
        )}
        {paymentType === 'direct_deposit' && (
          <div>
            <p>
              Please set up direct deposit into the Inkblot account. Bank
              information:
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Name:</span> Inkblot
              Technologies Inc
              <br />
              <span style={{ fontWeight: 'bold' }}>Address:</span> 1583
              Frontenac Pl. Burlington, ON. L7P1M7
              <br />
              <span style={{ fontWeight: 'bold' }}>Bank:</span> BMO
              <br />
              <span style={{ fontWeight: 'bold' }}>Branch:</span> 239 Lakeshore
              Rd E, Oakville, ON. L6J 1H7
              <br />
              <span style={{ fontWeight: 'bold' }}>Transit number:</span> 23822
              <br />
              <span style={{ fontWeight: 'bold' }}>
                Institution number:
              </span>{' '}
              001
              <br />
              <span style={{ fontWeight: 'bold' }}>Account number:</span>{' '}
              1997196
            </p>
          </div>
        )}
        {paymentType === 'cheque' && (
          <div>
            <p>
              Please send a cheque made to &quot;Inkblot Technologies Inc&quot;
              to the following address:
            </p>
            <p>
              Inkblot Technologies Inc
              <br />
              1300 Cornwall Rd., Suite 201
              <br />
              Oakville, ON
              <br />
              L6J 7W5
            </p>
          </div>
        )}
        {paymentType !==
          _.get(company.data, 'company_billing.payment_type') && (
          <Button
            style={{ marginTop: '20px' }}
            type="primary"
            disabled={paymentType === 'credit_card' && !token}
            htmlType="submit"
          >
            {_.get(company.data, 'company_billing.payment_type')
              ? 'Confirm Changes'
              : 'Save Payment Preferences'}
          </Button>
        )}
      </form>
      {/* TODO: Fetch Invoices from server */}
      <UikDivider />
      <h3 className="sub-header">Previous Receipts & invoices</h3>
      <p>Coming Soon!</p>
      {/*
      <UikWidgetTable>
        <thead>
          <tr>
            <th>Date</th>
            <th>TYPE</th>
            <th>DOWNLOAD</th>
          </tr>
        </thead>
        <tbody>{renderServicesTable()}</tbody>
      </UikWidgetTable>
      */}
    </div>
  );
}

InvoicesBilling.propTypes = {
  renderServicesTable: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  savePaymentType: PropTypes.func.isRequired,
  renderRadio: PropTypes.func.isRequired,
  paymentType: PropTypes.string,
  userEmail: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
};

InvoicesBilling.defaultProps = {
  paymentType: '',
  userEmail: '',
};

const mapStateToProps = (state, props) => {
  const { data } = props.company;
  const formSelector = formValueSelector('InvoicesBillingForm');
  return {
    initialValues: {
      paymentType: _.get(data, 'company_billing.payment_type'),
    },
    paymentType: formSelector(state, 'paymentType'),
  };
};

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(
  reduxForm({
    form: 'InvoicesBillingForm',
    enableReinitialize: true,
  })(InvoicesBilling),
);
