/**
 *
 * AdminMember
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { useInjectReducer } from 'utils/injectReducer';
import { UikWidget, UikLayoutMain } from '@uik/lib';
import { fetchAdminMembers } from './actions';
import makeSelectAdminMember from './selectors';
import reducer from './reducer';
import saga from './saga';
import MemberList from './MemberList';
import AddAdmin from './AddAdmin';
import ModifyAdmin from './ModifyAdmin';

export function AdminMember({ match, ...props }) {
  useInjectReducer({ key: 'adminMembers', reducer });
  useInjectSaga({ key: 'adminMembers', saga });

  useEffect(() => {
    props.fetchAdminMembers();
  }, []);

  return (
    <UikLayoutMain>
      <UikWidget padding>
        <h2 className="header">Administrators</h2>
        <Switch>
          <Route path={`${match.url}/add_admin`} component={AddAdmin} />
          <Route
            path={`${match.url}/:id/modify_admin`}
            component={ModifyAdmin}
          />
          <Route path={`${match.url}`} component={MemberList} />
        </Switch>
      </UikWidget>
    </UikLayoutMain>
  );
}

AdminMember.propTypes = {
  fetchAdminMembers: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  adminMember: makeSelectAdminMember(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchAdminMembers: (companyId) => dispatch(fetchAdminMembers(companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AdminMember);
