/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/**
 *
 * EmailReminders
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { Button, Icon } from 'antd';
import AntSelect from 'components/AntSelect';
import AntInput from 'components/AntInput';
import AntDatePicker from 'components/AntDatePicker';
import AntTimePicker from 'components/AntTimePicker';
import Checkbox from 'components/RfCheckbox';
import TextEditor from 'components/TextEditor';
import DropdownField from 'containers/MyAccount/Form/DropdownField';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from '../App/selectors';
import { makeSelectEmailReminderTemplates } from './selectors';
import {
  createEmailReminder,
  fetchEmailReminderTemplates,
  fetchEmailReminders,
  fetchTranquilityInfo,
  clearTranquilityInfo,
} from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import { required, isTranquilityURL } from '../../utils/validation';
import { useHistory } from 'react-router-dom';

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;
const FormColContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 263px;
  display: flex;
  > div {
    width: 100%;
  }
  margin-bottom: 30px;
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

const audience = [
  { label: 'Everybody', value: 'full' },
  { label: 'Those who have registered', value: 'registered' },
  { label: "Those who haven't registered", value: 'no_register' },
  { label: "Those who haven't done assess", value: 'no_assess' },
];

const language_options = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'English and French', value: 'en_fr' },
  { label: 'French and English', value: 'fr_en' },
];

const serviceOptions = [
  { label: 'Tranquility', value: 'tranquility' },
  { label: 'Inkblot', value: 'inkblot' },
  { label: 'Maple', value: 'maple' },
];

const serviceOptionsReg = [{ label: 'Inkblot', value: 'inkblot' }];

export function AddReminder(props) {
  const {
    currentCompanyId,
    currentUser,
    template,
    send_now,
    language,
    emailReminderTemplates,
  } = props;
  const [lines, setLines] = useState(1);
  const [middleLines, setMiddleLines] = useState(1);
  const [beforeCtaMiddleLines, setBeforeCtaMiddleLines] = useState(1);
  const [managedResellers, setManagedResellers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    props.fetchEmailReminderTemplates();
    if (props.currentUser && props.currentUser.managed_companies) {
      const Resellers = props.currentUser.managed_companies.filter(
        (e) => e.reseller_yn,
      );
      if (Resellers.length) setManagedResellers(Resellers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const history = useHistory();

  useEffect(() => {
    props.clearTranquilityInfo();
    if (isTranquilityEmailSelected(emailReminderTemplates.data, template)) {
      props.fetchTranquilityInfo(currentCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, currentCompanyId]);

  const onSubmit = (values) => {
    setLoading(true);
    const time = values.send_time ? values.send_time.format('HH:mm') : '05:00';
    if (values.send_now_yn) {
      values.scheduled_send_date = moment().format();
    } else {
      values.scheduled_send_date = moment(
        `${values.send_date.format('YYYY-MM-DD')} ${time}`,
      ).format('YYYY-MM-DD HH:mm');
    }
    values.company_id =
      (values.selected_reseller && values.selected_reseller.value) ||
      currentCompanyId;
    if (values.language !== 'fr' && !_.isNull(values.line0)) {
      values.main_lines = [];
      values.middle_lines = [];
      values.before_cta_middle_lines = [];
      // merge lines[0], lines[1], lines[3]... to main_lines
      for (let i = 0; i < lines; i += 1) {
        values.main_lines.push(values[`line${i}`]);
        delete values[`line${i}`];
      }
      // merge middle_line[0], middle_line[1], middle_line[3]... to middle_lines
      if (middleLines && !!values.middle_line0) {
        for (let i = 0; i < middleLines; i += 1) {
          values.middle_lines.push(values[`middle_line${i}`]);
          delete values[`middle_line${i}`];
        }
      }
      if (beforeCtaMiddleLines && !!values.before_cta_middle_line0) {
        for (let i = 0; i < beforeCtaMiddleLines; i += 1) {
          values.before_cta_middle_lines.push(
            values[`before_cta_middle_line${i}`],
          );
          delete values[`before_cta_middle_line${i}`];
        }
      }
      values.signature_lines = [values.signature_line0, values.signature_line1];
      delete values.signature_line0;
      delete values.signature_line1;
    }
    if (values.language !== 'en' && !_.isNull(values.french_line0)) {
      values.stacked_lines = [];
      values.middle_stacked_lines = [];
      values.before_cta_middle_stacked_lines = [];
      for (let i = 0; i < lines; i += 1) {
        values.stacked_lines.push(values[`french_line${i}`]);
        delete values[`french_line${i}`];
      }
      for (let i = 0; i < middleLines; i += 1) {
        values.middle_stacked_lines.push(values[`middle_french_line${i}`]);
        delete values[`middle_french_line${i}`];
      }
      for (let i = 0; i < beforeCtaMiddleLines; i += 1) {
        values.before_cta_middle_stacked_lines.push(
          values[`before_cta_middle_french_line${i}`],
        );
        delete values[`before_cta_middle_french_line${i}`];
      }
      values.french_signature_lines = [
        values.french_signature_line0,
        values.french_signature_line1,
      ];
      delete values.french_signature_line0;
      delete values.french_signature_line1;
    }
    props.createEmailReminder(values, (_reminder, error) => {
      if (!error) {
        setLoading(false);
        props.fetchEmailReminders(currentCompanyId);
        history.push('/email_reminders');
      }
    });
  };

  const formatEmailTemplates = (reminders) => {
    if (!reminders) return [];
    const array = reminders.map((email) => ({
      id: email.id,
      label: email.friendly_name,
      value: email.id,
    }));
    return array;
  };

  const custom = (reminders, template_id) => {
    const custom_template = _.find(
      reminders,
      (temp) => temp.template_name === 'custom_email',
    );
    if (_.get(custom_template, ['id'], null) === template_id) {
      return true;
    }
    return false;
  };

  const is_registration_custom_email = (reminders, template_id) => {
    const custom_template = _.find(
      reminders,
      (temp) => temp.template_name === 'registration_custom_email',
    );
    if (_.get(custom_template, ['id'], null) === template_id) {
      return true;
    }
    return false;
  };

  const isTranquilityEmailSelected = (reminders, template_id) => {
    if (!reminders || !template_id) {
      return false;
    }
    const CheckKeys = [
      'tranquility_welcome_email',
      'tranquility_reminder_email',
      'tranquility_final_reminder_email',
    ];
    let i = 0;
    while (i < reminders.length) {
      const temp_name = reminders[i] && reminders[i].template_name;
      const res =
        temp_name &&
        CheckKeys.includes(temp_name) &&
        reminders[i].id === template_id;
      if (res) return true;
      i += 1;
    }
    return false;
  };

  const renderLines = (isRegistrationCustom) => {
    if (language === 'fr') {
      return renderFrench(isRegistrationCustom);
    }
    if (language === 'en_fr') {
      return (
        <>
          {renderEnglish(isRegistrationCustom)}
          {renderFrench(isRegistrationCustom)}
        </>
      );
    }
    if (language === 'fr_en') {
      return (
        <>
          {renderFrench(isRegistrationCustom)}
          {renderEnglish(isRegistrationCustom)}
        </>
      );
    }
    return renderEnglish(isRegistrationCustom);
  };
  const renderLineNumberButton = (type) => {
    const increase = (pre) => pre + 1;
    const decrease = (pre) => pre - 1;
    return (
      <ButtonContainer style={{ marginBottom: 25 }}>
        <Button
          htmlType="button"
          type="primary"
          onClick={() => {
            if (type === 'middle') {
              setMiddleLines(increase);
            } else if (type === 'before_cta_middle') {
              setBeforeCtaMiddleLines(increase);
            } else {
              setLines(increase);
            }
          }}
        >
          <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
        </Button>
        <Button
          htmlType="button"
          type="danger"
          onClick={() => {
            if (type === 'middle') {
              setMiddleLines(decrease);
            } else if (type === 'before_cta_middle') {
              setBeforeCtaMiddleLines(decrease);
            } else {
              setLines(decrease);
            }
          }}
        >
          <Icon type="delete" style={{ verticalAlign: 0 }} />
        </Button>
      </ButtonContainer>
    );
  };
  const renderEnglish = (isRegistrationCustom) => {
    return (
      <>
        {isRegistrationCustom && (
          <FormRowContainer>
            <Field
              name="header"
              label="Header English"
              style={{ width: 300 }}
              component={AntInput}
            />
          </FormRowContainer>
        )}
        {/* Before CTA Button Paragraph Lines */}
        {unlimitedLines()}
        {renderLineNumberButton()}
        {isRegistrationCustom && unlimitedLines('before_cta_middle')}
        {isRegistrationCustom && renderLineNumberButton('before_cta_middle')}
        {/* After CTA Button Paragraph Lines */}
        {unlimitedLines('middle')}
        {renderLineNumberButton('middle')}
        <>
          <FormRowContainer>
            <Field
              name="signature_line0"
              label="Closing"
              style={{ width: 300 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="signature_line1"
              label="From"
              style={{ width: 750 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
        </>
      </>
    );
  };

  const renderFrench = (isRegistrationCustom) => {
    return (
      <>
        {isRegistrationCustom && (
          <FormRowContainer>
            <Field
              name="header_fr"
              label="Header French"
              style={{ width: 300 }}
              component={AntInput}
            />
          </FormRowContainer>
        )}
        {/* Before CTA Button Paragraph Lines */}
        {unlimitedLines(null, 'french')}
        {renderLineNumberButton()}
        {/* After CTA Button Paragraph Lines */}
        {isRegistrationCustom && unlimitedLines('before_cta_middle', 'french')}
        {isRegistrationCustom &&
          renderLineNumberButton('before_cta_middle', 'french')}
        {unlimitedLines('middle', 'french')}
        {renderLineNumberButton('middle')}
        <>
          <FormRowContainer>
            <Field
              name="french_signature_line0"
              label="French Closing"
              style={{ width: 300 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
          <FormRowContainer>
            <Field
              name="french_signature_line1"
              label="French From"
              style={{ width: 750 }}
              component={AntInput}
              validate={[required]}
            />
          </FormRowContainer>
        </>
      </>
    );
  };
  const unlimitedLines = (lineType, lang) => {
    // generate len(1) array initially. then add length to it when + button clicked.
    const paraLen =
      lineType === 'middle'
        ? middleLines
        : lineType === 'before_cta_middle'
        ? beforeCtaMiddleLines
        : lines;

    const paragraphs = Array(paraLen).fill();

    const languageKey = lang ? `${lang}_` : '';
    return paragraphs.map((a, i) => {
      const name =
        lineType === 'middle'
          ? `middle_${languageKey}line${i}`
          : lineType === 'before_cta_middle'
          ? `before_cta_middle_${languageKey}line${i}`
          : `${languageKey}line${i}`;

      const label =
        lineType === 'middle'
          ? 'After CTA button '
          : lineType === 'before_cta_middle'
          ? 'after org. code'
          : is_registration_custom_email(
              props.emailReminderTemplates.data,
              template,
            )
          ? 'before org. code'
          : '';
      return (
        <FormColContainer key={name}>
          <Field
            name={name}
            label={`${label} ${
              lang ? lang.charAt(0).toUpperCase() + lang.slice(1) : 'English'
            } Paragraph ${i + 1}`}
            component={TextEditor}
          />
        </FormColContainer>
      );
    });
  };

  // if you are only admin, you cant be on this page
  if (!_.isEmpty(currentUser) && !currentUser.inkblot_admin_yn) {
    history.push('/my_account/company_information');
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">New Reminder</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="email_reminder_template_id"
            label="Email Template"
            width="300px"
            component={AntSelect}
            options={formatEmailTemplates(props.emailReminderTemplates.data)}
          />
        </FormRowContainer>
        {(custom(props.emailReminderTemplates.data, template) ||
          is_registration_custom_email(
            props.emailReminderTemplates.data,
            template,
          )) && (
          <>
            <FormRowContainer>
              <Field
                name="use_as_welcome_email_yn"
                label="Replace as welcome email"
                component={Checkbox}
              />
            </FormRowContainer>
            {managedResellers.length && (
              <div>
                <FormRowContainer>
                  <Field
                    name="send_to_all_resold_companies"
                    label="Send to all Resold companies under a Reseller"
                    component={Checkbox}
                  />
                </FormRowContainer>
                <FormRowContainer>
                  <Field
                    name="service"
                    component={AntSelect}
                    label="Service"
                    options={
                      is_registration_custom_email(
                        props.emailReminderTemplates.data,
                        template,
                      )
                        ? serviceOptionsReg
                        : serviceOptions
                    }
                  />
                </FormRowContainer>
                {props.send_to_all_resold_companies && (
                  <FormRowContainer>
                    <Field
                      name="selected_reseller"
                      label="Select a reseller"
                      component={DropdownField}
                      options={managedResellers.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))}
                    />
                  </FormRowContainer>
                )}
              </div>
            )}
            <FormRowContainer>
              <Field
                name="subject"
                label="Subject"
                style={{ width: 500 }}
                component={AntInput}
                validate={[required]}
              />
            </FormRowContainer>
            <FormRowContainer>
              <Field
                name="language"
                label="Language"
                width="300px"
                component={AntSelect}
                options={language_options}
                validate={[required]}
              />
            </FormRowContainer>
            {renderLines(
              is_registration_custom_email(
                props.emailReminderTemplates.data,
                template,
              ),
            )}
            <FormRowContainer>
              <Field
                name="audience"
                label="Audience"
                width="300px"
                component={AntSelect}
                options={audience}
                validate={[required]}
              />
            </FormRowContainer>
          </>
        )}
        {props.tranquilityInfo &&
          props.tranquilityInfo.renderURLInput === true && (
            <FormRowContainer>
              <Field
                name="tranquility_url"
                label="tranquility URL"
                component={AntInput}
                validate={[isTranquilityURL]}
                style={{ width: 300 }}
              />
            </FormRowContainer>
          )}
        {props.tranquilityInfo && props.tranquilityInfo.error && (
          <p>{props.tranquilityInfo.error}</p>
        )}
        <FormRowContainer>
          <Field name="send_now_yn" label="Send Now" component={Checkbox} />
        </FormRowContainer>
        {!send_now && (
          <FormRowContainer>
            <Field
              name="send_date"
              label="Day to send"
              component={AntDatePicker}
              validate={[required]}
            />
            <Field
              name="send_time"
              label="Time to send (EST)"
              component={AntTimePicker}
            />
          </FormRowContainer>
        )}
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.emailReminderTemplates.error &&
              ((props.emailReminderTemplates.error &&
                props.emailReminderTemplates.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        <ButtonContainer>
          <Button loading={loading} htmlType="submit" type="primary">
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Schedule Reminder
          </Button>
          <Button
            type="danger"
            onClick={() => history.push('/email_reminders')}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </SubContainer>
  );
}

AddReminder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchEmailReminderTemplates: PropTypes.func.isRequired,
  fetchEmailReminders: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  createEmailReminder: PropTypes.func.isRequired,
  currentCompanyId: PropTypes.number,
  emailReminderTemplates: PropTypes.object.isRequired,
  template: PropTypes.number,
  send_now: PropTypes.bool,
  language: PropTypes.string,
  fetchTranquilityInfo: PropTypes.func,
  tranquilityInfo: PropTypes.object,
  clearTranquilityInfo: PropTypes.func,
  send_to_all_resold_companies: PropTypes.bool,
};

function mapStateToProps(state) {
  const selector = formValueSelector('AddReminderForm');

  const sagaMapStateToProps = createStructuredSelector({
    currentUser: makeSelectCurrentUser(),
    emailReminderTemplates: makeSelectEmailReminderTemplates(),
    currentCompanyId: makeSelectCurrentCompanyId(),
  })(state);

  return {
    initialValues: {
      signature_line0: 'Thank You,',
      signature_line1: 'Inkblot',
      french_signature_line0: 'Cordialement,',
      french_signature_line1: 'Inkblot',
    },
    template: selector(state, 'email_reminder_template_id'),
    send_now: selector(state, 'send_now_yn'),
    send_to_all_resold_companies: selector(
      state,
      'send_to_all_resold_companies',
    ),
    language: selector(state, 'language'),
    tranquilityInfo: _.get(
      state,
      'emailReminders.emailReminder.tranquilityInfo',
    ),
    ...sagaMapStateToProps,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createEmailReminder: (values, callback) =>
      dispatch(createEmailReminder(values, callback)),
    fetchEmailReminderTemplates: () => dispatch(fetchEmailReminderTemplates()),
    fetchEmailReminders: (companyId) =>
      dispatch(fetchEmailReminders(companyId)),
    fetchTranquilityInfo: (companyId) =>
      dispatch(fetchTranquilityInfo(companyId)),
    clearTranquilityInfo: () => dispatch(clearTranquilityInfo()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    enableReinitialize: true,
    form: 'AddReminderForm',
  })(AddReminder),
);
