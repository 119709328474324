import _ from 'lodash';
import { canadianProvinces } from '../../../../pagePartials';

export const safe = (obj, path) => _.get(obj, path, '');
export const safeObj = (obj, path) => _.get(obj, path, {});

export const formattedAddress = (arr) => arr.filter(Boolean).join(', ');

export const findProvince = (id) => {
  const provinceObj = canadianProvinces.find((x) => x.value === id);

  return provinceObj ? provinceObj.label : '';
};

export const parseContact = (obj, type) => {
  const contact = _.find(obj, ['type_of', type]);
  const parsed = {};
  const businessPhone = _.find(contact && contact.phone_numbers, [
    'phone_type',
    'business',
  ]);
  const smsPhone = _.find(contact && contact.phone_numbers, [
    'phone_type',
    'sms',
  ]);
  if (contact) {
    parsed.name = contact.name;
    parsed.email = contact.email;
    parsed.title = contact.title;
    parsed.address = contact.address;
    parsed.cc_emails = contact.cc_emails;
    parsed.business_phone = businessPhone && businessPhone.number;
    parsed.sms_phone = smsPhone && smsPhone.number;

    return parsed;
  }
  return parsed;
};
