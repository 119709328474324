/*
 *
 * TeamManagement reducer
 *
 */
import produce from 'immer';
import {
  FETCH_ADMIN_MEMBERS,
  FETCH_ADMIN_MEMBERS_SUCCESS,
  FETCH_ADMIN_MEMBERS_ERROR,
  CREATE_ADMIN_MEMBER,
  CREATE_ADMIN_MEMBER_SUCCESS,
  CREATE_ADMIN_MEMBER_ERROR,
  FETCH_ADMIN_MEMBER,
  FETCH_ADMIN_MEMBER_SUCCESS,
  FETCH_ADMIN_MEMBER_ERROR,
  UPDATE_ADMIN_MEMBER,
  UPDATE_ADMIN_MEMBER_SUCCESS,
  UPDATE_ADMIN_MEMBER_ERROR,
  DESTROY_ADMIN_MEMBER_SUCCESS,
  DESTROY_ADMIN_MEMBER,
  DESTROY_ADMIN_MEMBER_ERROR,
} from './constants';

export const initialState = {
  adminMembers: {
    loading: false,
    error: false,
    data: false,
  },
  adminMember: {
    loading: false,
    error: false,
    data: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const administrationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_ADMIN_MEMBERS:
        draft.adminMembers.loading = true;
        draft.adminMembers.error = false;
        draft.adminMembers.data = false;
        break;

      case FETCH_ADMIN_MEMBERS_SUCCESS:
        draft.adminMembers.data = action.members;

        draft.adminMembers.loading = false;
        break;

      case FETCH_ADMIN_MEMBERS_ERROR:
        draft.adminMembers.error = action.error;
        draft.adminMembers.loading = false;
        break;
      case DESTROY_ADMIN_MEMBER:
      case CREATE_ADMIN_MEMBER:
      case FETCH_ADMIN_MEMBER:
      case UPDATE_ADMIN_MEMBER:
        draft.adminMember.loading = true;
        draft.adminMember.error = false;
        draft.adminMember.data = false;
        break;

      case FETCH_ADMIN_MEMBER_SUCCESS:
        draft.adminMember.data = action.member;
        draft.adminMember.loading = false;
        break;
      case UPDATE_ADMIN_MEMBER_SUCCESS:
        draft.adminMembers.data.forEach((element, index) => {
          if (element.id === action.member.id) {
            draft.adminMembers.data[index] = action.member;
          }
        });
        draft.adminMember.data = action.member;
        draft.adminMember.loading = false;
        break;
      case CREATE_ADMIN_MEMBER_SUCCESS:
        draft.adminMembers.data.push(action.member);
        draft.adminMember.loading = false;
        break;
      case DESTROY_ADMIN_MEMBER_SUCCESS:
        draft.adminMembers.data = draft.adminMembers.data.filter((x) => {
          return x.id !== action.member.id;
        });
        draft.adminMember.data = action.member;
        draft.adminMember.loading = false;
        break;

      case CREATE_ADMIN_MEMBER_ERROR:
      case DESTROY_ADMIN_MEMBER_ERROR:
      case FETCH_ADMIN_MEMBER_ERROR:
      case UPDATE_ADMIN_MEMBER_ERROR:
        draft.adminMember.error = action.error;
        draft.adminMember.loading = false;
        break;
    }
  });

export default administrationReducer;
