import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { UikInput } from '@uik/lib';
import '@uik/lib/styles.scss';

const InputContainer = styled.div`
  position: relative;

  .uik-input__errorMessage {
    position: absolute;
    font-size: 11px;
    margin: 0px;
  }

  .error-warning {
    color: red;
    font-size: 9px;
  }
`;

function InputField({
  input,
  label,
  placeholder,
  normalize,
  validate,
  meta: { touched, error, warning },
}) {
  return (
    <InputContainer>
      <UikInput
        {...input}
        label={label}
        value={[input.value]}
        placeholder={placeholder}
        style={{ width: '180px' }}
        normalize={normalize}
        validate={validate}
      />
      {touched &&
        ((error && <span className="error-warning">{error}</span>) ||
          (warning && <span className="error-warning">{warning}</span>))}
    </InputContainer>
  );
}

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  normalize: PropTypes.func,
  validate: PropTypes.func,
};

export default InputField;
