import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the emailReminders state domain
 */

const selectEmailRemindersDomain = (state) =>
  state.emailReminders || initialState;

/**
 * Other specific selectors
 */
const makeSelectEmailReminderTemplates = () =>
  createSelector(
    selectEmailRemindersDomain,
    (substate) => substate.emailReminderTemplates,
  );

const makeSelectEmailReminders = () =>
  createSelector(
    selectEmailRemindersDomain,
    (substate) => substate.emailReminders,
  );

const makeSelectEmailReminder = () =>
  createSelector(
    selectEmailRemindersDomain,
    (substate) => substate.emailReminder,
  );

/**
 * Default selector used by TeamManagement
 */

const makeSelectEmailReminderManagement = () =>
  createSelector(selectEmailRemindersDomain, (substate) => substate);

export default makeSelectEmailReminderManagement;
export {
  selectEmailRemindersDomain,
  makeSelectEmailReminder,
  makeSelectEmailReminderTemplates,
  makeSelectEmailReminders,
};
