/* eslint-disable no-param-reassign */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Checkbox } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import Container from './Container';
import TextData from './TextData';
import { safe } from './helpers';
import { canadianProvinces } from '../../../../pagePartials';
import { updateCompany } from '../../actions';
import { validatePostalCode } from '../../../../utils/validation';

const BasicsForm = (props) => {
  const [sameAs, setSameAs] = useState(false);
  const { company, handleSubmit, toggle } = props;

  const onSubmit = (values) => {
    if (sameAs) {
      values.company_mailing = {
        ...values.company_office,
        type_of: 'company_mailing',
      };
    }
    props.updateCompany(company.id, values, () => {
      toggle();
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <TextData tag="Company Name" data={safe(company, 'name')} />
        <TextData tag="Alias Name" data={safe(company, 'alias')} />
      </Container>
      <Container>
        <TextData
          tag="Customized Inkblot URL"
          data={safe(company, 'customized_url')}
          width="100%"
        />
      </Container>
      <div>
        <h4 style={{ marginBottom: '12px' }}>Office Address</h4>
        <Container maxWidth="600px" style={{ justifyContent: 'space-between' }}>
          <Field
            component={AntInput}
            label="ADDRESS 1"
            name="company_office.street"
          />
          <Field
            component={AntInput}
            label="ADDRESS 2"
            name="company_office.alternate_street"
          />
          <Field
            component={AntInput}
            label="CITY/TOWN"
            name="company_office.city"
          />
        </Container>
        <Container maxWidth="600px" style={{ justifyContent: 'space-between' }}>
          <Field
            component={AntSelect}
            options={canadianProvinces}
            label="PROVINCE/TERRITORY"
            name="company_office.state_id"
          />
          <Field
            component={AntInput}
            label="POSTAL CODE"
            name="company_office.code"
            validate={validatePostalCode}
          />
        </Container>
      </div>
      <div>
        <h4 style={{ marginBottom: '12px' }}>Mailing Address</h4>
        <Checkbox
          onClick={() => setSameAs(!sameAs)}
          style={{ marginTop: '28px', marginBottom: '40px' }}
        >
          Same as office address{' '}
        </Checkbox>
        {!sameAs && (
          <>
            <Container
              maxWidth="600px"
              style={{ justifyContent: 'space-between' }}
            >
              <Field
                component={AntInput}
                label="ADDRESS 1"
                name="company_mailing.street"
              />
              <Field
                component={AntInput}
                label="ADDRESS 2"
                name="company_mailing.alternate_street"
              />
              <Field
                component={AntInput}
                label="CITY/TOWN"
                name="company_mailing.city"
              />
            </Container>
            <Container
              maxWidth="600px"
              style={{ justifyContent: 'space-between' }}
            >
              <Field
                component={AntSelect}
                options={canadianProvinces}
                label="PROVINCE/TERRITORY"
                name="company_mailing.state_id"
              />
              <Field
                component={AntInput}
                label="POSTAL CODE"
                name="company_mailing.code"
                validate={validatePostalCode}
              />
            </Container>
          </>
        )}
      </div>
      <Button htmlType="submit" type="primary">
        Save Changes
      </Button>
      <Button style={{ marginLeft: 10 }} onClick={() => toggle()}>
        Cancel
      </Button>
    </form>
  );
};

BasicsForm.propTypes = {
  company: PropTypes.object,
  updateCompany: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
};

function mapStateToProps(_state, ownProps) {
  const { company } = ownProps;
  const officeAddress = _.get(company, 'office_address');
  const mailingAddress = _.get(company, 'mailing_address');
  return {
    initialValues: {
      company_office: { ...officeAddress },
      company_mailing: { ...mailingAddress },
    },
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateCompany: (id, values, callback) =>
      dispatch(updateCompany(id, values, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'BasicsForm',
    enableReinitialize: true,
  })(BasicsForm),
);
