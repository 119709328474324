/**
 *
 * AntMultipleEmailsInput
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from 'antd';
import { validateEmail } from 'utils/validation';

const TextWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 0.75rem;
    color: #9ea0a5;
    line-height: 1.125rem;
    text-transform: uppercase;
  }
`;

function AntMultipleEmailsInput({
  input,
  width,
  label,
  placeholder,
  meta: { touched, error, warning },
}) {
  const { value, onChange: changeValue } = input;
  const emails = value ? value.split(',') : [];

  const handleSelectChange = (valueArray) => {
    changeValue(valueArray.filter((v) => !validateEmail(v)).join(','));
  };

  return (
    <TextWrapper>
      <p className="tag">{label}</p>
      <Select
        value={emails}
        placeholder={placeholder}
        style={{ width: width || '390px' }}
        mode="tags"
        onChange={(valueArray) => handleSelectChange(valueArray)}
        tokenSeparators={[',', ' ', ';']}
        open={false}
      />
      {touched &&
        ((error && <span className="error-warning">{error}</span>) ||
          (warning && <span className="error-warning">{warning}</span>))}
    </TextWrapper>
  );
}

AntMultipleEmailsInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  meta: PropTypes.object.isRequired,
};

export default AntMultipleEmailsInput;
