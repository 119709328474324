/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { LD_CLIENT_ID } from 'utils/environment';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

function LDProvider(props) {
  return <div>{props.children}</div>;
}

LDProvider.propTypes = {
  children: PropTypes.object,
};

export default withLDProvider({
  clientSideID: LD_CLIENT_ID,
  user: {
    key: 'marchel-admin-anonymous',
    anonymous: true,
  },
})(LDProvider);
