import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { get, put, post } from 'utils/request';

import {
  BACKFILL_PREVIEW,
  FETCH_COMPANY,
  UPDATE_COMPANY,
  UPLOAD_EMPLOYEE_CSV,
  SAVE_PAYMENT_TYPE,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  TOGGLE_DISABLE_COMPANY,
  FETCH_EMPLOYEE_LIST,
  EXPORT_EMPLOYEE_LIST,
  FETCH_CUSTOM_FIELDS,
  FETCH_CP_NAVIGATORS,
} from './constants';
import {
  fetchCompanySuccess,
  fetchCompanyError,
  updateCompanySuccess,
  updateCompanyError,
  uploadEmployeeCsvSuccess,
  uploadEmployeeCsvError,
  backfillPreviewSuccess,
  backfillPreviewError,
  savePaymentTypeSuccess,
  savePaymentTypeError,
  createEmployeeSuccess,
  createEmployeeError,
  deleteEmployeeSuccess,
  deleteEmployeeError,
  toggleDisableCompanyError,
  toggleDisableCompanySuccess,
  fetchEmployeeListSuccess,
  fetchEmployeeListError,
  exportEmployeeListSuccess,
  exportEmployeeListError,
  fetchCustomFieldsSuccess,
} from './actions';

function* fetchCompany({ id }) {
  const requestUrl = `${API_URL}/companies/${id}`;
  try {
    const company = yield call(get, requestUrl);
    yield reduxPut(fetchCompanySuccess(company));
  } catch (error) {
    yield reduxPut(fetchCompanyError(error));
  }
}

function* updateCompany({ id, values, callback }) {
  const requestUrl = `${API_URL}/companies/${id}`;
  try {
    const company = yield call(put, requestUrl, values);
    yield reduxPut(updateCompanySuccess(company));
    if (callback) callback(company);
  } catch (error) {
    yield reduxPut(updateCompanyError(error));
    if (callback) callback(null, error);
  }
}

function* backfillPreview({ values, callback }) {
  const requestUrl = `${API_URL}/companies/backfill_preview`;

  try {
    const success = yield call(post, requestUrl, values);
    yield reduxPut(backfillPreviewSuccess(success));
    if (callback) callback(success);
  } catch (error) {
    yield reduxPut(backfillPreviewError(error));
  }
}

function* uploadEmployeeCsv({ values, callback }) {
  const requestUrl = `${API_URL}/companies/upload_csv`;

  try {
    const success = yield call(post, requestUrl, values);
    yield reduxPut(uploadEmployeeCsvSuccess(success));

    if (callback) callback(success);
  } catch (error) {
    yield reduxPut(uploadEmployeeCsvError(error));
  }
}

function* savePaymentType({ values, callback }) {
  const requestUrl = `${API_URL}/companies/save_payment_type`;

  try {
    const success = yield call(put, requestUrl, values);
    yield reduxPut(savePaymentTypeSuccess(success));

    if (callback) callback(success);
  } catch (error) {
    yield reduxPut(savePaymentTypeError(error));
  }
}

function* createEmployee({ values, callback }) {
  const requestUrl = `${API_URL}/employees`;

  try {
    const employee = yield call(post, requestUrl, values);
    yield reduxPut(createEmployeeSuccess(employee));
    if (callback) callback(employee);
  } catch (error) {
    yield reduxPut(createEmployeeError(error));
    if (callback) callback(null, error);
  }
}

function* deleteEmployee({ values, callback }) {
  const requestUrl = `${API_URL}/employees/remove`;

  try {
    const employee = yield call(put, requestUrl, values);
    yield reduxPut(deleteEmployeeSuccess(employee));
    if (callback) callback(employee);
  } catch (error) {
    yield reduxPut(deleteEmployeeError(error));
    if (callback) callback(null, error);
  }
}

function* toggleDisableCompany({ values, callback }) {
  const requestUrl = `${API_URL}/companies/toggle_disable_company`;
  const company = yield call(put, requestUrl, values);
  try {
    yield reduxPut(toggleDisableCompanySuccess(company));
    if (callback) callback(company);
  } catch (error) {
    yield reduxPut(toggleDisableCompanyError(company));
    if (callback) callback(null, error);
  }
}

function* fetchEmployeeList({
  values: { currentCompanyId, page, status, query },
  callback,
}) {
  const requestUrl = `${API_URL}/employees?company_id=${currentCompanyId}&page=${page}&status=${status}&query=${query}`;

  try {
    const employeeList = yield call(get, requestUrl);
    yield reduxPut(fetchEmployeeListSuccess(employeeList));
    if (callback) callback(employeeList);
  } catch (error) {
    yield reduxPut(fetchEmployeeListError(error));
    if (callback) callback(null, error);
  }
}

function* exportEmployeeList({
  values: { currentCompanyId, employeeType },
  callback,
}) {
  const requestUrl = `${API_URL}/employees/export_csv?company_id=${currentCompanyId}&employee_type=${employeeType}`;

  try {
    yield call(get, requestUrl);
    yield reduxPut(exportEmployeeListSuccess());
    if (callback) callback(null);
  } catch (error) {
    yield reduxPut(exportEmployeeListError(error));
    if (callback) callback(error);
  }
}

function* fetchCustomFields({ companyId }) {
  const requestUrl = `${API_URL}/companies/${companyId}/custom_fields`;
  try {
    const customFields = yield call(get, requestUrl);
    yield reduxPut(fetchCustomFieldsSuccess(customFields));
  } catch (error) {
    // console.log(error);
  }
}

function* fetchClinicalNavigators({ callback }) {
  const requestUrl = `${API_URL}/clinical_navigators`;
  try {
    const companies = yield call(get, requestUrl);
    if (callback) callback(companies);
  } catch (error) {
    // console.log(error);
  }
}

export default function* MyAccountSaga() {
  yield takeLatest(FETCH_COMPANY, fetchCompany);
  yield takeLatest(UPDATE_COMPANY, updateCompany);
  yield takeLatest(UPLOAD_EMPLOYEE_CSV, uploadEmployeeCsv);
  yield takeLatest(BACKFILL_PREVIEW, backfillPreview);
  yield takeLatest(SAVE_PAYMENT_TYPE, savePaymentType);
  yield takeLatest(CREATE_EMPLOYEE, createEmployee);
  yield takeLatest(DELETE_EMPLOYEE, deleteEmployee);
  yield takeLatest(TOGGLE_DISABLE_COMPANY, toggleDisableCompany);
  yield takeLatest(FETCH_EMPLOYEE_LIST, fetchEmployeeList);
  yield takeLatest(EXPORT_EMPLOYEE_LIST, exportEmployeeList);
  yield takeLatest(FETCH_CUSTOM_FIELDS, fetchCustomFields);
  yield takeLatest(FETCH_CP_NAVIGATORS, fetchClinicalNavigators);
}
