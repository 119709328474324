/* eslint-disable camelcase */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { formattedAddress, findProvince } from './helpers';

const AddressWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3e3f42;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
  p.data {
    max-width: 220px;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    margin-bottom: 8px;
    letter-spacing: normal;
    color: #3e3f42;
  }
`;

const StyledAddress = ({ tag, data, width }) => {
  const { street, code, city, alternate_street, state_id } = data;
  return (
    <AddressWrapper width={width}>
      <p className="tag">{tag}</p>
      <p className="data">
        {formattedAddress([street, alternate_street, city])}
      </p>
      <p className="data">{findProvince(state_id)}</p>
      <p className="data">{code && code.toUpperCase()}</p>
    </AddressWrapper>
  );
};

StyledAddress.defaultProps = {
  tag: '',
  data: {},
  width: '50%',
};

StyledAddress.propTypes = {
  tag: PropTypes.string,
  data: PropTypes.object,
  width: PropTypes.string,
};

export default StyledAddress;
