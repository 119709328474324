import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import questionMarks from '../../images/LandingPage/animations/mental-health.png';
import messages from './messages';

export function SectionThree() {
  return (
    <div className="section-3">
      <div>
        <img src={questionMarks} alt="Your mental health partner" />
        <div className="section-3-text">
          <h2>
            <FormattedHTMLMessage {...messages.isComplicated} />
          </h2>
          <p>
            <FormattedMessage {...messages.mostEmployee} />
          </p>
        </div>
      </div>
    </div>
  );
}
