import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Button, Modal } from 'antd';

import RadioButton from 'components/RadioButton';

function ExportEmployeeListModal({ handleCancel, onSubmit, exportModalOpen }) {
  return (
    <Modal
      onCancel={handleCancel}
      onOk={onSubmit}
      title="Export Employee List"
      visible={exportModalOpen}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit}>
          Export
        </Button>,
      ]}
    >
      <form>
        <Field component={RadioButton} name="employee_type" />
      </form>
    </Modal>
  );
}

ExportEmployeeListModal.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  exportModalOpen: PropTypes.bool.isRequired,
};

export default ExportEmployeeListModal;
