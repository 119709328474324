import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';

import { ADMIN_API_URL } from 'utils/environment';
import { get, destroy, post, put } from 'utils/request';

import {
  FETCH_ADMIN_MEMBERS,
  CREATE_ADMIN_MEMBER,
  FETCH_ADMIN_MEMBER,
  UPDATE_ADMIN_MEMBER,
  DESTROY_ADMIN_MEMBER,
} from './constants';
import {
  fetchAdminMembersSuccess,
  fetchAdminMembersError,
  createAdminMemberSuccess,
  createAdminMemberError,
  fetchAdminMemberSuccess,
  fetchAdminMemberError,
  updateAdminMemberSuccess,
  updateAdminMemberError,
  destroyAdminMemberSuccess,
  destroyAdminMemberError,
} from './actions';

function* fetchAdminMembers({ callback }) {
  const requestUrl = `${ADMIN_API_URL}/marchel_admins`;

  try {
    const members = yield call(get, requestUrl);
    yield reduxPut(fetchAdminMembersSuccess(members));
    if (callback) callback(members);
  } catch (error) {
    yield reduxPut(fetchAdminMembersError(error));
  }
}

function* createAdminMember({ values, callback }) {
  const requestUrl = `${ADMIN_API_URL}/marchel_admins`;

  try {
    const member = yield call(post, requestUrl, values);
    yield reduxPut(createAdminMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(createAdminMemberError(error));
    if (callback) callback(null, error);
  }
}

function* fetchAdminMember({ id, callback }) {
  const requestUrl = `${ADMIN_API_URL}/marchel_admins/${id}`;

  try {
    const member = yield call(get, requestUrl);
    yield reduxPut(fetchAdminMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(fetchAdminMemberError(error));
    if (callback) callback(null, error);
  }
}

function* updateAdminMember({ id, values, callback }) {
  const requestUrl = `${ADMIN_API_URL}/marchel_admins/${id}`;

  try {
    const member = yield call(put, requestUrl, values);
    yield reduxPut(updateAdminMemberSuccess(member));
    if (callback) callback(member);
  } catch (error) {
    yield reduxPut(updateAdminMemberError(error));
    if (callback) callback(null, error);
  }
}

function* destroyAdminMember({ id }) {
  const requestUrl = `${ADMIN_API_URL}/marchel_admins/${id}`;

  try {
    const member = yield call(destroy, requestUrl, { id });
    yield reduxPut(destroyAdminMemberSuccess(member));
  } catch (error) {
    yield reduxPut(destroyAdminMemberError(error));
  }
}

export default function* teamManagementSaga() {
  yield takeLatest(FETCH_ADMIN_MEMBERS, fetchAdminMembers);
  yield takeLatest(CREATE_ADMIN_MEMBER, createAdminMember);
  yield takeLatest(FETCH_ADMIN_MEMBER, fetchAdminMember);
  yield takeLatest(UPDATE_ADMIN_MEMBER, updateAdminMember);
  yield takeLatest(DESTROY_ADMIN_MEMBER, destroyAdminMember);
}
