import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the teamManagement state domain
 */

const selectTeamManagementDomain = (state) =>
  state.teamManagement || initialState;

/**
 * Other specific selectors
 */
const makeSelectTeamMembers = () =>
  createSelector(
    selectTeamManagementDomain,
    (substate) => substate.teamMembers,
  );

const makeSelectTeamMember = () =>
  createSelector(selectTeamManagementDomain, (substate) => substate.teamMember);

/**
 * Default selector used by TeamManagement
 */

const makeSelectTeamManagement = () =>
  createSelector(selectTeamManagementDomain, (substate) => substate);

export default makeSelectTeamManagement;
export {
  selectTeamManagementDomain,
  makeSelectTeamMembers,
  makeSelectTeamMember,
};
