import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { PARTNER_URL } from 'utils/environment';

export function NavbarOne() {
  const [lang, setLang] = useState(localStorage.getItem('locale'));
  function setLanguage(e) {
    setLang(e.target.value);
    localStorage.setItem('locale', e.target.value);
    window.location.reload();
  }
  return (
    <div className="navbar-one">
      <div>
        <Link className="light-blue" to="/signin">
          BUSINESS LOGIN
        </Link>
        <a className="light-blue" href={`${PARTNER_URL}/signin`}>
          PARTNER LOGIN
        </a>
        <div className="right">
          <select value={lang} onChange={setLanguage}>
            <option value="en">EN</option>
            <option value="fr">FR</option>
          </select>
        </div>
      </div>
    </div>
  );
}
