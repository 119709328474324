/*
 *
 * EmailReminders actions
 *
 */

import {
  FETCH_EMAIL_REMINDER_TEMPLATES,
  FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS,
  FETCH_EMAIL_REMINDER_TEMPLATES_ERROR,
  CREATE_EMAIL_REMINDER,
  CREATE_EMAIL_REMINDER_SUCCESS,
  CREATE_EMAIL_REMINDER_ERROR,
  FETCH_EMAIL_REMINDERS,
  FETCH_EMAIL_REMINDERS_SUCCESS,
  FETCH_EMAIL_REMINDERS_ERROR,
  AUTOMATE_REMINDERS,
  AUTOMATE_REMINDERS_SUCCESS,
  AUTOMATE_REMINDERS_ERROR,
  FETCH_EMAIL_REMINDER,
  FETCH_EMAIL_REMINDER_SUCCESS,
  FETCH_EMAIL_REMINDER_ERROR,
  UPDATE_EMAIL_REMINDER,
  UPDATE_EMAIL_REMINDER_SUCCESS,
  UPDATE_EMAIL_REMINDER_ERROR,
  DESTROY_EMAIL_REMINDER,
  DESTROY_EMAIL_REMINDER_SUCCESS,
  DESTROY_EMAIL_REMINDER_ERROR,
  FETCH_TRANQUILITY_INFO,
  FETCH_TRANQUILITY_INFO_SUCCESS,
  FETCH_TRANQUILITY_INFO_ERROR,
  CLEAR_TRANQUILITY_INFO,
} from './constants';

export function fetchEmailReminderTemplates() {
  return {
    type: FETCH_EMAIL_REMINDER_TEMPLATES,
  };
}

export function fetchEmailReminderTemplatesSuccess(templates) {
  return {
    templates,
    type: FETCH_EMAIL_REMINDER_TEMPLATES_SUCCESS,
  };
}

export function fetchEmailReminderTemplatesError(error) {
  return {
    error,
    type: FETCH_EMAIL_REMINDER_TEMPLATES_ERROR,
  };
}

export function fetchEmailReminders(companyId = null) {
  return {
    companyId,
    type: FETCH_EMAIL_REMINDERS,
  };
}

export function fetchEmailRemindersSuccess(reminders) {
  return {
    reminders,
    type: FETCH_EMAIL_REMINDERS_SUCCESS,
  };
}

export function fetchEmailRemindersError(error) {
  return {
    error,
    type: FETCH_EMAIL_REMINDERS_ERROR,
  };
}

export function automateEmailReminders(callback, companyId = null) {
  return {
    callback,
    companyId,
    type: AUTOMATE_REMINDERS,
  };
}

export function automateEmailRemindersSuccess(reminders) {
  return {
    reminders,
    type: AUTOMATE_REMINDERS_SUCCESS,
  };
}

export function automateEmailRemindersError(error) {
  return {
    error,
    type: AUTOMATE_REMINDERS_ERROR,
  };
}

export function createEmailReminder(values, callback) {
  return {
    values,
    callback,
    type: CREATE_EMAIL_REMINDER,
  };
}

export function createEmailReminderSuccess(reminder) {
  return {
    reminder,
    type: CREATE_EMAIL_REMINDER_SUCCESS,
  };
}

export function createEmailReminderError(error) {
  return {
    error,
    type: CREATE_EMAIL_REMINDER_ERROR,
  };
}

export function fetchEmailReminder(id, callback, companyId = null) {
  return {
    id,
    callback,
    companyId,
    type: FETCH_EMAIL_REMINDER,
  };
}

export function fetchEmailReminderSuccess(member) {
  return {
    member,
    type: FETCH_EMAIL_REMINDER_SUCCESS,
  };
}

export function fetchEmailReminderError(error) {
  return {
    error,
    type: FETCH_EMAIL_REMINDER_ERROR,
  };
}

export function updateEmailReminder(id, values, callback, companyId = null) {
  return {
    id,
    values,
    callback,
    companyId,
    type: UPDATE_EMAIL_REMINDER,
  };
}

export function updateEmailReminderSuccess(reminder) {
  return {
    reminder,
    type: UPDATE_EMAIL_REMINDER_SUCCESS,
  };
}

export function updateEmailReminderError(error) {
  return {
    error,
    type: UPDATE_EMAIL_REMINDER_ERROR,
  };
}

export function destroyEmailReminder(id, callback) {
  return {
    id,
    callback,
    type: DESTROY_EMAIL_REMINDER,
  };
}

export function destroyEmailReminderSuccess(reminder) {
  return {
    reminder,
    type: DESTROY_EMAIL_REMINDER_SUCCESS,
  };
}

export function destroyEmailReminderError(error) {
  return {
    error,
    type: DESTROY_EMAIL_REMINDER_ERROR,
  };
}

export function fetchTranquilityInfo(companyId = null) {
  return {
    companyId,
    type: FETCH_TRANQUILITY_INFO,
  };
}

export function fetchTranquilityInfoSuccess(tranquilityInfo) {
  return {
    tranquilityInfo,
    type: FETCH_TRANQUILITY_INFO_SUCCESS,
  };
}

export function fetchTranquilityInfoError(error) {
  return {
    error,
    type: FETCH_TRANQUILITY_INFO_ERROR,
  };
}

export function clearTranquilityInfo() {
  return {
    type: CLEAR_TRANQUILITY_INFO,
  };
}
