/**
 *
 * Layout
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

import {
  UikContainerVertical,
  UikContainerHorizontal,
  UikLayoutMain,
} from '@uik/lib';
import SideBar from './SideBar';
import TopBar from './TopBar';

export function Layout(props) {
  return (
    <UikContainerVertical style={{ minHeight: '100vh' }}>
      <Helmet
        titleTemplate="%s - Business - Inkblot"
        defaultTitle="Business - Inkblot"
      >
        <meta name="description" content="marchel - Inkblot" />
      </Helmet>
      <TopBar />
      <UikContainerHorizontal>
        {!props.excludeSidebar && <SideBar />}
        <UikLayoutMain>{props.children}</UikLayoutMain>
      </UikContainerHorizontal>
    </UikContainerVertical>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  excludeSidebar: PropTypes.bool,
};

Layout.defaultProps = {
  excludeSidebar: false,
};

export default Layout;
