/*
 *
 * TeamManagement actions
 *
 */

import {
  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_ERROR,
  CREATE_TEAM_MEMBER,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_ERROR,
  FETCH_TEAM_MEMBER,
  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  DESTROY_TEAM_MEMBER,
  DESTROY_TEAM_MEMBER_SUCCESS,
  DESTROY_TEAM_MEMBER_ERROR,
} from './constants';

export function fetchTeamMembers(companyId = null) {
  return {
    companyId,
    type: FETCH_TEAM_MEMBERS,
  };
}

export function fetchTeamMembersSuccess(members) {
  return {
    members,
    type: FETCH_TEAM_MEMBERS_SUCCESS,
  };
}

export function fetchTeamMembersError(error) {
  return {
    error,
    type: FETCH_TEAM_MEMBERS_ERROR,
  };
}

export function createTeamMember(values, callback, companyId = null) {
  return {
    values,
    callback,
    companyId,
    type: CREATE_TEAM_MEMBER,
  };
}

export function createTeamMemberSuccess(member) {
  return {
    member,
    type: CREATE_TEAM_MEMBER_SUCCESS,
  };
}

export function createTeamMemberError(error) {
  return {
    error,
    type: CREATE_TEAM_MEMBER_ERROR,
  };
}

export function fetchTeamMember(id, callback, companyId = null) {
  return {
    id,
    callback,
    companyId,
    type: FETCH_TEAM_MEMBER,
  };
}

export function fetchTeamMemberSuccess(member) {
  return {
    member,
    type: FETCH_TEAM_MEMBER_SUCCESS,
  };
}

export function fetchTeamMemberError(error) {
  return {
    error,
    type: FETCH_TEAM_MEMBER_ERROR,
  };
}

export function updateTeamMember(id, values, callback, companyId = null) {
  return {
    id,
    values,
    callback,
    companyId,
    type: UPDATE_TEAM_MEMBER,
  };
}

export function updateTeamMemberSuccess(member) {
  return {
    member,
    type: UPDATE_TEAM_MEMBER_SUCCESS,
  };
}

export function updateTeamMemberError(error) {
  return {
    error,
    type: UPDATE_TEAM_MEMBER_ERROR,
  };
}

export function destroyTeamMember(id, companyId = null) {
  return {
    id,
    companyId,
    type: DESTROY_TEAM_MEMBER,
  };
}

export function destroyTeamMemberSuccess(member) {
  return {
    member,
    type: DESTROY_TEAM_MEMBER_SUCCESS,
  };
}

export function destroyTeamMemberError(error) {
  return {
    error,
    type: DESTROY_TEAM_MEMBER_ERROR,
  };
}
