export const reportTypeOptions = [
  { value: 'snapshot', label: 'Snapshot' },
  { value: 'access_small', label: 'Access (Small)' },
  { value: 'access_large', label: 'Access (Large)' },
  { value: 'access_small_plus', label: 'Access Plus (Small)' },
  { value: 'access_large_plus', label: 'Access Plus (Large)' },
  { value: 'efap_small', label: 'EFAP (Small)' },
  { value: 'efap_large', label: 'EFAP (Large)' },
  { value: 'summary_metrics', label: 'Summary Metrics' },
];

// The value basically represents how many months within the block.
// e.g 3 months in a quarter.
export const reportPeriods = [
  { value: 3, label: 'Quarter' },
  { value: 4, label: 'Semester' },
  { value: 1, label: 'Monthly' },
];
