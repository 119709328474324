import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import darkGreenCheck from '../../images/EapPage/dark-green-check.svg';
import fullSpectrum from '../../images/EapPage/spectrum.svg';
import messages from './messages';

export function SectionTwo() {
  return (
    <div className="section-two">
      <div className="left">
        <div className="full-spectrum">
          <img src={fullSpectrum} alt="Full spectrum" />
          <h2>
            <FormattedMessage {...messages.fullSpectrum} />
          </h2>
        </div>
        <FormattedHTMLMessage {...messages.onDemand} />
      </div>
      <div className="right">
        <h4>
          <FormattedMessage {...messages.lifesChallenges} />
        </h4>
        <div className="support-items">
          <div>
            <img src={darkGreenCheck} alt="Dark green check" />
            <FormattedHTMLMessage {...messages.fastEasy} />
          </div>
          <div>
            <img src={darkGreenCheck} alt="Dark green check" />
            <FormattedHTMLMessage {...messages.peerProgram} />
          </div>
          <div>
            <img src={darkGreenCheck} alt="Dark green check" />
            <FormattedHTMLMessage {...messages.advisoryServices} />
          </div>
          <div>
            <img src={darkGreenCheck} alt="Dark green check" />
            <FormattedHTMLMessage {...messages.careerCounselling} />
          </div>
        </div>
      </div>
    </div>
  );
}

SectionTwo.propTypes = {};
