/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ReactGA from 'react-ga';
import { createStructuredSelector } from 'reselect';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useInjectSaga } from 'utils/injectSaga';
import '@uik/lib/styles.scss';
import Layout from 'containers/Layout';
import GenerateReport from 'containers/GenerateReport';
import LandingPage from 'containers/LandingPage';
import EapPage from 'containers/EapPage';
import ThankYouPage from 'containers/ThankYouPage';
import TeamManagement from 'containers/TeamManagement';
import EmailReminders from 'containers/EmailReminders';
import SignIn from 'containers/SignIn';
import MyAccount from 'containers/MyAccount';
import ForgotPassword from 'containers/ForgotPassword';
import ImportExecution from 'containers/ImportExecution';
import ResetPassword from 'containers/ResetPassword';
import CompanyCreation from 'containers/CompanyCreation';
import PeopleCompanyCreation from 'containers/PeopleCompanyCreation';
import Administration from 'containers/Administration';
import Reporting from 'containers/Reporting';
import CompanyEdit from 'containers/CompanyEdit';
import queryString from 'query-string';
import GlobalStyles from './GlobalStyles';
import saga from './saga';
import { fetchCurrentUser } from './actions';
import { makeSelectCurrentUser } from './selectors';

function App(props) {
  useInjectSaga({ key: 'app', saga });

  const { ldClient } = props;

  const parseLocaleFromPath = () => {
    const params = queryString.parse(window.location.search);
    return params.locale;
  };

  const setLocale = () => {
    if (typeof window !== 'undefined') {
      const locale = parseLocaleFromPath();
      if (locale) {
        localStorage.setItem('locale', locale);
      }
    }
  };

  useEffect(() => {
    setLocale();
    props.fetchCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.currentUser.inkblot_admin_yn && ldClient) {
      const { currentUser } = props;
      const user = {
        key: currentUser.uuid,
        name: `${currentUser.first_name} ${currentUser.last_name}`,
        email: currentUser.email,
      };

      ldClient.identify(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser, ldClient]);

  const pageView =
    window &&
    window.location &&
    window.location.pathname + window.location.search;
  ReactGA.pageview(pageView);

  return (
    <GlobalStyles>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/forgot_password" component={ForgotPassword} />
        <Route exact path="/imports/:id" component={ImportExecution} />
        <Route
          path="/reset_password/:reset_password_token"
          component={ResetPassword}
        />
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/thankyou" component={ThankYouPage} />
        <Route exact path="/eap" component={EapPage} />
        <Layout excludeSidebar={pageView === '/create_company'}>
          <Route path="/my_account" component={MyAccount} />
          <Route exact path="/generate_report" component={GenerateReport} />
          <Route path="/team_management" component={TeamManagement} />
          <Route path="/email_reminders" component={EmailReminders} />
          <Route path="/reporting" component={Reporting} />
          <Route exact path="/create_company" component={CompanyCreation} />
          <Route
            exact
            path="/my_account/edit_company"
            component={CompanyEdit}
          />
          <Route
            exact
            path="/create_people_company"
            component={PeopleCompanyCreation}
          />
          <Route path="/administration" component={Administration} />
        </Layout>
      </Switch>
    </GlobalStyles>
  );
}
App.propTypes = {
  fetchCurrentUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  ldClient: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentUser: (callback) => dispatch(fetchCurrentUser(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withLDConsumer()(compose(withConnect)(App));
