import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import coffeeChat from '../../images/LandingPage/animations/coffeechat.svg';
import teamwork13 from '../../images/LandingPage/animations/teamwork-13.svg';
import team1 from '../../images/LandingPage/animations/team-1.svg';
import trauma from '../../images/LandingPage/animations/trauma.png';
import messages from './messages';

const moreServices = [
  {
    id: 1,
    image: coffeeChat,
    header: <FormattedMessage {...messages.extraServices} />,
    text: <FormattedMessage {...messages.helpReduce} />,
  },
  {
    id: 2,
    image: teamwork13,
    header: <FormattedMessage {...messages.clinicalCollab} />,
    text: <FormattedMessage {...messages.ourMedical} />,
  },
  {
    id: 3,
    image: team1,
    header: <FormattedMessage {...messages.peerSupport} />,
    text: <FormattedMessage {...messages.weTake} />,
  },
  {
    id: 4,
    image: trauma,
    header: <FormattedMessage {...messages.traumaServ} />,
    text: <FormattedMessage {...messages.inCase} />,
  },
];

export function SectionFive({ scrollToBottom }) {
  return (
    <div className="section-5">
      <div>
        <h2>
          <FormattedMessage {...messages.moreWays} />
        </h2>
        <div className="even-more-services">
          {moreServices.map((service) => (
            <div className="more-services" key={service.id}>
              <img
                src={service.image}
                style={{ width: '145px', height: '80px', marginBottom: '35px' }}
                alt="Your mental health partner"
              />
              <h4>{service.header}</h4>
              <p className="service-text">{service.text}</p>
            </div>
          ))}
        </div>
        <div className="center-button">
          <button
            className="landing-button"
            type="button"
            onClick={scrollToBottom}
          >
            <FormattedMessage {...messages.learnMore} />
          </button>
        </div>
      </div>
    </div>
  );
}

SectionFive.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
};
