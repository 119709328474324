import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import realSupport from '../../images/EapPage/eap-real-support.png';
import atbLogo from '../../images/EapPage/atb-logo.png';
import endyLogo from '../../images/EapPage/endy-logo.png';
import theWeatherNetworkLogo from '../../images/EapPage/weather-network-logo-clear.svg';
import leagueLogo from '../../images/EapPage/league-logo.svg';
import lightGreenCheck from '../../images/EapPage/light-green-check.svg';
import messages from './messages';

const organizations = [
  {
    id: 0,
    src: theWeatherNetworkLogo,
    alt: 'The Weather Network logo',
    width: '25%',
  },
  {
    id: 1,
    src: leagueLogo,
    alt: 'League logo',
    width: '13%',
  },
  {
    id: 2,
    src: atbLogo,
    alt: 'ATB logo',
    width: '13%',
  },
  {
    id: 3,
    src: endyLogo,
    alt: 'Endy logo',
    width: '13%',
  },
];

export function SectionOne({ scrollToBottom }) {
  return (
    <div className="section-one">
      <div className="container">
        <div className="text">
          <h2>
            <FormattedMessage {...messages.realSupport} />
          </h2>
          <FormattedHTMLMessage {...messages.yourEmployees} />
          <button className="eap-button" type="button" onClick={scrollToBottom}>
            <FormattedMessage {...messages.teamMember} />
          </button>
          <div className="trusted">
            <img src={lightGreenCheck} alt="Light green check" />
            <p>
              <FormattedMessage {...messages.trustedBy} />
            </p>
          </div>
          <div className="organizations">
            {organizations.map((org) => (
              <img
                key={org.id}
                src={org.src}
                alt={org.alt}
                style={{ height: 'auto', width: org.width }}
              />
            ))}
          </div>
        </div>
        <img
          className="main-image"
          src={realSupport}
          alt="Your mental health partner"
        />
      </div>
    </div>
  );
}

SectionOne.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
};
