/* eslint-disable camelcase */

/**
 *
 * TeamManagement
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Button, Icon, Modal } from 'antd';
import { makeSelectEmailReminders } from './selectors';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from '../App/selectors';
import { automateEmailReminders } from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import EmailReminderTable from './EmailReminderTable';
import { useHistory } from 'react-router-dom';

function EmailReminderList(props) {
  const history = useHistory();
  const {
    currentUser: { owner_yn, senior_admin_yn },
    currentCompanyId,
  } = props;
  const [automateModal, setAutomateModal] = useState(false);

  const handleOk = () => {
    props.automateEmailReminders(() => {
      setAutomateModal(false);
    }, currentCompanyId);
  };

  const handleCancel = () => {
    setAutomateModal(false);
  };
  let button;
  let automation;
  if (owner_yn || senior_admin_yn) {
    button = (
      <Button
        style={{ marginRight: 20 }}
        htmlType="submit"
        type="primary"
        onClick={() => history.push('/email_reminders/add_reminder')}
      >
        <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
        Add Reminder
      </Button>
    );
    automation = (
      <Button
        htmlType="submit"
        type="default"
        onClick={() => setAutomateModal(true)}
      >
        Automate
      </Button>
    );
  } else {
    button = <div />;
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">My Reminders</h3>
        <div className="button-group">
          {button}
          {automation}
        </div>
      </SubHeaderContainer>
      <EmailReminderTable
        currentUser={props.currentUser}
        reminders={props.reminders.data || []}
      />
      <Modal
        title="Automation"
        visible={automateModal}
        width={400}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Automating the email reminders <strong>WILL REMOVE ALL</strong> the
        current reminders and replace them with the standard reminder cadence.
      </Modal>
    </SubContainer>
  );
}

EmailReminderList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reminders: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
  automateEmailReminders: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  reminders: makeSelectEmailReminders(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    automateEmailReminders: (values, callback) =>
      dispatch(automateEmailReminders(values, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EmailReminderList);
