/*
 *
 * TeamManagement reducer
 *
 */
import produce from 'immer';
import {
  FETCH_TEAM_MEMBERS,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_ERROR,
  CREATE_TEAM_MEMBER,
  CREATE_TEAM_MEMBER_SUCCESS,
  CREATE_TEAM_MEMBER_ERROR,
  FETCH_TEAM_MEMBER,
  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_ERROR,
  UPDATE_TEAM_MEMBER,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_ERROR,
  DESTROY_TEAM_MEMBER_SUCCESS,
  DESTROY_TEAM_MEMBER,
  DESTROY_TEAM_MEMBER_ERROR,
} from './constants';

export const initialState = {
  teamMembers: {
    loading: false,
    error: false,
    data: false,
  },
  teamMember: {
    loading: false,
    error: false,
    data: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const teamManagementReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TEAM_MEMBERS:
        draft.teamMembers.loading = true;
        draft.teamMembers.error = false;
        draft.teamMembers.data = false;
        break;

      case FETCH_TEAM_MEMBERS_SUCCESS:
        draft.teamMembers.data = action.members;

        draft.teamMembers.loading = false;
        break;

      case FETCH_TEAM_MEMBERS_ERROR:
        draft.teamMembers.error = action.error;
        draft.teamMembers.loading = false;
        break;
      case DESTROY_TEAM_MEMBER:
      case CREATE_TEAM_MEMBER:
      case FETCH_TEAM_MEMBER:
      case UPDATE_TEAM_MEMBER:
        draft.teamMember.loading = true;
        draft.teamMember.error = false;
        draft.teamMember.data = false;
        break;

      case FETCH_TEAM_MEMBER_SUCCESS:
        draft.teamMember.data = action.member;
        draft.teamMember.loading = false;
        break;
      case UPDATE_TEAM_MEMBER_SUCCESS:
        draft.teamMembers.data.forEach((element, index) => {
          if (element.id === action.member.id) {
            draft.teamMembers.data[index] = action.member;
          }
        });
        draft.teamMember.data = action.member;
        draft.teamMember.loading = false;
        break;
      case CREATE_TEAM_MEMBER_SUCCESS:
        draft.teamMembers.data.push(action.member);
        draft.teamMember.loading = false;
        break;
      case DESTROY_TEAM_MEMBER_SUCCESS:
        draft.teamMembers.data = draft.teamMembers.data.filter((x) => {
          return x.id !== action.member.id;
        });
        draft.teamMember.data = action.member;
        draft.teamMember.loading = false;
        break;

      case CREATE_TEAM_MEMBER_ERROR:
      case DESTROY_TEAM_MEMBER_ERROR:
      case FETCH_TEAM_MEMBER_ERROR:
      case UPDATE_TEAM_MEMBER_ERROR:
        draft.teamMember.error = action.error;
        draft.teamMember.loading = false;
        break;
    }
  });

export default teamManagementReducer;
