/*
 *
 * MyAccount constants
 *
 */

export const FETCH_COMPANY = 'app/MyAccount/FETCH_COMPANY';
export const FETCH_COMPANY_ERROR = 'app/MyAccount/FETCH_COMPANY_ERROR';
export const FETCH_COMPANY_SUCCESS = 'app/MyAccount/FETCH_COMPANY_SUCCESS';

export const UPDATE_COMPANY = 'app/MyAccount/UPDATE_COMPANY';
export const UPDATE_COMPANY_ERROR = 'app/MyAccount/UPDATE_COMPANY_ERROR';
export const UPDATE_COMPANY_SUCCESS = 'app/MyAccount/UPDATE_COMPANY_SUCCESS';

export const UPLOAD_EMPLOYEE_CSV = 'app/AddCompany/UPLOAD_EMPLOYEE_CSV';
export const UPLOAD_EMPLOYEE_CSV_ERROR =
  'app/AddCompany/UPLOAD_EMPLOYEE_CSV_ERROR';
export const UPLOAD_EMPLOYEE_CSV_SUCCESS =
  'app/AddCompany/UPLOAD_EMPLOYEE_CSV_SUCCESS';

export const BACKFILL_PREVIEW = 'app/AddCompany/BACKFILL_PREVIEW';
export const BACKFILL_PREVIEW_ERROR = 'app/AddCompany/BACKFILL_PREVIEW_ERROR';
export const BACKFILL_PREVIEW_SUCCESS =
  'app/AddCompany/BACKFILL_PREVIEW_SUCCESS';

export const SAVE_PAYMENT_TYPE = 'app/AddCompany/SAVE_PAYMENT_TYPE';
export const SAVE_PAYMENT_TYPE_ERROR = 'app/AddCompany/SAVE_PAYMENT_TYPE_ERROR';
export const SAVE_PAYMENT_TYPE_SUCCESS =
  'app/AddCompany/SAVE_PAYMENT_TYPE_SUCCESS';

export const CREATE_EMPLOYEE = 'app/TeamManagement/CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_ERROR = 'app/TeamManagement/CREATE_EMPLOYEE_ERROR';
export const CREATE_EMPLOYEE_SUCCESS =
  'app/TeamManagement/CREATE_EMPLOYEE_SUCCESS';

export const DELETE_EMPLOYEE = 'app/TeamManagement/DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_ERROR = 'app/TeamManagement/DELETE_EMPLOYEE_ERROR';
export const DELETE_EMPLOYEE_SUCCESS =
  'app/TeamManagement/DELETE_EMPLOYEE_SUCCESS';

export const TOGGLE_DISABLE_COMPANY = 'app/MyAccount/TOGGLE_DISABLE_COMPANY';
export const TOGGLE_DISABLE_COMPANY_ERROR =
  'app/MyAccount/TOGGLE_DISABLE_COMPANY_ERROR';
export const TOGGLE_DISABLE_COMPANY_SUCCESS =
  'app/MyAccount/TOGGLE_DISABLE_COMPANY_SUCCESS';

export const FETCH_EMPLOYEE_LIST = 'app/MyAccount/FETCH_EMPLOYEE_LIST';
export const FETCH_EMPLOYEE_LIST_ERROR =
  'app/MyAccount/FETCH_EMPLOYEE_LIST_ERROR';
export const FETCH_EMPLOYEE_LIST_SUCCESS =
  'app/MyAccount/FETCH_EMPLOYEE_LIST_SUCCESS';

export const UPDATE_EMPLOYEE_LIST_PARAMS =
  'app/MyAccount/UPDATE_EMPLOYEE_LIST_PARAMS';

export const EXPORT_EMPLOYEE_LIST = 'app/MyAccount/EXPORT_EMPLOYEE_LIST';
export const EXPORT_EMPLOYEE_LIST_ERROR =
  'app/MyAccount/EXPORT_EMPLOYEE_LIST_ERROR';
export const EXPORT_EMPLOYEE_LIST_SUCCESS =
  'app/MyAccount/EXPORT_EMPLOYEE_LIST_SUCCESS';

export const FETCH_CUSTOM_FIELDS = 'app/MyAccount/FETCH_CUSTOM_FIELDS';
export const FETCH_CP_NAVIGATORS = 'app/MyAccount/FETCH_CP_NAVIGATORS';
export const FETCH_CUSTOM_FIELDS_SUCCESS =
  'app/MyAccount/FETCH_CUSTOM_FIELDS_SUCCESS';

export const EMPLOYEE_STANDARD_FIELDS = [
  'id',
  'first_name',
  'last_name',
  'email',
  'dob',
  'gender',
  'start_date',
  'end_date',
];
