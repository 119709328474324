import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import messages from './messages';

export function NavbarOne() {
  const [lang, setLang] = useState(localStorage.getItem('locale'));
  function setLanguage(e) {
    setLang(e.target.value);
    localStorage.setItem('locale', e.target.value);
    window.location.reload();
  }
  return (
    <div className="nav-one">
      <div className="left">
        <FormattedHTMLMessage {...messages.haveQuestion} />
      </div>
      <div className="right">
        <FormattedHTMLMessage {...messages.inkblotTherapy} />
        <select value={lang} onChange={setLanguage}>
          <option value="en">EN</option>
          <option value="fr">FR</option>
        </select>
      </div>
    </div>
  );
}
