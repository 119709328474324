import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import teamwork5 from '../../images/LandingPage/animations/teamwork-white-5.svg';
import messages from './messages';

export function SectionOne({ scrollToBottom }) {
  return (
    <div className="section-1">
      <div>
        <div className="section-1-text">
          <h2>
            <FormattedMessage {...messages.yourPartner} />
          </h2>
          <p>
            <FormattedMessage {...messages.success} />
          </p>
          <button
            className="landing-button"
            type="button"
            onClick={scrollToBottom}
          >
            <FormattedMessage {...messages.learnMore} />
          </button>
        </div>
        <img src={teamwork5} alt="Your mental health partner" />
      </div>
    </div>
  );
}

SectionOne.propTypes = {
  scrollToBottom: PropTypes.func.isRequired,
};
