/* eslint-disable camelcase */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import { Button, Icon } from 'antd';
import { Link } from 'react-router-dom';
import AntInput from 'components/AntInput';
import { required, validateEmail } from '../../utils/validation';
import { API_URL } from '../../utils/environment';

const ForgotPassword = (props) => {
  const [emailSent, setEmailSent] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { handleSubmit } = props;

  const onSubmit = (values) => {
    axios.post(`${API_URL}/reset_passwords`, { ...values }).then(() => {
      setEmailSent(true);
      setButtonDisabled(true);
      setTimeout(() => setButtonDisabled(false), 5000);
    });
  };

  return (
    <div className="signin-container">
      <div className="sign-in-box">
        <h2>Forgot Password</h2>
        <p>
          Already have a passsword? <Link to="signin">Sign In</Link>
        </p>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
          <Field
            name="email"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            label="E-MAIL ADDRESS"
            placeholder="your@email.com"
            component={AntInput}
            style={{ width: '100%' }}
            validate={[required, validateEmail]}
          />
          {emailSent && (
            <p style={{ marginTop: '20px', marginBottom: 0 }}>
              We will send password reset instructions to you shortly.
            </p>
          )}
          <Button
            style={{ marginTop: '40px' }}
            type="primary"
            htmlType="submit"
            block
            disabled={buttonDisabled}
          >
            Send Password Reset
          </Button>
        </form>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'ForgotPasswordForm',
  })(ForgotPassword),
);
