import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import openhands from '../../images/EapPage/openhands.svg';
import messages from './messages';

export function SectionSix() {
  return (
    <div className="section-six">
      <img src={openhands} alt="Easy to use" />
      <FormattedHTMLMessage {...messages.easyUse} />
      <FormattedHTMLMessage {...messages.trulyEffective} />
    </div>
  );
}

SectionSix.propTypes = {};
