import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import TextData from './TextData';
import LogoData from './LogoData';
import Container from './Container';
import { safe, safeObj } from './helpers';
import Address from './Address';

const Basics = ({ company, toggle, currentUser }) => {
  return (
    <>
      <Container>
        <TextData tag="Company Name" data={safe(company, 'name')} />
        <TextData tag="Alias Name" data={safe(company, 'alias')} />
        <TextData tag="Company ID" data={safe(company, 'id')} />
      </Container>
      <Container>
        <TextData
          tag="Customized Inkblot URL"
          data={safe(company, 'customized_url')}
          width="100%"
        />
        <TextData
          tag="Company Code"
          data={safe(company.company_info, 'company_code')}
          width="100%"
        />
      </Container>
      {company.company_logos?.length > 0 && (
        <>
          <h3>Company logos</h3>
          <Container>
            {company.company_logos.map((logo) => (
              <LogoData key={logo.id} url={logo.url} type={logo.type_of} />
            ))}
          </Container>
        </>
      )}
      {company.reseller_logos?.length > 0 && (
        <>
          <h3>Reseller logos</h3>
          <Container>
            {company.reseller_logos.map((logo) => (
              <LogoData key={logo.id} url={logo.url} type={logo.type_of} />
            ))}
          </Container>
        </>
      )}

      {currentUser.inkblot_admin_yn && (
        <>
          <Container>
            <Address
              tag="Office Address"
              data={safeObj(company, 'office_address')}
            />
            <Address
              tag="Mailing Address"
              data={safeObj(company, 'mailing_address')}
            />
          </Container>
          <Button onClick={toggle} type="default">
            Edit Basic Information
          </Button>
        </>
      )}
    </>
  );
};

Basics.propTypes = {
  company: PropTypes.object,
  toggle: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default Basics;
