import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import '@uik/lib/styles.scss';
import styled from 'styled-components';
const { Option } = Select;

const DropdownStyles = styled.div`
  .uik-select__wrapper {
    max-width: ${(props) => props.width};
    width: 50vw;
    span {
      text-transform: none !important;
    }
  }
`;

function CsvDropdownField({
  label,
  options,
  placeholder,
  width,
  defaultValue,
  onChange,
  key,
}) {
  return (
    <DropdownStyles width={width}>
      <p>{label} is:</p>
      <Select
        defaultValue={[defaultValue]}
        placeholder={placeholder}
        className="minimize-label"
        onChange={onChange}
        key={key}
        style={{ width }}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
    </DropdownStyles>
  );
}

CsvDropdownField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  width: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CsvDropdownField;
