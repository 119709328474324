/* eslint-disable camelcase */

/**
 *
 * TeamManagement
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { Button, Icon } from 'antd';
import { makeSelectTeamMembers } from './selectors';
import { makeSelectCurrentUser } from '../App/selectors';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import TeamMemberTable from './TeamMemberTable';
import { useHistory } from 'react-router-dom';

function TeamList(props) {
  const {
    currentUser: { owner_yn, senior_admin_yn },
  } = props;
  const history = useHistory();
  let button;

  if (owner_yn || senior_admin_yn) {
    button = (
      <Button
        htmlType="submit"
        type="primary"
        onClick={() => history.push('/team_management/add_admin')}
      >
        <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
        Add Admin
      </Button>
    );
  } else {
    button = <div />;
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">My Team</h3>
        {button}
      </SubHeaderContainer>
      <TeamMemberTable
        currentUser={props.currentUser}
        members={props.teamMembers.data || []}
      />
    </SubContainer>
  );
}

TeamList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  teamMembers: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  teamMembers: makeSelectTeamMembers(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TeamList);
