import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import _ from 'lodash';
import { Button, Table, Pagination, Modal, Icon, Select, Input } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';

import {
  makeSelectCurrentCompanyId,
  makeSelectCurrentUser,
} from 'containers/App/selectors';
import Label from 'components/Label';

import {
  deleteEmployee,
  exportEmployeeList,
  fetchEmployeeList,
  updateEmployeeListParams,
} from '../actions';
import { makeSelectEmployeeList } from '../selectors';
import RemoveEmployeeModal from './RemoveEmployeeModal';
import ExportEmployeeListModal from './ExportEmployeeListModal';

const DATE_FORMAT = 'DD/MM/YYYY';
const FORM_NAME = 'EmployeeListForm';

const Container = styled.div`
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

function EmployeeList({
  change,
  currentCompanyId,
  currentUser,
  dispatch,
  employeeList,
  handleSubmit,
  reset,
  ...props
}) {
  let columns = [];

  columns = [
    {
      title: 'ID',
      dataIndex: 'employee_id',
      key: 'employee_id',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Start date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (date) => (date ? moment(date).format(DATE_FORMAT) : ''),
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (date) => (date ? moment(date).format(DATE_FORMAT) : ''),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text, record) => {
        if (record.status === 'Active')
          return (
            <Button
              onClick={() => {
                setEmployee(record);
                setRemoveModalOpen(true);
              }}
              type="danger"
            >
              <Icon type="minus-circle" style={{ verticalAlign: 0 }} />
              Remove
            </Button>
          );

        return <div />;
      },
    },
  ];

  if (currentUser.inkblot_admin_yn) {
    columns.unshift({
      title: 'User ID',
      dataIndex: 'user_id',
      key: 'user_id',
    });
  }

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState('active');
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');
  const [employee, setEmployee] = useState(null);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const employees = _.get(employeeList, 'data.users', []);
  const pagination = _.get(employeeList, 'data.meta', {});

  useEffect(() => {
    const params = { currentCompanyId, page, status, query };

    props.updateEmployeeListParams(params);
    props.fetchEmployeeList(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCompanyId, page, status, query]);

  // Reset the current page to the last page if the current page has gone out of bounds
  useEffect(() => {
    if (pagination.total_pages < page) {
      setPage(pagination.total_pages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  // Set the query to the search value only after the user has stopped typing for 300ms
  useEffect(() => {
    const debounce = setTimeout(() => {
      setQuery(searchValue);
    }, 300);

    return () => clearTimeout(debounce);
  }, [searchValue]);

  function handleCancel() {
    setEmployee(null);
    setRemoveModalOpen(false);
    setExportModalOpen(false);
    dispatch(reset(FORM_NAME));
  }

  function handleChange(field, value) {
    change(field, value);
  }

  function onSubmitExport(values) {
    const params = { currentCompanyId, employeeType: values.employee_type };

    props.exportEmployeeList(params, (error) => {
      setExportModalOpen(false);
      dispatch(reset(FORM_NAME));

      if (!error) {
        Modal.success({
          content: (
            <p>
              The list is being generated and will be sent to{' '}
              <b>{currentUser.email}</b> when it is complete.
            </p>
          ),
        });
      } else {
        Modal.error({
          title: 'Error Exporting Employee List',
          content: error.data.error,
          onOk: () => setExportModalOpen(true),
        });
      }
    });
  }

  function onSubmitRemove(values) {
    const params = {
      company_id: currentCompanyId,
      user_company_id: employee.user_company_id,
    };

    if (values.end_date && values.end_date.isValid()) {
      _.assign(params, { end_date: values.end_date.format() });
    }

    props.deleteEmployee(params, (_response, error) => {
      setRemoveModalOpen(false);
      dispatch(reset(FORM_NAME));

      if (!error) {
        props.fetchEmployeeList({ currentCompanyId, page, status, query });
      } else {
        Modal.error({
          title: `Error Removing ${employee.full_name}`,
          content: error.data.error,
        });
      }

      setEmployee(null);
    });
  }

  return (
    <Container>
      <h3 className="sub-header">Employee List</h3>
      <div
        style={{
          display: 'flex',
          marginBottom: '40px',
          alignItems: 'flex-end',
        }}
      >
        <div style={{ marginRight: '30px' }}>
          <Label>filter</Label>
          <Select
            onChange={(newStatus) => setStatus(newStatus)}
            style={{ width: '180px' }}
            value={status}
          >
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="removed">Removed</Select.Option>
            <Select.Option value="all">All</Select.Option>
          </Select>
        </div>
        <div>
          <Label>search</Label>
          <Input
            allowClear
            style={{ width: '180px' }}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Button onClick={() => setExportModalOpen(true)} type="default">
            <Icon type="export" style={{ verticalAlign: 0 }} />
            Export
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={employees}
        loading={employeeList.loading}
        pagination={false}
      />
      <br />
      <Pagination
        current={pagination.current_page}
        onChange={(pageNumber) => setPage(pageNumber)}
        pageSize={20}
        total={pagination.total_count}
      />
      <RemoveEmployeeModal
        dateFormat={DATE_FORMAT}
        employeeName={_.get(employee, 'full_name', '')}
        handleCancel={handleCancel}
        handleChange={handleChange}
        onSubmit={handleSubmit(onSubmitRemove)}
        removeModalOpen={removeModalOpen}
      />
      <ExportEmployeeListModal
        exportModalOpen={exportModalOpen}
        handleCancel={handleCancel}
        onSubmit={handleSubmit(onSubmitExport)}
      />
    </Container>
  );
}

EmployeeList.propTypes = {
  change: PropTypes.func.isRequired,
  currentCompanyId: PropTypes.number,
  currentUser: PropTypes.object.isRequired,
  deleteEmployee: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  employeeList: PropTypes.object.isRequired,
  exportEmployeeList: PropTypes.func.isRequired,
  fetchEmployeeList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  updateEmployeeListParams: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentCompanyId: makeSelectCurrentCompanyId(),
  currentUser: makeSelectCurrentUser(),
  employeeList: makeSelectEmployeeList(),
});

function mapDispatchToProps(dispatch) {
  return {
    deleteEmployee: (values, callback) =>
      dispatch(deleteEmployee(values, callback)),
    fetchEmployeeList: (values, callback) =>
      dispatch(fetchEmployeeList(values, callback)),
    updateEmployeeListParams: (values) =>
      dispatch(updateEmployeeListParams(values)),
    exportEmployeeList: (values, callback) =>
      dispatch(exportEmployeeList(values, callback)),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: FORM_NAME,
  })(EmployeeList),
);
