/*
 * Landing Page Messages
 *
 * This contains all the text for the Landing Page.
 */
import { defineMessages } from 'react-intl';

export const scope = 'marchel.containers.landingPage';

export default defineMessages({
  yourPartner: {
    id: `${scope}.yourPartner`,
    defaultMessage: 'Your Workplace Mental Health Partner',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage:
      'The success of your business depends on the well-being of your people. Inkblot provides mental health solutions with strategies and resources built for your organization.',
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: 'Learn More',
  },
  isComplicated: {
    tagName: 'span',
    id: `${scope}.isComplicated`,
    defaultMessage:
      'The workplace mental health landscape is <span class="h2-span"> complicated. </span>',
  },
  mostEmployee: {
    id: `${scope}.mostEmployee`,
    defaultMessage:
      "Most employees don't know what their EAP is or how to get the most out of it. Inkblot offers expert support tools and resources for your people. We get them matched quickly with the ability to choose the specific care they need — no more long waits to speak to a randomly selected therapist, no more bandaid solutions. We make it easy to help your employees feel their best",
  },
  inkblotSolution: {
    tagName: 'h1',
    id: `${scope}.inkblotSolution`,
    defaultMessage: 'The Inkblot <span>Solution</span>',
  },
  confidentialCounselling: {
    id: `${scope}.confidentialCounselling`,
    defaultMessage: 'Confidential Video Counselling',
  },
  consistency: {
    id: `${scope}.consistency`,
    defaultMessage:
      'Consistency is essential to effective mental health solutions. Employees connect with their preferred practitioner using secure video any time, any place. We help match your people based on symptoms, stressors, language, therapists who share their culture and values and more.',
  },
  completeSurvey: {
    id: `${scope}.completeSurvey`,
    defaultMessage: 'Complete our matching survey.',
  },
  bookConsult: {
    id: `${scope}.bookConsult`,
    defaultMessage:
      'Book a complimentary consultation with your best-suited practitioner.',
  },
  secureVideo: {
    id: `${scope}.secureVideo`,
    defaultMessage: 'Schedule your first therapy session using secure video.',
  },
  betterCare: {
    id: `${scope}.betterCare`,
    defaultMessage: 'Begin your journey to better care.',
  },
  multipleLarge: {
    tagName: 'h5',
    id: `${scope}.multipleLarge`,
    defaultMessage:
      "<span class='greenspan'>Multiple large trials</span> prove that video counselling is as effective and, at times, more effective than face-to-face counselling.",
  },
  inkblotAssess: {
    id: `${scope}.inkblotAssess`,
    defaultMessage: 'Inkblot Assess',
  },
  ourExpert: {
    id: `${scope}.ourExpert`,
    defaultMessage:
      'Our expert assessment tool evaluates individual and workplace psychological health.',
  },
  employeeObtain: {
    id: `${scope}.employeeObtain`,
    defaultMessage:
      'Employees obtain a personalized report on their own psychological health, while employers obtain benchmarked insights into their organization’s psychological health and culture.',
  },
  reportGen: {
    tagName: 'h5',
    id: `${scope}.reportGen`,
    defaultMessage:
      "<span class='bluespan'>Over 85%</span> of employees say the personalized report generated upon completion is helpful.",
  },
  comprehensiveReporting: {
    id: `${scope}.comprehensiveReporting`,
    defaultMessage: 'Comprehensive Reporting',
  },
  learnAbout: {
    id: `${scope}.learnAbout`,
    defaultMessage:
      'Learn about employee health with customized reports and analytics captured during the assessments, registration and matching process, plus before and after a session. Employers can access employee health data anytime they need it.',
  },
  employeeFeel: {
    tagName: 'h5',
    id: `${scope}.employeeFeel`,
    defaultMessage:
      'Employees report a <span class="pinkspan">30% improvement</span> in mental health symptoms after just two sessions.',
  },
  moreWays: {
    id: `${scope}.moreWays`,
    defaultMessage: 'More ways Inkblot can help',
  },
  extraServices: {
    id: `${scope}.extraServices`,
    defaultMessage: 'Advisory & Coaching Services',
  },
  helpReduce: {
    id: `${scope}.helpReduce`,
    defaultMessage:
      'Help reduce everyday stresses employees face with access to a full suite of advisory services, including legal and financial support, plus career and health consultations.',
  },
  clinicalCollab: {
    id: `${scope}.clinicalCollab`,
    defaultMessage: 'Clinically Collaborative Care',
  },
  ourMedical: {
    id: `${scope}.ourMedical`,
    defaultMessage:
      'Our medical and counselling teams work together to provide employees with physicians that can start or change medications based on a diagnosis.',
  },
  peerSupport: {
    id: `${scope}.peerSupport`,
    defaultMessage: 'Peer Support',
  },
  weTake: {
    id: `${scope}.weTake`,
    defaultMessage:
      'We take an innovative approach to peer support within organizations. Create a healthier workplace with long-term online support and training that helps to reduce stigma.',
  },
  traumaServ: {
    id: `${scope}.traumaServ`,
    defaultMessage: 'Trauma Services',
  },
  inCase: {
    id: `${scope}.inCase`,
    defaultMessage:
      'In case of a critical incident, we provide 24/7 access to in-person, virtual and telephonic assistance, including management consultations, psychological first aid with access to trauma defusing and debriefing and individual trauma treatment for complex cases of trauma-related mental health symptoms.',
  },
  oneSize: {
    id: `${scope}.oneSize`,
    defaultMessage: 'You’ve got options.',
  },
  ourMental: {
    id: `${scope}.ourMental`,
    defaultMessage:
      'A successful mental health partner provides choices. Find the package that best aligns with your mental health and wellness strategy.',
  },
  atLeague: {
    tagName: 'p',
    id: `${scope}.atLeague`,
    defaultMessage:
      '"As our mental health partner, Inkblot has helped make sure that our employers in both Canada and the USA are always supported. <span> Inkblot is professional, responsive, flexible and provides a full scope of mental health services </span> to our employees digitally to ensure that experts are available when and where they need them."',
  },
  kimTabac: {
    id: `${scope}.kimTabac`,
    defaultMessage: '- Kim Tabac, Chief People Officer, League',
  },
  makeEasy: {
    tagName: 'p',
    id: `${scope}.makeEasy`,
    defaultMessage:
      '"Inkblot makes it easy for our team members to access custom-tailored counselling services wherever and whenever they want. This past year has shown how important mental health is at our organization, and Inkblot has been an excellent partner in adapting to our changing needs."',
  },
  kerileeSnatchen: {
    id: `${scope}.kerileeSnatchen`,
    defaultMessage: '- Kerliee Snatchen, Director People and Culture, ATB',
  },
  rewriting: {
    tagName: 'p',
    id: `${scope}.rewriting`,
    defaultMessage:
      '"Inkblot is rewriting the playbook on Employee Assistance Programs. They care about creating a positive experience at a time when employees need empathy and compassion. They care about building trust. They have over-delivered in every aspect of our partnership, and we haven\'t looked back."',
  },
  annaPetosa: {
    id: `${scope}.annaPetosa`,
    defaultMessage: '- Anna Petosa, VP, People Ops, Pelmorex',
  },
  whyChoose: {
    id: `${scope}.whyChoose`,
    defaultMessage: 'Why choose Inkblot?',
  },
  recognizeComplexities: {
    id: `${scope}.recognizeComplexities`,
    defaultMessage:
      'We recognize the complexities of workplace mental health programs.',
  },
  canAddress: {
    id: `${scope}.canAddress`,
    defaultMessage: 'We can address your organization’s unique needs.',
  },
  wereAdvocates: {
    id: `${scope}.wereAdvocates`,
    defaultMessage: 'We are advocates for mental health - just like you.',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact Us',
  },
  ourTeam: {
    id: `${scope}.ourTeam`,
    defaultMessage:
      'Our team is ready to help build your unique employee mental health program. Reach out below to get started today!',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'NAME',
  },
  orgName: {
    id: `${scope}.orgName`,
    defaultMessage: 'ORGANIZATION NAME',
  },
  orgSize: {
    id: `${scope}.orgSize`,
    defaultMessage: 'ORGANIZATION SIZE',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'EMAIL',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'PHONE NUMBER',
  },
  message: {
    id: `${scope}.message`,
    defaultMessage: 'MESSAGE (OPTIONAL)',
  },
  hearAbout: {
    id: `${scope}.hearAbout`,
    defaultMessage: 'How did you hear about us?',
  },
  social: {
    id: `${scope}.social`,
    defaultMessage: 'Social Post',
  },
  digital: {
    id: `${scope}.digital`,
    defaultMessage: 'Digital Ad',
  },
  friend: {
    id: `${scope}.friend`,
    defaultMessage: 'Colleague/Friend',
  },
  panel: {
    id: `${scope}.panel`,
    defaultMessage: 'Panel/Conference',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  getStarted: {
    id: `${scope}.getStarted`,
    defaultMessage: 'Get Started',
  },
  weValue: {
    tagName: 'span',
    id: `${scope}.weValue`,
    defaultMessage:
      'We value your commitment to your mental health plan and will respond to your message <span> within 48 business hours.</span>',
  },
  helpCentre: {
    id: `${scope}.helpCentre`,
    defaultMessage: 'Help Centre',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  privacy: {
    id: `${scope}.privacy`,
    defaultMessage: 'Privacy Policy ',
  },
  termsAndConditions: {
    id: `${scope}.termsAndConditions`,
    defaultMessage: 'Terms & Conditions ',
  },
  contactAt: {
    tagName: 'p',
    id: `${scope}.contactAt`,
    defaultMessage:
      "Contact us at <a href='mailto:support@inkblottherapy.com'> support@inkblottherapy.com </a> with any questions.",
  },
  forPress: {
    tagName: 'div',
    id: `${scope}.forPress`,
    defaultMessage:
      "For press inquiries, please contact us <a href='mailto:media@inkblottherapy.com'>here.</a>",
  },
  caption: {
    id: `${scope}.caption`,
    defaultMessage: '© 2021 INKBLOT TECHNOLOGIES INC.',
  },
});
