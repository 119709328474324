export const canadianProvinces = [
  {
    value: 1,
    label: 'Alberta',
  },
  {
    value: 2,
    label: 'British Columbia',
  },
  {
    value: 3,
    label: 'Manitoba',
  },
  {
    value: 4,
    label: 'New Brunswick',
  },
  {
    value: 5,
    label: 'Newfoundland and Labrador',
  },
  {
    value: 6,
    label: 'Northwest Territories',
  },
  {
    value: 7,
    label: 'Nova Scotia',
  },
  {
    value: 8,
    label: 'Nunavut',
  },
  {
    value: 9,
    label: 'Ontario',
  },
  {
    value: 10,
    label: 'Prince Edward Island',
  },
  {
    value: 11,
    label: 'Quebec',
  },
  {
    value: 12,
    label: 'Saskatchewan',
  },
  {
    value: 13,
    label: 'Yukon Territory',
  },
];
