/**
 *
 * ThankYouPage
 *
 */

import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet-async';

import 'styles/containers/landing.scss';
import 'styles/containers/eap.scss';

import { NavbarOne } from '../../pagePartials/EapPage/Navbar1';
import { NavbarTwo } from '../../pagePartials/EapPage/Navbar2';
import { MobileNavOverlay } from '../../pagePartials/EapPage/MobileNavOverlay';
import { SectionOne } from '../../pagePartials/ThankYouPage/Section1';
import Footer from '../../components/EapFooter';

export function ThankYouPage() {
  const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Inkblot for Business - Your Workplace Mental Health Partner
        </title>
        <meta
          name="description"
          content="Inkblot offers easy and effective mental health support for businesses. Help your team perform at their best with fast access to best-in-class counsellors."
        />
      </Helmet>
      <IntlProvider locale="en">
        <div className="eap landing">
          <NavbarOne />
          <NavbarTwo setShowOverlay={setShowOverlay} />
          <MobileNavOverlay
            height={showOverlay ? '100%' : '0%'}
            setShowOverlay={setShowOverlay}
          />
          <SectionOne />
          <Footer />
        </div>
      </IntlProvider>
    </div>
  );
}

ThankYouPage.propTypes = {};

export default ThankYouPage;
