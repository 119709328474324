import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get, post } from 'utils/request';

import {
  FETCH_PEOPLE_COMPANIES,
  CREATE_PEOPLE_COMPANY,
  UPLOAD_PEOPLE_EMPLOYEE_CSV,
} from './constants';
import {
  fetchCompaniesSuccess,
  fetchCompaniesError,
  createCompanySuccess,
  createCompanyError,
  uploadEmployeeCsvSuccess,
  uploadEmployeeCsvError,
} from './actions';

function* fetchCompanies() {
  const requestUrl = `${API_URL}/companies?resellers=true`;

  try {
    const companies = yield call(get, requestUrl);
    yield reduxPut(fetchCompaniesSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchCompaniesError(error));
  }
}

function* createCompany({ values, callback }) {
  const requestUrl = `${API_URL}/people_companies`;

  try {
    const comp = yield call(post, requestUrl, values);
    yield reduxPut(createCompanySuccess(comp));
    if (callback) callback(comp);
  } catch (error) {
    yield reduxPut(createCompanyError(error));
  }
}

function* uploadEmployeeCsv({ values, callback }) {
  const requestUrl = `${API_URL}/companies/upload_csv`;

  try {
    const success = yield call(post, requestUrl, values);
    yield reduxPut(uploadEmployeeCsvSuccess(success));

    if (callback) callback(success);
  } catch (error) {
    yield reduxPut(uploadEmployeeCsvError(error));
  }
}

export default function* peopleCompanyCreationSaga() {
  yield takeLatest(FETCH_PEOPLE_COMPANIES, fetchCompanies);
  yield takeLatest(UPLOAD_PEOPLE_EMPLOYEE_CSV, uploadEmployeeCsv);
  yield takeLatest(CREATE_PEOPLE_COMPANY, createCompany);
}
