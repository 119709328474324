import React from 'react';

import canadianTireLogo from '../../images/LandingPage/logos/canadian-tire.png';
import endyLogo from '../../images/LandingPage/logos/endy-logo.png';
import leagueLogo from '../../images/LandingPage/logos/league-logo-horizontal.svg';
import pelmorexLogo from '../../images/LandingPage/logos/pelmorex-logo.png';
import rexallLogo from '../../images/LandingPage/logos/rexall-logo.png';

const organizations = [
  {
    id: 0,
    src: leagueLogo,
    alt: 'League logo',
    width: '7%',
  },
  {
    id: 1,
    src: pelmorexLogo,
    alt: 'Pelmorex Logo',
    width: '7%',
  },
  {
    id: 2,
    src: rexallLogo,
    alt: 'Rexall Logo',
    width: '6%',
  },
  {
    id: 3,
    src: endyLogo,
    alt: 'Endy logo',
    width: '5%',
  },
  {
    id: 4,
    src: canadianTireLogo,
    alt: 'Canadian Tire logo',
    width: '5%',
  },
];

export function SectionTwo() {
  return (
    <div className="section-2">
      {organizations.map((org) => (
        <img
          className="logos"
          key={org.id}
          src={org.src}
          alt={org.alt}
          style={{ height: 'auto', width: org.width }}
        />
      ))}
    </div>
  );
}
