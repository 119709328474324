import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import simpleonboarding from '../../images/EapPage/simpleonboarding.png';
import messages from './messages';

export function SectionSeven() {
  return (
    <div className="section-seven">
      <img src={simpleonboarding} alt="Onboarding made simple" />
      <div className="text">
        <h2>
          <FormattedMessage {...messages.simpleOnboarding} />
        </h2>
        <p>
          <FormattedMessage {...messages.secureSeamless} />
        </p>
        <ul>
          <FormattedHTMLMessage {...messages.customizedImplementation} />
          <FormattedHTMLMessage {...messages.neededUsed} />
          <FormattedHTMLMessage {...messages.employeeTraining} />
        </ul>
      </div>
    </div>
  );
}

SectionSeven.propTypes = {};
