/*
 *
 * TeamManagement constants
 *
 */

export const userTypeOptions = (ownerYn) => {
  if (ownerYn) {
    return [
      {
        value: 2,
        label: 'Admin',
      },
      {
        value: 1,
        label: 'Senior Admin',
      },
    ];
  }
  return [
    {
      value: 2,
      label: 'Admin',
    },
  ];
};

export const FETCH_TEAM_MEMBERS = 'app/TeamManagement/FETCH_TEAM_MEMBERS';
export const FETCH_TEAM_MEMBERS_ERROR =
  'app/TeamManagement/FETCH_TEAM_MEMBERS_ERROR';
export const FETCH_TEAM_MEMBERS_SUCCESS =
  'app/TeamManagement/FETCH_TEAM_MEMBERS_SUCCESS';

export const CREATE_TEAM_MEMBER = 'app/TeamManagement/CREATE_TEAM_MEMBER';
export const CREATE_TEAM_MEMBER_ERROR =
  'app/TeamManagement/CREATE_TEAM_MEMBER_ERROR';
export const CREATE_TEAM_MEMBER_SUCCESS =
  'app/TeamManagement/CREATE_TEAM_MEMBER_SUCCESS';

export const FETCH_TEAM_MEMBER = 'app/TeamManagement/FETCH_TEAM_MEMBER';
export const FETCH_TEAM_MEMBER_ERROR =
  'app/TeamManagement/FETCH_TEAM_MEMBER_ERROR';
export const FETCH_TEAM_MEMBER_SUCCESS =
  'app/TeamManagement/FETCH_TEAM_MEMBER_SUCCESS';

export const UPDATE_TEAM_MEMBER = 'app/TeamManagement/UPDATE_TEAM_MEMBER';
export const UPDATE_TEAM_MEMBER_ERROR =
  'app/TeamManagement/UPDATE_TEAM_MEMBER_ERROR';
export const UPDATE_TEAM_MEMBER_SUCCESS =
  'app/TeamManagement/UPDATE_TEAM_MEMBER_SUCCESS';

export const DESTROY_TEAM_MEMBER = 'app/TeamManagement/DESTROY_TEAM_MEMBER';
export const DESTROY_TEAM_MEMBER_ERROR =
  'app/TeamManagement/DESTROY_TEAM_MEMBER_ERROR';
export const DESTROY_TEAM_MEMBER_SUCCESS =
  'app/TeamManagement/DESTROY_TEAM_MEMBER_SUCCESS';
