import React from 'react';
import PropTypes from 'prop-types';
import { UikCheckbox } from '@uik/lib';

import '@uik/lib/styles.scss';

function CheckboxField({ disabled, input, label, onClick }) {
  return (
    <UikCheckbox
      defaultChecked={input.value}
      label={label}
      {...input}
      onClick={onClick}
      disabled={disabled}
    />
  );
}

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CheckboxField;
