/**
 *
 * ValidationText
 *
 */

import PropTypes from 'prop-types';
import styled from 'styled-components';

const ValidationText = styled.p`
  color: red;
  display: block;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: ${({ style }) => style.marginTop || '0'};
`;

ValidationText.propTypes = {
  style: PropTypes.object,
};

ValidationText.defaultProps = {
  style: {},
};

export default ValidationText;
