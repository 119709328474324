/* eslint-disable camelcase */
/**
 *
 * TeamManagement
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { Button, Icon } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import { userTypeOptions } from './constants';
import { fetchTeamMember, updateTeamMember } from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import DescriptionPopup from './DescriptionPopup';
import { makeSelectTeamMember } from './selectors';
import { useHistory } from 'react-router-dom';

const required = (value) => (value ? undefined : 'Required');

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

export function ModifyEmployee(props) {
  const {
    currentCompanyId,
    currentUser: { owner_yn, senior_admin_yn },
  } = props;

  const history = useHistory();

  useEffect(() => {
    props.fetchTeamMember(
      props.match.params.id,
      (member, error) => {
        if (!error) {
          props.change('first_name', member.first_name);
          props.change('last_name', member.last_name);
          props.change('email', member.email);
          props.change('user_type', member.senior_admin_yn ? 1 : 2);
        }
      },
      currentCompanyId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const onSubmit = (values) => {
    props.updateTeamMember(
      props.match.params.id,
      values,
      (_member, error) => {
        history.push('/team_management');
        if (!error) {
          history.push('/team_management');
        }
      },
      currentCompanyId,
    );
  };

  // if you are only admin, you cant be on this page
  if (!owner_yn && !senior_admin_yn) {
    history.push('/team_management');
  }

  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">Modify Admin</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="first_name"
            label="FIRST NAME"
            component={AntInput}
            validate={[required]}
          />
          <Field
            name="last_name"
            label="LAST NAME"
            component={AntInput}
            validate={[required]}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="email"
            label="ACCOUNT EMAIL"
            component={AntInput}
            validate={[required]}
            disabled
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="user_type"
            label="USER TYPE"
            width="180px"
            component={AntSelect}
            options={userTypeOptions(owner_yn)}
            validate={[required]}
          />
          <DescriptionPopup />
        </FormRowContainer>
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.teamMember.error &&
              ((props.teamMember.error.data &&
                props.teamMember.error.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        <ButtonContainer>
          <Button
            htmlType="submit"
            type="primary"
            loading={props.teamMember.loading}
          >
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Modify
          </Button>
          <Button
            type="danger"
            onClick={() => history.push('/team_management')}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </SubContainer>
  );
}

ModifyEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchTeamMember: PropTypes.func.isRequired,
  updateTeamMember: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  teamMember: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  teamMember: makeSelectTeamMember(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchTeamMember: (id, callback, companyId) =>
      dispatch(fetchTeamMember(id, callback, companyId)),
    updateTeamMember: (id, values, callback, companyId) =>
      dispatch(updateTeamMember(id, values, callback, companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'ModifyAdminForm',
  })(ModifyEmployee),
);
