/*
 *
 * CompanyCreation reducer
 *
 */
import produce from 'immer';

import {
  FETCH_PEOPLE_COMPANIES,
  FETCH_PEOPLE_COMPANIES_SUCCESS,
  FETCH_PEOPLE_COMPANIES_ERROR,
  CREATE_PEOPLE_COMPANY_ERROR,
  RESET_COMPANY_ERROR,
} from './constants';

export const initialState = {
  companies: {
    loading: false,
    error: false,
    data: [],
  },
};

/* eslint-disable default-case, no-param-reassign */
const peopleCompanyCreationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_PEOPLE_COMPANIES:
        draft.companies.loading = true;
        draft.companies.error = false;
        draft.companies.data = [];
        break;

      case FETCH_PEOPLE_COMPANIES_SUCCESS:
        draft.companies.data = action.companies;
        draft.companies.loading = false;
        break;
      case FETCH_PEOPLE_COMPANIES_ERROR:
        draft.companies.error = action.error;
        draft.companies.loading = false;
        break;
      case CREATE_PEOPLE_COMPANY_ERROR:
        draft.companies.error = action.error;
        draft.companies.loading = false;
        draft.companies.data = null;
        break;
      case RESET_COMPANY_ERROR:
        draft.companies.error = null;
        break;
    }
  });

export default peopleCompanyCreationReducer;
