import styled from 'styled-components';

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 768px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 768px;
`;
