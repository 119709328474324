/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

const selectRouter = (state) => state.router;

const makeSelectCurrentUser = () =>
  createSelector(selectGlobal, (globalState) => globalState.currentUser);

const makeSelectCurrentCompanyId = () =>
  createSelector(selectGlobal, (globalState) => globalState.currentCompanyId);

const makeSelectLocation = () =>
  createSelector(selectRouter, (routerState) => routerState.location);

export {
  selectGlobal,
  makeSelectCurrentUser,
  makeSelectLocation,
  makeSelectCurrentCompanyId,
};
