/*
 *
 * CompanyCreation constants
 *
 */

export const FETCH_PEOPLE_COMPANIES =
  'app/CompanyCreation/FETCH_PEOPLE_COMPANIES';
export const FETCH_PEOPLE_COMPANIES_ERROR =
  'app/CompanyCreation/FETCH_PEOPLE_COMPANIES_ERROR';
export const FETCH_PEOPLE_COMPANIES_SUCCESS =
  'app/CompanyCreation/FETCH_PEOPLE_COMPANIES_SUCCESS';
export const RESET_COMPANY_ERROR =
  'app/CompanyCreation/FETCH_PEOPLE_COMPANIES_ERROR';

export const CREATE_PEOPLE_COMPANY =
  'app/CompanyCreation/CREATE_PEOPLE_COMPANY';
export const CREATE_PEOPLE_COMPANY_ERROR =
  'app/CompanyCreation/CREATE_PEOPLE_COMPANY_ERROR';
export const CREATE_PEOPLE_COMPANY_SUCCESS =
  'app/CompanyCreation/CREATE_PEOPLE_COMPANY_SUCCESS';

export const UPLOAD_PEOPLE_EMPLOYEE_CSV =
  'app/CompanyCreation/UPLOAD_PEOPLE_EMPLOYEE_CSV';
export const UPLOAD_PEOPLE_EMPLOYEE_CSV_SUCCESS =
  'app/CompanyCreation/UPLOAD_PEOPLE_EMPLOYEE_CSV_SUCCESS';
export const UPLOAD_PEOPLE_EMPLOYEE_CSV_ERROR =
  'app/CompanyCreation/UPLOAD_PEOPLE_EMPLOYEE_CSV_ERROR';
