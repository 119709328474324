/**
 *
 * AddEmployee
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { Button, Icon } from 'antd';
import moment from 'moment';

import AntInput from 'components/AntInput';
import AntDatePicker from 'components/AntDatePicker';
import { validateEmail, required } from 'utils/validation';
import { normalizeNumeric } from 'utils/normalize';

import { createEmployee, fetchEmployeeList } from '../actions';
import makeSelectMyAccount, { makeSelectEmployeeList } from '../selectors';

const Container = styled.div`
  display: flex;
  max-width: 600px;
  margin-bottom: 40px;
  justify-content: space-between;
`;

const dateFormat = 'DD/MM/YYYY';

export function AddEmployee({
  dispatchCreateEmployee,
  handleSubmit,
  company,
  customFieldsData,
  change,
  employeeList,
  ...props
}) {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    _.assign(values, { company_id: company.id });
    setLoading(true);
    setErrorMessage(null);
    dispatchCreateEmployee(values, (employee, error) => {
      if (!error) {
        const today = moment();
        let emailDate;

        if (
          !values.start_date ||
          values.start_date.isSame(today, 'd') ||
          values.start_date.isBefore(today)
        ) {
          emailDate = 'today';
        } else {
          emailDate = values.start_date.format('LL');
        }

        let userMessage;

        if (employee.email === 'No Email Provided') {
          userMessage = `${employee.employee_id} has been added.`;
        } else {
          userMessage = `${employee.email} was successfully added. \
            They will be sent a welcome email ${emailDate}.`;
        }

        setSuccessMessage(userMessage);
        setLoading(false);
        props.fetchEmployeeList(employeeList.params);
      } else if (error) {
        setErrorMessage(error.data.error);
        setLoading(false);
      }
    });
  };

  return (
    <>
      <h3 className="sub-header" style={{ marginBottom: 0 }}>
        Manual Employee Upload
      </h3>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: 55 }}>
        <Container>
          <Field
            component={AntInput}
            label="FIRST NAME"
            name="first_name"
            validate={[required]}
          />
          <Field
            component={AntInput}
            label="LAST NAME"
            name="last_name"
            validate={[required]}
          />
          <Field
            component={AntInput}
            label="WORK EMAIL"
            name="email"
            validate={[validateEmail]}
          />
        </Container>
        <Container>
          <Field
            component={AntDatePicker}
            label="START DATE"
            name="start_date"
            placeholder={dateFormat}
            onChange={(_date, dateString) => {
              change('start_date', dateString);
            }}
          />
          <Field
            component={AntDatePicker}
            label="END DATE*"
            name="end_date"
            placeholder={dateFormat}
            onChange={(_date, dateString) => {
              change('end_date', dateString);
            }}
          />
          <div style={{ width: '180px' }} />
        </Container>
        <Container>
          <Field
            component={AntInput}
            label="ID"
            name="employee_id"
            validate={[required]}
          />
          <div style={{ width: '180px' }} />

          {company.contract_type === 'adhoc' ? (
            <Field
              component={AntInput}
              label="MINUTES"
              name="additional_minutes"
              validate={[required]}
              normalize={normalizeNumeric}
            />
          ) : (
            <div style={{ width: '180px' }} />
          )}
        </Container>
        <div style={{ width: '100%' }}>
          <div
            style={{
              color: '#9ea0a5',
              width: '550px',
              position: 'relative',
              top: '-30px',
            }}
          >
            Please provide a unique ID for this member. This must match the ID
            provided for this member in any future consolidation files. If this
            does not match the ID provided at a later time, this member may no
            longer be able to access their Inkblot benefits.
          </div>
        </div>
        {customFieldsData.length > 0 && (
          <Container>
            {_.map(customFieldsData, (cf) => {
              return (
                <Field
                  key={cf.internal_name}
                  component={AntInput}
                  label={cf.label}
                  name={`custom_fields.${cf.internal_name}`}
                />
              );
            })}
          </Container>
        )}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          disabled={loading}
        >
          <Icon type="plus-circle" style={{ verticalAlign: 0 }} />
          Add Employee
        </Button>
      </form>
    </>
  );
}

AddEmployee.propTypes = {
  company: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  dispatchCreateEmployee: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  employeeList: PropTypes.object.isRequired,
  fetchEmployeeList: PropTypes.func.isRequired,
  customFieldsData: PropTypes.array.isRequired,
};

AddEmployee.defaultProps = {
  company: {},
};

const mapStateToProps = createStructuredSelector({
  myAccount: makeSelectMyAccount(),
  employeeList: makeSelectEmployeeList(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatchCreateEmployee: (values, callback) =>
      dispatch(createEmployee(values, callback)),
    fetchEmployeeList: (values, callback) =>
      dispatch(fetchEmployeeList(values, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddEmployeeForm',
    enableReinitialize: true,
  })(AddEmployee),
);
