import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';
import { API_URL } from 'utils/environment';
import { post } from 'utils/request';
import { CREATE_REPORT } from './constants';
import { createReportSuccess, createReportError } from './actions';

function* createReport({ values, callback }) {
  const requestUrl = `${API_URL}/reports`;
  try {
    const company = yield call(post, requestUrl, values);
    yield reduxPut(createReportSuccess(company));
    if (callback) callback(company);
  } catch (error) {
    yield reduxPut(createReportError(error));
  }
}

// Individual exports for testing
export default function* reportsSaga() {
  yield takeLatest(CREATE_REPORT, createReport);
}
