/**
 *
 * TeamManagement
 *
 */

import React from 'react';
import styled from 'styled-components';

const DescriptionContainer = styled.div`
  position: relative;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  .pop-up {
    position: absolute;
    top: 0;
    left: 30px;
    width: 295px;
    height: auto;
    padding: 20px 0;
    border-radius: 4px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e2e5ed;

    display: flex;
    .pop-up-box {
      width: 154px;
      padding: 0 20px;
      p.title {
        margin: 3px 0 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: #1664d8;
      }
      p.desc {
        margin: 0;
        font-size: 12px;
        color: #3e3f42;
        span {
          font-weight: 500;
        }
      }
    }
    .pop-up-box-divider {
      width: 137px;
      border-right: solid 1px #e2e5ed;
    }
  }
`;

export function DescriptionPopup() {
  return (
    <DescriptionContainer>
      <div className="pop-up">
        <div className="pop-up-box pop-up-box-divider">
          <p className="title">Owner</p>
          <p className="desc">
            <span>Can</span> access all companies, but not reminders or
            reporting
          </p>
        </div>
        <div className="pop-up-box">
          <p className="title">Marchel Admin</p>
          <p className="desc">
            <span>Full</span> access
          </p>
        </div>
      </div>
    </DescriptionContainer>
  );
}

export default DescriptionPopup;
