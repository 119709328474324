/**
 *
 * AddEmployee
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Radio, Tooltip, Switch } from 'antd';

export const FlexCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
`;

export function HeaderValidation({
  rawHeaders,
  rowCount,
  validationError,
  onUploadTypeChange,
  uploadType,
  sendEmail,
  setSendEmail,
  backfillRequired,
  backfillPreviewRes,
}) {
  const headersContainsEmail = rawHeaders
    ? Object.keys(rawHeaders).includes('email')
    : false;

  useEffect(() => {
    onUploadTypeChange(!headersContainsEmail ? 'consolidation' : 'add');
    if (backfillRequired) {
      onUploadTypeChange('backfill');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawHeaders]);

  const consolidationTitle = backfillRequired
    ? 'All active employees are required to have an ID in order to do a Consolidation.'
    : 'A consolidation matches a new employee list with the existing employee list on our database, to identify any additions or removals within the company.';

  return (
    <div>
      <h3 className="sub-header">Type</h3>
      <Radio.Group
        onChange={(event) => onUploadTypeChange(event.target.value)}
        value={uploadType}
        style={{ marginBottom: 25 }}
      >
        <Radio.Button value="add">Add Employees</Radio.Button>
        <Tooltip title={consolidationTitle}>
          <Radio.Button value="consolidation" disabled={backfillRequired}>
            Consolidation
          </Radio.Button>
        </Tooltip>
        {backfillRequired && (
          <Tooltip title="Assigns ID to active employees, referenced by their work email. Employees who don't appear in the Eligibility File will be removed.">
            <Radio.Button value="backfill">Backfill</Radio.Button>
          </Tooltip>
        )}
      </Radio.Group>
      <p>Importing {rowCount} total row(s)</p>
      {validationError && <p style={{ color: 'red' }}>{validationError}</p>}
      {backfillPreviewRes && uploadType === 'backfill' && (
        <p style={{ fontWeight: 600 }}>
          Backfill will{' '}
          <span style={{ color: 'green' }}>
            update {backfillPreviewRes.updated} Employee(s)
          </span>{' '}
          and{' '}
          <span style={{ color: 'red' }}>
            remove {backfillPreviewRes.removed} Employee(s)
          </span>
          .
        </p>
      )}
      <>
        <h3 className="sub-header">Send Welcome Emails</h3>
        <Switch
          name="email_yn"
          checkedChildren="Yes"
          unCheckedChildren="No"
          checked={sendEmail}
          defaultChecked={sendEmail}
          onChange={() => setSendEmail(!sendEmail)}
        />
      </>
    </div>
  );
}

HeaderValidation.propTypes = {
  rawHeaders: PropTypes.object,
  rowCount: PropTypes.number,
  onUploadTypeChange: PropTypes.func.isRequired,
  setSendEmail: PropTypes.func.isRequired,
  validationError: PropTypes.string,
  uploadType: PropTypes.string.isRequired,
  sendEmail: PropTypes.bool.isRequired,
  backfillRequired: PropTypes.bool.isRequired,
  backfillPreviewRes: PropTypes.object.isRequired,
};

export default HeaderValidation;
