/* eslint-disable camelcase */
/**
 *
 * TeamManagement
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { Button, Icon } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import { userTypeOptions } from './constants';
import { makeSelectTeamMember } from './selectors';
import { createTeamMember } from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import DescriptionPopup from './DescriptionPopup';
import { required, validateEmail } from '../../utils/validation';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from '../App/selectors';
import { useHistory } from 'react-router-dom';

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

export function AddEmployee(props) {
  const {
    currentCompanyId,
    currentUser: { owner_yn, senior_admin_yn },
  } = props;
  const history = useHistory();
  const onSubmit = (values) => {
    props.createTeamMember(
      values,
      (_member, error) => {
        if (!error) {
          history.push('/team_management');
        }
      },
      currentCompanyId,
    );
  };

  // if you are only admin, you cant be on this page
  if (!owner_yn && !senior_admin_yn) {
    history.push('/team_management');
  }
  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">New Admin</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="first_name"
            label="FIRST NAME"
            component={AntInput}
            validate={[required]}
          />
          <Field
            name="last_name"
            label="LAST NAME"
            component={AntInput}
            validate={[required]}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="email"
            label="ACCOUNT EMAIL"
            component={AntInput}
            validate={[required, validateEmail]}
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="user_type"
            label="USER TYPE"
            width="180px"
            component={AntSelect}
            options={userTypeOptions(owner_yn)}
          />
          <DescriptionPopup />
        </FormRowContainer>
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.teamMember.error &&
              ((props.teamMember.error.data &&
                props.teamMember.error.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        <ButtonContainer>
          <Button
            htmlType="submit"
            type="primary"
            loading={props.teamMember.loading}
          >
            <Icon type="edit" style={{ verticalAlign: 0 }} />
            Add Admin
          </Button>
          <Button
            type="danger"
            onClick={() => history.push('/team_management')}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </form>
    </SubContainer>
  );
}

AddEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  createTeamMember: PropTypes.func.isRequired,
  teamMember: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  teamMember: makeSelectTeamMember(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    createTeamMember: (values, callback, companyId) =>
      dispatch(createTeamMember(values, callback, companyId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddAdminForm',
    initialValues: { user_type: 2 },
  })(AddEmployee),
);
