import React from 'react';
import { Table, Typography, Collapse } from 'antd';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

export function CustomFieldTable({ customFieldsData }) {
  const { Text } = Typography;
  const { Panel } = Collapse;

  const columns = [
    {
      title: 'Column Name',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (name) => <Text code>{name}</Text>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) => {
        return (
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(record.message),
              }}
            />{' '}
            {description ? (
              <Text>{description}</Text>
            ) : (
              <i>There is no description for this custom field.</i>
            )}
          </div>
        );
      },
    },
  ];

  const dataSource = customFieldsData.map((customField) => {
    return {
      key: customField.id,
      name: customField.internal_name,
      description: customField.description,
    };
  });

  return (
    <>
      <Collapse>
        <Panel header="Custom Fields (For Segmented Reporting)">
          {customFieldsData.length ? (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          ) : (
            <p>
              <i>There are no custom fields for this company.</i>
            </p>
          )}
        </Panel>
      </Collapse>
    </>
  );
}

CustomFieldTable.propTypes = {
  customFieldsData: PropTypes.array,
};

export default CustomFieldTable;
