/**
 *
 * Label
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledLabel = styled.p`
  font-family: 'Roboto', sans-serif;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 0.75rem;
  color: #9ea0a5;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

function Label({ children, style }) {
  return <StyledLabel style={style}>{children}</StyledLabel>;
}

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  style: PropTypes.object,
};

export default Label;
