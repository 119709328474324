import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import vision from '../../images/EapPage/vision.svg';
import messages from './messages';

export function Section8() {
  return (
    <div className="section-eight">
      <img src={vision} alt="Vision" />
      <FormattedHTMLMessage {...messages.realImpact} />
      <FormattedHTMLMessage {...messages.customizableTools} />
    </div>
  );
}

Section8.propTypes = {};
