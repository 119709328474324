import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import tangibleRoi from '../../images/EapPage/tangible-roi.svg';
import tangibleRoiFr from '../../images/EapPage/tangible-roi-fr.png';
import evidenceBased from '../../images/EapPage/evidence-based.png';
import messages from './messages';

export function SectionNine() {
  return (
    <div className="section-nine">
      <div className="part-1">
        <div className="text">
          <FormattedHTMLMessage {...messages.tangibleRoi} />
          <FormattedHTMLMessage {...messages.dataCollection} />
          <FormattedHTMLMessage {...messages.embeddedAssessment} />
        </div>
        <img
          src={
            localStorage.getItem('locale') !== 'fr'
              ? tangibleRoi
              : tangibleRoiFr
          }
          alt="Tangible ROI"
        />
      </div>
      <div className="part-2">
        <img src={evidenceBased} alt="Evidence based" />
        <div className="text">
          <FormattedHTMLMessage {...messages.evidenceBased} />
          <FormattedHTMLMessage {...messages.uniqueOutcome} />
        </div>
      </div>
    </div>
  );
}

SectionNine.propTypes = {};
