import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the reports state domain
 */

const selectReportsDomain = (state) => state.reports || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Reports
 */

const makeSelectReports = () =>
  createSelector(selectReportsDomain, (substate) => substate);

export default makeSelectReports;
export { selectReportsDomain };
