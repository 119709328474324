/*
 * EAP Messages
 *
 * This contains all the text for the EAP component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'marchel.containers.eap';

export default defineMessages({
  forBusiness: {
    id: `${scope}.forBusiness`,
    defaultMessage: 'Inkblot for Business',
  },
  haveQuestion: {
    id: `${scope}.haveQuestion`,
    defaultMessage:
      "Have a question? Send us an email to <a mailto:'business@inkblottherapy.com'>business@inkblottherapy.com</a>",
    tagName: 'p',
  },
  inkblotTherapy: {
    id: `${scope}.inkblotTherapy`,
    defaultMessage:
      '<a href="https://inkblottherapy.com/" target="_blank">Return to Inkblot Therapy</a>',
    tagName: 'p',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  eapCaps: {
    id: `${scope}.eapCaps`,
    defaultMessage: 'EAP',
  },
  aboutInkblot: {
    id: `${scope}.aboutInkblot`,
    defaultMessage: 'About Inkblot',
  },
  connect: {
    id: `${scope}.connect`,
    defaultMessage: 'Connect',
  },
  realSupport: {
    id: `${scope}.realSupport`,
    defaultMessage: 'An EAP that offers real support.',
  },
  yourEmployees: {
    id: `${scope}.yourEmployees`,
    defaultMessage:
      '<b>Stand by your employees and their families when they need it the most,</b> with an EAP designed to offer true engagement. On-demand appointments. Personal connections. Easiest to use.',
    tagName: 'p',
  },
  teamMember: {
    id: `${scope}.teamMember`,
    defaultMessage: 'Connect with an Inkblot Team Member',
  },
  trustedBy: {
    id: `${scope}.trustedBy`,
    defaultMessage: 'Trusted by leading organizations:',
    tagName: 'span',
  },
  fullSpectrum: {
    id: `${scope}.fullSpectrum`,
    defaultMessage: 'A full spectrum of care, ready when employees need it.',
  },
  onDemand: {
    id: `${scope}.onDemand`,
    defaultMessage:
      'From on-demand counselling to a wide range of advisory services, <b>Inkblot has your team covered.</b>',
    tagName: 'p',
  },
  lifesChallenges: {
    id: `${scope}.lifesChallenges`,
    defaultMessage: 'Support for all of life’s challenges:',
  },
  fastEasy: {
    id: `${scope}.fastEasy`,
    defaultMessage:
      'Fast & easy, long-term connections with a world-class team of therapists.',
    tagName: 'span',
  },
  peerProgram: {
    id: `${scope}.peerProgram`,
    defaultMessage:
      'Mental Health Peer Program with online training and group support.',
    tagName: 'span',
  },
  advisoryServices: {
    id: `${scope}.advisoryServices`,
    defaultMessage:
      'Advisory services to help with everything from legal and financial questions to childcare and eldercare resources.',
    tagName: 'span',
  },
  careerCounselling: {
    id: `${scope}.careerCounselling`,
    defaultMessage:
      'Career counselling and health coaching to help your employees feel and perform at their best.',
    tagName: 'span',
  },
  thePlaybook: {
    id: `${scope}.thePlaybook`,
    defaultMessage:
      "Inkblot is re-writing the playbook on Employee Assistance Programs and it's much needed. <b>They have over-delivered in every aspect of our partnership</b> and we haven't looked back.",
    tagName: 'p',
  },
  vpPeople: {
    id: `${scope}.vpPeople`,
    defaultMessage: 'VP, People Ops',
  },
  bestInClass: {
    id: `${scope}.bestInClass`,
    defaultMessage:
      'Counselling that isn’t just good enough, but best-in-class.',
    tagName: 'h2',
  },
  traditionalEaps: {
    id: `${scope}.traditionalEaps`,
    defaultMessage:
      '<b>Traditional EAPs are designed as a band-aid solution</b>; with Inkblot, you can offer your employees real care that will see them through a moment of <i>crisis and beyond.</i>',
    tagName: 'p',
  },
  employeeDriven: {
    id: `${scope}.employeeDriven`,
    defaultMessage: 'Employee-driven counselling that works.',
    tagName: 'h3',
  },
  uniqueMatching: {
    id: `${scope}.uniqueMatching`,
    defaultMessage:
      'Inkblot’s unique matching system allows employees to <b>select their own counsellor based on their needs and preferences,</b> rather than being assigned one by a service representative at a call centre.',
    tagName: 'p',
  },
  selfBooked: {
    id: `${scope}.selfBooked`,
    defaultMessage:
      'Appointments are self-booked, with same-day sessions available.',
  },
  lastingRelationships: {
    id: `${scope}.lastingRelationships`,
    defaultMessage: 'Strong and lasting counsellor-client relationships. ',
    tagName: 'h3',
  },
  rightMatch: {
    id: `${scope}.rightMatch`,
    defaultMessage:
      'We focus on finding the right match and employees can retain their counsellor for as long as they need support.',
    tagName: 'p',
  },
  theProof: {
    id: `${scope}.theProof`,
    defaultMessage: 'THE PROOF:',
    tagName: 'h4',
  },
  threeSessions: {
    id: `${scope}.threeSessions`,
    defaultMessage:
      'After only 3 sessions of counselling, employees on average experience <b>49% improvement</b> in symptoms as measured by validated clinical measures.',
    tagName: 'span',
  },
  easyUse: {
    id: `${scope}.easyUse`,
    defaultMessage: '<b>Easy to use</b>—for you and your team.',
    tagName: 'h2',
  },
  trulyEffective: {
    id: `${scope}.trulyEffective`,
    defaultMessage:
      'A truly effective solution with the <b><i>easiest</i></b> rollout imaginable.',
    tagName: 'p',
  },
  simpleOnboarding: {
    id: `${scope}.simpleOnboarding`,
    defaultMessage: 'Onboarding made simple.',
  },
  secureSeamless: {
    id: `${scope}.secureSeamless`,
    defaultMessage:
      'Our secure, seamless digital-first focus makes Inkblot easy to use for employees, their families & HR teams.',
  },
  customizedImplementation: {
    id: `${scope}.customizedImplementation`,
    defaultMessage:
      '<b>Our Inkblot Customized EAP Implementation Plan</b> takes the work and stress out of introducing Inkblot to your team',
    tagName: 'li',
  },
  neededUsed: {
    id: `${scope}.neededUsed`,
    defaultMessage:
      "We ensure Inkblot maintains top of mind awareness, so <b>when it's needed, it's used.</b>",
    tagName: 'li',
  },
  employeeTraining: {
    id: `${scope}.employeeTraining`,
    defaultMessage:
      'Mental Health training for employees and for managers is <b>included at no additional cost.</b>',
    tagName: 'li',
  },
  realImpact: {
    id: `${scope}.realImpact`,
    defaultMessage: 'Real impact. <b>Real visibility.</b>',
    tagName: 'h2',
  },
  customizableTools: {
    id: `${scope}.customizableTools`,
    defaultMessage:
      'Inkblot’s customizable tools ensure that you understand <b><i>exactly</i></b> how your investment is helping your employees',
    tagName: 'p',
  },
  tangibleRoi: {
    id: `${scope}.tangibleRoi`,
    defaultMessage: 'Demonstrate tangible ROI.',
    tagName: 'h3',
  },
  dataCollection: {
    id: `${scope}.dataCollection`,
    defaultMessage:
      'Inkblot’s data collection and smart analytics allows a confidential yet comprehensive view of how our services are impacting your workplace',
    tagName: 'p',
  },
  embeddedAssessment: {
    id: `${scope}.embeddedAssessment`,
    defaultMessage:
      'Our embedded <b>mental health assessment</b> tool provides both you and your employees with valuable baseline, benchmarking and recommendations to monitor psychological health.',
    tagName: 'p',
  },
  evidenceBased: {
    id: `${scope}.evidenceBased`,
    defaultMessage: 'Evidence-based reporting.',
    tagName: 'h3',
  },
  uniqueOutcome: {
    id: `${scope}.uniqueOutcome`,
    defaultMessage:
      'Our unique session-to-session clinical outcome monitoring and analytics <b>can integrate with your HRIS</b> to provide robust aggregate insights and demonstrate ROI.',
    tagName: 'p',
  },
  connectWith: {
    id: `${scope}.connectWith`,
    defaultMessage: 'Connect with an Inkblot Team Member today.',
  },
  haveOptions: {
    id: `${scope}.haveOptions`,
    defaultMessage: 'When it comes to your EAP - <b>you have options.</b>',
    tagName: 'p',
  },
  byNumbers: {
    id: `${scope}.byNumbers`,
    defaultMessage: 'inkblot by the numbers',
    tagName: 'h3',
  },
  feelBetter: {
    id: `${scope}.feelBetter`,
    defaultMessage: 'Employees have used Inkblot to feel better',
    tagName: 'span',
  },
  referredInkblot: {
    id: `${scope}.referredInkblot`,
    defaultMessage: 'Family physicians have referred to Inkblot',
    tagName: 'span',
  },
  trustedPractitioners: {
    id: `${scope}.trustedPractitioners`,
    defaultMessage: 'Trusted Practitioners to choose from',
    tagName: 'span',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'name',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'email',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'company name',
  },
  jobTitle: {
    id: `${scope}.jobTitle`,
    defaultMessage: 'job title',
  },
  organizationSize: {
    id: `${scope}.organizationSize`,
    defaultMessage: 'organization size',
  },
  messageOptional: {
    id: `${scope}.messageOptional`,
    defaultMessage: 'message (optional)',
  },
  toPartner: {
    id: `${scope}.toPartner`,
    defaultMessage: 'eg: I want to partner with Inkblot because…',
  },
  howHear: {
    id: `${scope}.howHear`,
    defaultMessage: 'how did you hear about us?',
  },
  sendingRequest: {
    id: `${scope}.sendingRequest`,
    defaultMessage: 'Send Request',
  },
});
