import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the companyEdit state domain
 */

const selectCompanyEditDomain = (state) => {
  return state.companyEdit || initialState;
};
/**
 * Other specific selectors
 */

const makeSelectCompanies = () =>
  createSelector(
    selectCompanyEditDomain,
    (substate) => substate.companies.data,
  );

const makeSelectAccountManagers = () =>
  createSelector(
    selectCompanyEditDomain,
    (substate) => substate.accountManagers.data,
  );
// prettier-ignore
const makeSelectCpNavigators = () =>
  createSelector(
    selectCompanyEditDomain,
    substate =>
      substate.cpNavigators.data && substate.cpNavigators.data.length
        ? substate.cpNavigators.data.map(e => ({
          label: `${e.first_name} ${e.last_name} (id:${e.id})`,
          value: e.id, 
        }))
        : [],
  );
/**
 * Default selector used by companyEdit
 */

const makeSelectCompanyEdit = () =>
  createSelector(selectCompanyEditDomain, (substate) => substate);

export default makeSelectCompanyEdit;
export {
  selectCompanyEditDomain,
  makeSelectCompanies,
  makeSelectAccountManagers,
  makeSelectCpNavigators,
};
