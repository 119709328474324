import styled from 'styled-components';

const SubHeaderContainer = styled.div`
  margin: 40px 60px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default SubHeaderContainer;
