import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { get, post } from 'utils/request';

import {
  FETCH_COMPANIES,
  FETCH_AMS,
  CREATE_COMPANY,
  UPLOAD_EMPLOYEE_CSV,
  FETCH_CP_NAVIGATORS,
  CREATE_LOGO,
} from './constants';
import {
  fetchCompaniesSuccess,
  fetchCompaniesError,
  fetchAccountManagersSuccess,
  fetchAccountManagersError,
  createCompanySuccess,
  createCompanyError,
  createLogoSuccess,
  createLogoError,
  uploadEmployeeCsvSuccess,
  uploadEmployeeCsvError,
  fetchClinicalNavigatorsSuccess,
  fetchClinicalNavigatorsError,
} from './actions';

function* fetchCompanies() {
  const requestUrl = `${API_URL}/companies?resellers=true`;

  try {
    const companies = yield call(get, requestUrl);
    yield reduxPut(fetchCompaniesSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchCompaniesError(error));
  }
}

function* createLogo({ values, companyId, callback }) {
  const requestUrl = `${API_URL}/companies/${companyId}/company_logos`;
  try {
    const comp = yield call(post, requestUrl, values);
    yield reduxPut(createLogoSuccess(comp));
    if (callback) callback(comp);
  } catch (error) {
    yield reduxPut(createLogoError(error));
  }
}

function* createCompany({ values, callback }) {
  const requestUrl = `${API_URL}/companies`;

  try {
    const comp = yield call(post, requestUrl, values);
    yield reduxPut(createCompanySuccess(comp));
    if (callback) callback(comp);
  } catch (error) {
    yield reduxPut(createCompanyError(error));
  }
}

function* fetchAccountManagers() {
  const requestUrl = `${API_URL}/account_managers`;

  try {
    const companies = yield call(get, requestUrl);
    yield reduxPut(fetchAccountManagersSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchAccountManagersError(error));
  }
}

function* fetchClinicalNavigators() {
  const requestUrl = `${API_URL}/clinical_navigators`;

  try {
    const companies = yield call(get, requestUrl);
    yield reduxPut(fetchClinicalNavigatorsSuccess(companies));
  } catch (error) {
    yield reduxPut(fetchClinicalNavigatorsError(error));
  }
}

function* uploadEmployeeCsv({ values, callback }) {
  const requestUrl = `${API_URL}/companies/upload_csv`;

  try {
    const success = yield call(post, requestUrl, values);
    yield reduxPut(uploadEmployeeCsvSuccess(success));

    if (callback) callback(success);
  } catch (error) {
    yield reduxPut(uploadEmployeeCsvError(error));
  }
}

export default function* companyCreationSaga() {
  yield takeLatest(FETCH_COMPANIES, fetchCompanies);
  yield takeLatest(FETCH_CP_NAVIGATORS, fetchClinicalNavigators);
  yield takeLatest(FETCH_AMS, fetchAccountManagers);
  yield takeLatest(UPLOAD_EMPLOYEE_CSV, uploadEmployeeCsv);
  yield takeLatest(CREATE_COMPANY, createCompany);
  yield takeLatest(CREATE_LOGO, createLogo);
}
