import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextWrapper = styled.div`
  width: ${(props) => props.width};
  display: block;
  p.tag {
    font-family: 'Roboto';
    font-size: 16px;
    color: #3e3f42;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-bottom: 10px;
  }
  p.data {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #3e3f42;
  }
`;

const TextData = ({ tag, data, width }) => {
  return (
    <TextWrapper width={width}>
      <p className="tag">{tag}</p>
      <p className="data">{data}</p>
    </TextWrapper>
  );
};

TextData.defaultProps = {
  tag: '',
  data: '',
  width: '50%',
};

TextData.propTypes = {
  tag: PropTypes.string,
  data: PropTypes.string,
  width: PropTypes.string,
};

export default TextData;
