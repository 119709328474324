import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import trophy from '../../images/EapPage/trophy.svg';
import messages from './messages';

export function SectionFour() {
  return (
    <div className="section-four">
      <img src={trophy} alt="Trophy" />
      <FormattedHTMLMessage {...messages.bestInClass} />
      <FormattedHTMLMessage {...messages.traditionalEaps} />
    </div>
  );
}

SectionFour.propTypes = {};
