/**
 *
 * ResetPassword
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { fetchCurrentUser } from 'containers/App/actions';
import AntInput from 'components/AntInput';
import { Button, Icon } from 'antd';
import { Field, reduxForm } from 'redux-form';
import {
  required,
  passwordsMatch,
  passwordLength,
} from '../../utils/validation';
import { API_URL } from '../../utils/environment';

function ResetPassword(props) {
  const [pleaseWait, setPleaseWait] = useState(false);
  const [resetError, setResetError] = useState(false);
  const { handleSubmit, history } = props;

  const token = props.match.params.reset_password_token;

  const onSubmit = (values) => {
    axios
      .put(`${API_URL}/reset_passwords/me?reset_password_token=${token}`, {
        ...values,
      })
      .then((res) => {
        localStorage.setItem('token', res.data.access_token);
        setPleaseWait(true);
        setTimeout(() => props.fetchCurrentUser(), 1000);
        setTimeout(() => history.push('/my_account/company_information'), 3000);
      })
      .catch(() => {
        setResetError(true);
      });
  };
  return (
    <div className="signin-container">
      <div className="sign-in-box">
        <Helmet>
          <title>ResetPassword</title>

          <meta name="description" content="Description of ResetPassword" />
        </Helmet>
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '20px' }}>
          <Field
            name="password"
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            label="Password"
            placeholder="·····"
            component={AntInput}
            style={{ width: '100%' }}
            validate={[required, passwordLength]}
            type="password"
          />
          <div style={{ height: '30px' }} />
          <Field
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password_confirmation"
            label="Confirm Password"
            placeholder="·····"
            component={AntInput}
            style={{ width: '100%' }}
            validate={[required, passwordsMatch, passwordLength]}
            type="password"
          />
          {pleaseWait && (
            <p style={{ marginTop: '20px', marginBottom: 0 }}>
              Please wait while we redirect you... If you are not directed in 5
              seconds,{' '}
              <Link to="/my_account/company_information">click here</Link>
            </p>
          )}
          {resetError && (
            <p
              className="error-message"
              style={{ marginTop: '8px', marginBottom: 0 }}
            >
              Please contact{' '}
              <a href="mailto:support@inkblottherapy.com">
                support@inkblottherapy.com
              </a>{' '}
              for a password reset.
            </p>
          )}
          <Button
            style={{ marginTop: '40px' }}
            htmlType="submit"
            type="primary"
            block
            disabled={pleaseWait}
          >
            Confirm Reset{' '}
          </Button>
        </form>
      </div>
    </div>
  );
}

ResetPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

ResetPassword.defaultProps = {};

function mapDispatchToProps(dispatch) {
  return {
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'ResetPasswordForm',
  })(ResetPassword),
);
