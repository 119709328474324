import { takeLatest, call, put as reduxPut } from 'redux-saga/effects';

import { API_URL } from 'utils/environment';
import { post } from 'utils/request';

import { SIGN_IN } from './constants';
import { signInSuccess, signInError } from './actions';

function* signIn({ values, callback }) {
  const requestUrl = `${API_URL}/auths/signin`;

  try {
    const user = yield call(post, requestUrl, values);
    yield reduxPut(signInSuccess(user));
    if (callback) callback(user);
  } catch (error) {
    yield reduxPut(signInError(error));
    if (callback) callback(null, error);
  }
}

export default function* signInSaga() {
  yield takeLatest(SIGN_IN, signIn);
}
