import React from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';

const TextWrapper = styled.h5`
  font-family: 'Roboto';
  font-size: 16px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 0.75rem;
  color: #9ea0a5;
  line-height: 1.125rem;
  text-transform: uppercase;
`;

const TextEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .ql-editor {
    height: 80px;
  }
  width: 500px;
`;
const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'header',
  'list',
  'bullet',
  'align',
  'link',
];

const modules = {
  toolbar: [
    [{ header: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};

const gatingInstructionModules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
};
const TextEditor = (props) => {
  const { input, placeholder, label, type } = props;

  return (
    <TextEditorWrapper>
      <TextWrapper>{label}</TextWrapper>
      <ReactQuill
        formats={formats}
        modules={
          type == 'gating_instructions' ? gatingInstructionModules : modules
        }
        theme="snow"
        onChange={input.onChange}
        {...props}
        value={input.value}
        placeholder={placeholder}
      />
    </TextEditorWrapper>
  );
};

TextEditor.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default TextEditor;
