/* eslint-disable react/no-unused-prop-types */
/**
 *
 * MyAccount
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Field } from 'redux-form';
import { Menu } from 'antd';
import { UikWidget, UikLayoutMain, UikContentTitle, UikButton } from '@uik/lib';
import { FlexWrap } from 'components/Flex';
import { MarginFifteen } from 'components/Margin';
import {
  makeSelectCurrentUser,
  makeSelectCurrentCompanyId,
} from 'containers/App/selectors';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import _ from 'lodash';
import { makeSelectCompany, makeSelectEmployee } from './selectors';
import { fetchCompany, uploadEmployeeCsv, savePaymentType } from './actions';
import InputField from './Form/InputField';
import DropdownField from './Form/DropdownField';
import RadioField from './Form/RadioField';
import CsvDropdownField from './Form/CsvDropdownField';
import CompanyInformation from './components/CompanyInformation';
import ContractDetails from './components/ContractDetails';
import InvoicesBilling from './components/InvoicesBilling';
import EmployeeManagement from './components/EmployeeManagement';
import redX from '../../images/MyAccount/redx.svg';
import greenCheck from '../../images/MyAccount/greencheck.svg';
import reducer from './reducer';
import saga from './saga';

export function MyAccount(props) {
  useInjectReducer({ key: 'myAccount', reducer });
  useInjectSaga({ key: 'myAccount', saga });
  useEffect(() => {
    if (props.currentCompanyId) props.fetchCompany(props.currentCompanyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentCompanyId]);

  const renderInput = (input) => {
    const { label, name, placeholder, normalize, validate } = input;
    return (
      <MarginFifteen>
        <Field
          name={name}
          label={label}
          placeholder={placeholder}
          component={InputField}
          normalize={normalize}
          validate={validate}
        />
      </MarginFifteen>
    );
  };

  const renderDoubleInput = (values) => {
    const {
      labelOne,
      nameOne,
      labelTwo,
      nameTwo,
      placeholderOne,
      placeholderTwo,
      normalizeOne,
      normalizeTwo,
    } = values;
    return (
      <FlexWrap>
        {renderInput({
          label: labelOne,
          name: nameOne,
          placeholder: placeholderOne,
          normalize: normalizeOne,
        })}
        {renderInput({
          label: labelTwo,
          name: nameTwo,
          placeholder: placeholderTwo,
          normalize: normalizeTwo,
        })}
      </FlexWrap>
    );
  };

  const renderMapping = (input) => {
    const {
      label,
      name,
      options,
      width,
      placeholder,
      defaultValue,
      example,
      onChange,
      key,
    } = input;
    return (
      <MarginFifteen>
        <div style={{ overflow: 'hidden', maxWidth: width }}>
          <CsvDropdownField
            label={label}
            name={name}
            placeholder={placeholder}
            options={options}
            width={width}
            defaultValue={defaultValue}
            onChange={onChange}
            key={key}
          />
          <p style={{ marginTop: 15 }}>{example.trim()}</p>
        </div>
      </MarginFifteen>
    );
  };

  const renderDropdown = (input) => {
    const { label, name, options, width } = input;
    return (
      <MarginFifteen>
        <Field
          name={name}
          label={label}
          component={DropdownField}
          options={options}
          width={width}
        />
      </MarginFifteen>
    );
  };

  const renderContractDetail = (input) => {
    const { name, included } = input;
    return (
      <div style={{ margin: '3px 0px', display: 'flex' }}>
        <img
          style={{ marginRight: '5px' }}
          src={!included ? redX : greenCheck}
          alt={`${name} included`}
        />
        <div style={{ height: '100%' }}>
          <div>{name}</div>
        </div>
      </div>
    );
  };

  const renderYesNoRadio = (radio) => {
    const options = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ];

    return renderRadio({ ...radio, options });
  };

  const renderRadio = (radio) => {
    const { title, options, name } = radio;

    return (
      <MarginFifteen>
        <UikContentTitle>{title}</UikContentTitle>
        <div style={{ marginTop: '15px' }}>
          <Field
            name={name}
            title={title}
            options={options}
            component={RadioField}
          />
        </div>
      </MarginFifteen>
    );
  };

  const renderAccountDetail = (values) => {
    const { name, value, hyperlink } = values;
    return (
      <div style={{ marginRight: '155px' }}>
        <p className="body-text bold">{name}</p>
        {hyperlink ? (
          <p className="body-text">
            <a href={value} target="_blank">
              {value}
            </a>
          </p>
        ) : (
          <p className="body-text">{value}</p>
        )}
      </div>
    );
  };

  const renderServicesTable = () => {
    const data = [
      {
        date: '02/12/2019',
        type: 'Receipt',
      },
      {
        date: '02/11/2019',
        type: 'Invoice',
      },
      {
        date: '02/13/2019',
        type: 'Receipt',
      },
    ];
    return (
      <>
        {data.map((item, i) => {
          return (
            <tr key={`${i + 1}`}>
              <td>{item.date}</td>
              <td>{item.type}</td>
              <td>
                <UikButton icon={<i className="fa fa-file-pdf-o" />}>
                  .pdf
                </UikButton>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  const renderAccountSection = (pathname) => {
    if (pathname.includes('company_information')) {
      return <CompanyInformation />;
    }
    if (pathname.includes('contract_details') && props.company.data) {
      return (
        <ContractDetails
          user={props.currentUser}
          renderContractDetail={renderContractDetail}
          renderAccountDetail={renderAccountDetail}
          company={props.company.data}
        />
      );
    }
    if (pathname.includes('invoices_billing')) {
      return (
        <InvoicesBilling
          renderContractDetail={renderContractDetail}
          renderDropdown={renderDropdown}
          renderServicesTable={renderServicesTable}
          savePaymentType={props.savePaymentType}
          company={props.company}
          renderRadio={renderRadio}
          userEmail={props.currentUser.email}
        />
      );
    }
    if (pathname.includes('employee_management')) {
      return (
        <EmployeeManagement
          renderDoubleInput={renderDoubleInput}
          renderInput={renderInput}
          renderYesNoRadio={renderYesNoRadio}
          renderDropdown={renderDropdown}
          renderMapping={renderMapping}
          uploadEmployeeCsv={props.uploadEmployeeCsv}
          company={props.company.data}
        />
      );
    }
    return <noscript />;
  };

  const { pathname } = props.history.location;

  const currentPath = /[^/]*$/.exec(props.location.pathname)[0];

  const canEdit =
    _.get(props, 'currentUser.owner_yn') &&
    _.get(props, 'currentUser.inkblot_admin_yn');

  return (
    <UikLayoutMain>
      <UikWidget padding>
        <h2 className="header">My Account</h2>
        <Menu mode="horizontal" selectedKeys={[currentPath]}>
          <Menu.Item key="company_information">
            <Link to="/my_account/company_information">
              Company Information
            </Link>{' '}
          </Menu.Item>
          <Menu.Item key="contract_details">
            <Link to="/my_account/contract_details">Contract Details</Link>
          </Menu.Item>
          {props.currentUser && props.currentUser.inkblot_admin_yn && (
            <Menu.Item key="invoices_billing">
              <Link to="/my_account/invoices_billing">Invoices & Billing</Link>
            </Menu.Item>
          )}
          <Menu.Item key="employee_management">
            <Link to="/my_account/employee_management">
              Employee Management
            </Link>
          </Menu.Item>
          {canEdit && (
            <Menu.Item key="edit_company">
              <Link to="/my_account/edit_company">Edit Company</Link>
            </Menu.Item>
          )}
        </Menu>
        {renderAccountSection(pathname)}
      </UikWidget>
    </UikLayoutMain>
  );
}

MyAccount.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fetchCompany: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  uploadEmployeeCsv: PropTypes.func.isRequired,
  savePaymentType: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  currentCompanyId: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  company: makeSelectCompany(),
  currentUser: makeSelectCurrentUser(),
  employee: makeSelectEmployee(),
  currentCompanyId: makeSelectCurrentCompanyId(),
});

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: (id) => dispatch(fetchCompany(id)),
    uploadEmployeeCsv: (values, callback) =>
      dispatch(uploadEmployeeCsv(values, callback)),
    savePaymentType: (values, callback) =>
      dispatch(savePaymentType(values, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MyAccount);
