/*
 *
 * SignIn reducer
 *
 */
import produce from 'immer';
import { SIGN_IN, SIGN_IN_SUCCESS, SIGN_IN_ERROR } from './constants';

export const initialState = {
  signInResult: {
    loading: false,
    error: false,
    data: false,
  },
};

/* eslint-disable default-case, no-param-reassign */
const signInReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIGN_IN:
        draft.signInResult.loading = true;
        draft.signInResult.error = false;
        draft.signInResult.data = false;
        break;

      case SIGN_IN_SUCCESS:
        draft.signInResult.data = action.user;
        draft.signInResult.loading = false;
        break;

      case SIGN_IN_ERROR:
        draft.signInResult.error = action.error;
        draft.signInResult.loading = false;
        break;
    }
  });

export default signInReducer;
