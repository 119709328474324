/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import axios from 'axios';
import queryString from 'query-string';
import { API_URL } from '../../utils/environment';

function ImportExecution({ match }) {
  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.status === 'execute') {
      axios.put(`${API_URL}/imports/${match.params.id}`, {
        status: 'executed',
      });
    } else {
      axios.put(`${API_URL}/imports/${match.params.id}`, {
        status: 'cancelled',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="signin-container">
      <div className="sign-in-box">
        <h2>Job Scheduled!</h2>
        {queryString.parse(window.location.search).status === 'execute' && (
          <p>
            Your upload has been confirmed, and is queued to be completed. You
            will receive a confirmation email when the job has finished.
          </p>
        )}
        {queryString.parse(window.location.search).status !== 'execute' && (
          <p>Your upload has been cancelled.</p>
        )}
        <p>You may close this window now.</p>
      </div>
    </div>
  );
}

ImportExecution.propTypes = {
  match: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ImportExecution);
