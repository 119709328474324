/**
 *
 * SignIn
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import 'styles/containers/auth.scss';
import AntInput from 'components/AntInput';
import { Button, Icon } from 'antd';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { setToken } from 'utils/auth';
import { fetchCurrentUser } from 'containers/App/actions';
import { signIn } from './actions';
import { makeSelectSignInResult, makeSelectCurrentUser } from './selectors';
import reducer from './reducer';
import saga from './saga';

const requiredEmail = (value) =>
  value ? undefined : 'Please enter your e-mail address';
const requiredPassword = (value) =>
  value ? undefined : 'Please enter your password';

const VerticalSpacer = styled.div`
  height: ${(props) => props.height};
`;

export function SignIn(props) {
  const { signInResult } = props;

  if (!_.isEmpty(props.currentUser)) {
    props.history.push('/my_account/company_information');
  }

  const onSubmit = (values) => {
    props.signIn(values, (user, error) => {
      if (!error) {
        setToken(user.access_token);
        props.fetchCurrentUser();
        props.history.push('/my_account/company_information');
      }
    });
  };

  useInjectReducer({ key: 'signIn', reducer });
  useInjectSaga({ key: 'signIn', saga });

  return (
    <div className="signin-container">
      <div className="sign-in-box">
        <form onSubmit={props.handleSubmit(onSubmit)}>
          <h2>Sign In</h2>
          <Link to="forgot_password">Forgot My Password</Link>
          <VerticalSpacer height="20px" />
          <Field
            name="email"
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            label="E-MAIL ADDRESS"
            placeholder="your@email.com"
            component={AntInput}
            style={{ width: '100%' }}
            validate={requiredEmail}
          />
          <VerticalSpacer height="30px" />
          <Field
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            name="password"
            label="PASSWORD"
            placeholder="·····"
            type="password"
            component={AntInput}
            style={{ width: '100%' }}
            validate={[requiredPassword]}
          />
          <VerticalSpacer height="20px" />
          <div className="error-message">
            <p>
              {signInResult.error &&
                ((signInResult.error.data && signInResult.error.data.error) ||
                  'Error')}
            </p>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            loading={signInResult.loading}
            block
          >
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchCurrentUser: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInResult: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
};

SignIn.defaultProps = {
  currentUser: {},
};

const mapStateToProps = createStructuredSelector({
  signInResult: makeSelectSignInResult(),
  currentUser: makeSelectCurrentUser(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    signIn: (values, callback) => dispatch(signIn(values, callback)),
    fetchCurrentUser: () => dispatch(fetchCurrentUser()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'SignInForm',
  })(SignIn),
);
