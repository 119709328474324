import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Checkbox } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import AntMultipleEmailsInput from 'components/AntMultipleEmailsInput';
import Container from './Container';
import { canadianProvinces } from '../../../../pagePartials';
import { updateCompany } from '../../actions';
import {
  validatePhone,
  validatePostalCode,
  validateEmail,
} from '../../../../utils/validation';
import { normalizePhone } from '../../../../utils/normalize';

const renderContactAddress = (
  type,
  title,
  sameAs = false,
  sameAsFn = () => {},
) => {
  return (
    <div>
      <h4 style={{ marginBottom: '12px', textTransform: 'capitalize' }}>
        {title}
      </h4>
      {type === 'billing' && (
        <Checkbox
          onClick={sameAsFn}
          style={{ marginTop: '28px', marginBottom: '40px' }}
        >
          Same as primary address{' '}
        </Checkbox>
      )}
      {!sameAs && (
        <>
          <Container
            maxWidth="600px"
            style={{ justifyContent: 'space-between' }}
          >
            <Field
              component={AntInput}
              label="CONTACT PERSON"
              name={`${type}.name`}
            />
            <Field component={AntInput} label="TITLE" name={`${type}.title`} />
            <div style={{ width: '180px' }} />
          </Container>
          <Container
            maxWidth="600px"
            style={{ justifyContent: 'space-between' }}
          >
            <Field
              component={AntInput}
              label="EMAIL"
              name={`${type}.email`}
              validate={validateEmail}
            />
            <Field
              component={AntMultipleEmailsInput}
              label="CC Emails"
              name={`${type}.cc_emails`}
            />
          </Container>
          <Container
            maxWidth="600px"
            style={{ justifyContent: 'space-between' }}
          >
            <Field
              component={AntInput}
              label="ADDRESS 1"
              name={`${type}.address.street`}
            />
            <Field
              component={AntInput}
              label="ADDRESS 2"
              name={`${type}.address.alternate_street`}
            />
            <Field
              component={AntInput}
              label="CITY/TOWN"
              name={`${type}.address.city`}
            />
          </Container>
          <Container
            maxWidth="600px"
            style={{ justifyContent: 'space-between' }}
          >
            <Field
              component={AntSelect}
              options={canadianProvinces}
              label="PROVINCE/TERRITORY"
              name={`${type}.address.state_id`}
            />
            <Field
              component={AntInput}
              label="POSTAL CODE"
              name={`${type}.address.code`}
              validate={validatePostalCode}
            />
          </Container>
          <Container
            maxWidth="600px"
            style={{ justifyContent: 'space-between' }}
          >
            <Field
              component={AntInput}
              label="Business Phone"
              name={`${type}.business_phone`}
              validate={validatePhone}
              normalize={normalizePhone}
            />
            <Field
              component={AntInput}
              label="SMS Phone"
              name={`${type}.sms_phone`}
              validate={validatePhone}
              normalize={normalizePhone}
            />
            <div style={{ width: '180px' }} />
          </Container>
        </>
      )}
    </div>
  );
};

const AddressForm = (props) => {
  const [sameAs, setSameAs] = useState(false);
  const { company, handleSubmit, toggle } = props;

  const onSubmit = (values) => {
    const params = { ...values };
    if (sameAs) {
      params.billing = {
        ...values.primary,
      };
    }
    props.updateCompany(company.id, params, () => toggle());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {renderContactAddress('primary', 'Primary Contact')}
      {renderContactAddress('secondary', 'Secondary Contact')}
      {renderContactAddress('billing', 'Billing Contact', sameAs, () =>
        setSameAs(!sameAs),
      )}
      <Button htmlType="submit" type="primary">
        Save Changes
      </Button>
      <Button style={{ marginLeft: 10 }} onClick={() => toggle()}>
        Cancel
      </Button>
    </form>
  );
};

AddressForm.propTypes = {
  company: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
};

function mapStateToProps(_state, ownProps) {
  return {
    initialValues: {
      primary: ownProps.primary,
      secondary: ownProps.secondary,
      billing: ownProps.billing,
    },
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateCompany: (id, values, callback) =>
      dispatch(updateCompany(id, values, callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'AddressForm',
    enableReinitialize: true,
  })(AddressForm),
);
