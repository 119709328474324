/* eslint-disable camelcase */
/**
 *
 * TeamManagement
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { Button, Icon } from 'antd';
import AntInput from 'components/AntInput';
import AntSelect from 'components/AntSelect';
import { userTypeOptions } from './constants';
import {
  fetchAdminMember,
  updateAdminMember,
  fetchAdminMembers,
} from './actions';
import SubContainer from './SubContainer';
import SubHeaderContainer from './SubHeaderContainer';
import DescriptionPopup from './DescriptionPopup';
import { makeSelectAdminMember } from './selectors';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';

const required = (value) => (value ? undefined : 'Required');

const FormRowContainer = styled.div`
  width: 600px;
  margin: 0 0 30px 0;
  display: flex;
  > div {
    width: 30%;
    margin: 0 5% 0 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 0;
  > button {
    margin-right: 10px;
  }
`;

const SubmissionErrorStyle = styled.div`
  margin: 70px 0 10px 0;

  p.error-message {
    color: #e6492d;
    font-size: 11px;
    margin: 0;
  }
`;

export function ModifyEmployee(props) {
  const {
    currentUser: { inkblot_admin_yn },
  } = props;
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.fetchAdminMember(props.match.params.id, (member, error) => {
      if (!error) {
        props.change('email', member.email);
        props.change('user_type', member.admin_yn ? 2 : 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentUser]);

  const onSubmit = (values) => {
    setLoading(true);
    props.updateAdminMember(props.match.params.id, values, (_member, error) => {
      if (!error) {
        props.fetchAdminMembers(() => {
          setLoading(false);
          history.push('/administration');
        });
      }
    });
  };

  // if you are only admin, you cant be on this page
  if (!inkblot_admin_yn) {
    history.push('/my_account/company_information');
  }

  return (
    <SubContainer>
      <SubHeaderContainer>
        <h3 className="sub-header">Modify Admin</h3>
      </SubHeaderContainer>
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <FormRowContainer>
          <Field
            name="email"
            label="ACCOUNT EMAIL"
            component={AntInput}
            validate={[required]}
            disabled
          />
        </FormRowContainer>
        <FormRowContainer>
          <Field
            name="user_type"
            label="USER TYPE"
            width="180px"
            component={AntSelect}
            options={userTypeOptions()}
            validate={[required]}
          />
          <DescriptionPopup />
        </FormRowContainer>
        <SubmissionErrorStyle>
          <p className="error-message">
            {props.adminMember.error &&
              ((props.adminMember.error.data &&
                props.adminMember.error.data.error) ||
                'Error')}
          </p>
        </SubmissionErrorStyle>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <ButtonContainer>
            <Button
              htmlType="submit"
              type="primary"
              loading={props.adminMember.loading}
            >
              <Icon type="edit" style={{ verticalAlign: 0 }} />
              Modify
            </Button>
            <Button
              type="danger"
              onClick={() => history.push('/administration')}
            >
              Cancel
            </Button>
          </ButtonContainer>
        )}
      </form>
    </SubContainer>
  );
}

ModifyEmployee.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchAdminMember: PropTypes.func.isRequired,
  updateAdminMember: PropTypes.func.isRequired,
  fetchAdminMembers: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  adminMember: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
  adminMember: makeSelectAdminMember(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    fetchAdminMember: (id, callback) =>
      dispatch(fetchAdminMember(id, callback)),
    updateAdminMember: (id, values, callback) =>
      dispatch(updateAdminMember(id, values, callback)),
    fetchAdminMembers: (callback) => dispatch(fetchAdminMembers(callback)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(
  reduxForm({
    form: 'ModifyAdminForm',
  })(ModifyEmployee),
);
