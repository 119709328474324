/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_ERROR,
  FETCH_CURRENT_USER_SUCCESS,
  CLEAR_CURRENT_USER,
  SET_CURRENT_COMPANY_ID,
} from './constants';

export function fetchCurrentUser(callback) {
  return {
    callback,
    type: FETCH_CURRENT_USER,
  };
}

export function fetchCurrentUserSuccess(user) {
  return {
    user,
    type: FETCH_CURRENT_USER_SUCCESS,
  };
}

export function fetchCurrentUserError(error) {
  return {
    error,
    type: FETCH_CURRENT_USER_ERROR,
  };
}

export function clearCurrentUser() {
  return {
    type: CLEAR_CURRENT_USER,
  };
}

export function setCurrentCompanyId(companyId) {
  return {
    companyId,
    type: SET_CURRENT_COMPANY_ID,
  };
}
