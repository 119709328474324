/*
 *
 * MyAccount reducer
 *
 */
import produce from 'immer';
import {
  FETCH_COMPANY,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_ERROR,
  TOGGLE_DISABLE_COMPANY,
  TOGGLE_DISABLE_COMPANY_SUCCESS,
  TOGGLE_DISABLE_COMPANY_ERROR,
  FETCH_EMPLOYEE_LIST,
  FETCH_EMPLOYEE_LIST_ERROR,
  FETCH_EMPLOYEE_LIST_SUCCESS,
  UPDATE_EMPLOYEE_LIST_PARAMS,
  FETCH_CUSTOM_FIELDS,
  FETCH_CUSTOM_FIELDS_SUCCESS,
  FETCH_CP_NAVIGATORS,
} from './constants';

export const initialState = {
  company: {
    loading: false,
    error: false,
    data: undefined,
  },
  employee: {
    loading: false,
    error: false,
    data: false,
  },
  employeeList: {
    loading: false,
    error: false,
    data: undefined,
    params: {},
  },
  customFields: {
    loading: false,
    error: false,
    data: [],
  },
  clinicalNavigators: {
    data: [],
  },
};

/* eslint-disable default-case, no-param-reassign */
const myAccountReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_COMPANY:
      case TOGGLE_DISABLE_COMPANY:
      case UPDATE_COMPANY:
        draft.company.loading = true;
        draft.company.error = false;
        draft.company.data = undefined;
        break;
      case FETCH_COMPANY_SUCCESS:
      case TOGGLE_DISABLE_COMPANY_SUCCESS:
      case UPDATE_COMPANY_SUCCESS:
        draft.company.loading = false;
        draft.company.error = false;
        draft.company.data = action.company;
        break;
      case FETCH_COMPANY_ERROR:
      case TOGGLE_DISABLE_COMPANY_ERROR:
      case UPDATE_COMPANY_ERROR:
        draft.company.loading = false;
        draft.company.error = action.error;
        break;
      case CREATE_EMPLOYEE:
      case DELETE_EMPLOYEE:
        draft.employee.loading = true;
        draft.employee.error = false;
        draft.employee.data = undefined;
        break;
      case CREATE_EMPLOYEE_SUCCESS:
      case DELETE_EMPLOYEE_SUCCESS:
        draft.employee.loading = false;
        draft.employee.error = false;
        draft.employee.data = action.employee;
        break;
      case CREATE_EMPLOYEE_ERROR:
      case DELETE_EMPLOYEE_ERROR:
        draft.employee.loading = false;
        draft.employee.error = action.error;
        break;
      case FETCH_EMPLOYEE_LIST:
        draft.employeeList.loading = true;
        draft.employeeList.error = false;
        draft.employeeList.data = undefined;
        break;
      case FETCH_EMPLOYEE_LIST_ERROR:
        draft.employeeList.loading = false;
        draft.employeeList.data = undefined;
        draft.employeeList.error = action.error;
        break;
      case FETCH_EMPLOYEE_LIST_SUCCESS:
        draft.employeeList.loading = false;
        draft.employeeList.error = false;
        draft.employeeList.data = action.employeeList;
        break;
      case UPDATE_EMPLOYEE_LIST_PARAMS:
        draft.employeeList.params = action.params;
        break;
      case FETCH_CUSTOM_FIELDS:
        draft.customFields.loading = true;
        draft.customFields.data = [];
        break;
      case FETCH_CUSTOM_FIELDS_SUCCESS:
        draft.customFields.loading = false;
        draft.customFields.data = action.customFields;
        break;
      case FETCH_CP_NAVIGATORS:
    }
  });

export default myAccountReducer;
