import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UikFormInputGroup, UikRadio } from '@uik/lib';

import '@uik/lib/styles.scss';

const Wrapper = styled(UikFormInputGroup)`
  flex-direction: row !important;
  flex-wrap: wrap;

  .uik-checkbox__radio:not(:last-of-type) {
    margin-right: 30px;
  }
`;

function RadioField({ input, options }) {
  return (
    <Wrapper>
      {options.map((option) => (
        <UikRadio
          checked={String(input.value) === String(option.value)}
          onChange={input.onChange}
          key={option.value}
          label={option.label}
          name={input.name}
          value={option.value}
        />
      ))}
    </Wrapper>
  );
}

RadioField.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array,
};

export default RadioField;
