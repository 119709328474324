/**
 *
 * RadioButton
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';

import ValidationText from 'components/ValidationText';

const verticalStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

function RadioButton({
  direction,
  input: { onChange, value },
  meta: { error, touched },
}) {
  const style = direction === 'vertical' ? verticalStyle : {};

  return (
    <div>
      <Radio.Group onChange={onChange} value={value}>
        <Radio style={style} value="active">
          Active employees
        </Radio>
        <Radio style={style} value="removed">
          Removed employees
        </Radio>
        <Radio style={style} value="all">
          All employees
        </Radio>
      </Radio.Group>
      {touched && error && (
        <ValidationText style={{ marginTop: '5px' }}>{error}</ValidationText>
      )}
    </div>
  );
}

RadioButton.propTypes = {
  direction: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

RadioButton.defaultProps = {
  direction: 'vertical',
};

export default RadioButton;
